import React from 'react';
import GroupForm from '../components/GroupForm'

import ActivateMemberForm from '../components/ActivateMemberForm'
import NGOCreateForm from '../components/NGOcreateform'

import axios from 'axios'
import { Table, Input, Button,Collapse,Tooltip,Popover,Popconfirm,message,Descriptions,Modal,Spin, Card } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,BankFilled,FundViewOutlined,DownloadOutlined,EditOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var ngoid= ''
var bizuserid= ''
var bizuserid= ''
var token= ''

class NGOList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    ngos: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    userrights:{},
  };

  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }
    
     if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   this.componentmountactions();
}


//component mount method
componentmountactions=()=>{
  axios.get(serverconfig.backendserverurl+`/api/ngos/?ngo_supervisor_id=${ngoid}`)
  .then(res => {
      this.setState({
        ngos:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
      this.setState({datarequested:false})
  })

}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    let form_data1 = new FormData();
    form_data1.append('memberid', id);
    form_data1.append('sacco', ngoid);

    axios.post(`${serverconfig.backendserverurl}/customqueries/deletemember`, form_data1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      }).then(res =>{
      message.info("successfully deleted") 
      this.componentmountactions();
     }
     );

  };

    //open mdi guarantor form
    openmemberactivationform=(val)=>{
      this.setState({selectedmemberid:val})
      this.setState({memberactivationformvisible:true})
    }
  
    closememberactivationform=()=>{
      this.setState({memberactivationformvisible:false})
    }


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {


    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <h4>All supervised NGOS.</h4>

<Row>

{
  this.state.ngos?
  this.state.ngos.map(
    (accnt)=>(

       <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
        <Card
              onClick={()=>{
                //this.displayBranchDashboard(accnt.id)
                //context.setshowbranchdashboard(true)
                
              }}
              hoverable
              style={{ 
                borderRadius:20,
                background:"white",
                display: 'flex',
                justifyContent:'center',
                padding:4,
                alignItems:'center' }}>
                <div style={{width:'100%',
                  padding:10,
                  alignItems:'center',
                  alignSelf:'center',
                  display:'flex',
                  justifyContent:'center',
                  flexDirection:'column'
                  }}>
              <BankFilled style={{color:'#2F54EB',fontSize: '45px'}}/>

              <h6 style={{fontWeight:'bold',alignSelf:'center'}}>{accnt.ngo_name}</h6>
              <div style={{display:'flex',flexDirection:'row'}}>
              <Tooltip title={"click to view or update details "} placement="top">

              <Link to={`/ngodetails/${accnt.id}`}><EditOutlined style={{color:'#2F54EB',fontSize: '20px',margin:3}}/></Link>
              </Tooltip>

              <Tooltip title={"click to view ngo users "} placement="top">
                <Link to={`/ngousers/${accnt.id}`}><FundViewOutlined style={{color:'green',fontSize: '20px',margin:3}}/></Link>
              </Tooltip>

              </div>
              </div>
            </Card>
        </Col>  
      ))
  :
  null
}
  </Row>
  <br></br>
  <br></br>

    <Collapse defaultActiveKey={['0']} onChange={callback}>
    <Panel header="Collapse panel to open NGO creation form" key="1">
      <h2>Create new NGO here</h2>
      <NGOCreateForm />
    </Panel>
    </Collapse>  
    </div>
    )
  }

}
}

export default NGOList; 
