import React from 'react';
import { Form, Input, Button,Select,InputNumber,DatePicker,message,Card,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var token= ''
var ngoid= ''


class SchoolFeesForm extends React.Component {

  state = {
    productName:'',  
    unit:'',
    unitprice:'',
    productDescription:'',
    productSerial:'',
    productImage:null,   
    paymentmode:'',
    date:moment().format(dateFormat).toString(),
    datarequested:false,

    children:[],
    schoolterms:[],
    selected_child:'',
    selected_term:'',
    school_fees_amount:0,
    other_requirements_amount:0,
    other_requirements_description:''

  }
  componentDidMount(){
    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/children/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          children:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/schoolterms/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          schoolterms:res.data
        })
    })

  }

  

  //submit button pressed
  onFinish=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('child',this.state.selected_child);
    form_data.append('school_term',this.state.selected_term);
    form_data.append('school_fees_amount',this.state.school_fees_amount);
    form_data.append('other_requirements_amount',this.state.other_requirements_amount);
    form_data.append('other_requirements_description',this.state.other_requirements_description);
    form_data.append('Ngo', ngoid);

    ///make a post request now
      axios.post(serverconfig.backendserverurl+'/api/schoolfees_budget/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>        this.setState({datarequested:false})
,    window.location.reload(false),message.info('Successfully added fees')
    )
    .catch(error => console.log(error))

    }

    handlepaymentmodeChange= (e) => {
       this.setState({paymentmode:e})
    }

    handledatechange= (date, dateString) => this.setState({ date: dateString});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
          <Card>
  
          <Form
                name="childform"
                onFinish={(event) => this.onFinish(event)}
            >
  
              <FormItem 
                    label="Child"
                    name="child"
                    rules={[
                      {
                        required: true,
                        message: 'Please select child',
                      },
                    ]}
                    >
                    <Select placeholder="Child"
                      style={{  }}
                      value={this.state.selected_child} 
                      onChange={(val)=>{this.setState({selected_child:val})
                    }} 
                    
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    
                    >
                        {this.state.children.map(
                          (child)=>(
                            <Option value={child.id}>{child.FullName} | {child.school_name}</Option>
                          ))}
                  </Select>     

              </FormItem>


              <FormItem 
                    label="School term"
                    name="schoolterm"
                    rules={[
                      {
                        required: true,
                        message: 'Please select school term',
                      },
                    ]}
                    >
                    <Select placeholder="School term"
                      style={{  }}
                      value={this.state.selected_term} 
                      onChange={(val)=>{this.setState({selected_term:val})
                    }} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    
                    >
                        {this.state.schoolterms.map(
                          (term)=>(
                            <Option value={term.id}>{term.term_name}</Option>
                          ))}
                  </Select>     

              </FormItem>
  
              <FormItem label="School fees amount"
                name="fees_amount"
                rules={[
                  {
                    required: true,
                    message: 'Please input amount',
                  },
                ]}
                
                >
                  <InputNumber
                    style={{ width: 150 }} 
                    defaultValue={0.0}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\x\s?|(,*)/g, '')}
                    onChange={(val)=>{this.setState({school_fees_amount:val})}}

                  />    
                              
                </FormItem>
                


                <FormItem label="Other requirements total amount"
                name="otherreq_amount"
                rules={[
                  {
                    required: true,
                    message: 'Please input amount',
                  },
                ]}
                
                >
                  <InputNumber
                    style={{ width: 150 }} 
                    defaultValue={0.0}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\x\s?|(,*)/g, '')}
                    onChange={(val)=>{this.setState({other_requirements_amount:val})}}

                  />    
                              
                </FormItem>

                <FormItem label="Other School requirements"
                  name="comments"
                  rules={[
                    {
                      required: true,
                      message: 'Please input comments',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="requirements break down and description"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.other_requirements_description}
                        onChange={(val)=>{this.setState({other_requirements_description:val.target.value})}}
                      />
                  </FormItem>


              <FormItem>
                <Button  type="primary" htmlType="submit">Add Fees</Button>
              </FormItem>
              </Form>
          </Card>
  
        </div>
      );
    }
    
  }
}

export default SchoolFeesForm;
