import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined,WarningFilled,CheckCircleFilled } from '@ant-design/icons';
var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TextArea } = Input;

var token= ''
var ngoid= ''
var bizuserid= ''


class ChildDocumentUploadForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accounttypes:[],
    idtypes:[],
    sur_name:'' ,  
    middle_name:'',
    given_name:'',
    nationality:'',
    ID_type:'',
    ID_number:'',
    date_Of_Birth:'',
    Email_Address:'',
    P_O_Box:'',
    Area_Of_Residence:'',
    District:'',
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    next_of_kin:'',
    Next_Of_Kin_Physical_Address:'',
    relationship:'',
    Photo:null,
    Signature:null,
    Thumbprint:null,

    Date_Of_Joining:'',
    account_type:'',
    date_of_appointment:'',
    status:'',
    staff_number:'',
    reference_one:'',
    Referee1PhoneNumber:'',
    datarequested:true,
    accountnumber:'',
    photofile:null,
    signaturefile:null,
    thumbprintfile:null,
    marital_status:'',
    own_residence:'',
    tribe:'',
    recommender:'',
    language1:'',
    language2:'',
    gender:'',
    any_other_account:'',
    other_account_number:'',
    other_bank:'',
    shares:0,
    title:'',
    datasubmittedsuccessfully:false,
    saccopackageobj:{},
    members:[],
    companyprofile:{},

    occupation:'',
    postaladdress:'',
    homecelllocaltion:'',
    cell:'',
    cellleader:'',
    nameofemployer_business:'',

    addressofemployer_business:'',
    refree_institutionalmember_name:'',
    refree_institutionalmember_contact:'',
    refree_cellleader_name:'',
    refree_cellleader_contact:'',
    next_of_kin_contact:'',

    child_account_school:'',
    child_account_school_class:'',

    membertype:'senior',
    pysical_form:null,
    saving_period:'',

    id_expirydate:'',
    ugdistricts:[],
    cellleader_contact:'',
    id_valid:false,
    age_valid:false,
    agetypeobject:{},
    idtypeobject:{},
    reapersrefobj:{},
    reapersselectedref:'',

    age_types:[],
    age_type:'',
    bankaccount:'',
    mobilemoney_contact:'',
    schools:[],
    selected_school:'',
    child_account_school_class:'',
    guardians:[],
    selected_guardian:'',

    children:[],
    selected_child:'',
    documenttype:'',
    attached_file:null,
    document_description:''

  }

  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/idtypes/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          idtypes:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/ngos/${ngoid}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })

    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/ugdistricts/?Ngo=${ngoid}`)
    .then(res => {
      this.setState({
        ugdistricts:res.data
      })
    })

    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/age_types/?Ngo=${ngoid}`)
    .then(res => {
      this.setState({
        age_types:res.data
      })
    })

    axios.get(serverconfig.backendserverurl+`/api/schools/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          schools:res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/guardians/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          guardians:res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/children/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          children:res.data
        })

        this.setState({datarequested:false})

    })

})

}


  //submit button pressed
  handleFormSubmit=(event) =>{

        this.setState({datarequested:true})
  
        let form_data = new FormData();
        form_data.append('date', this.state.date);
        form_data.append('child', this.state.selected_child);
        form_data.append('documenttype', this.state.documenttype);
        form_data.append('Ngo', ngoid);
        form_data.append('user', bizuserid);
        form_data.append('document_description', this.state.document_description);
        
        this.state.attached_file==null?
        console.log("No file")
        :
        form_data.append('attached_file', this.state.attached_file, this.state.attached_file?this.state.attached_file.name:"");
  
        axios.post(serverconfig.backendserverurl+'/api/child_documents/', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res =>{
          console.log(res.data)
          this.setState({datarequested:false})
          this.setState({datasubmittedsuccessfully:true})
          
        })
        .catch(error => console.log(error))
      
  }

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Uploaded  document "
            subTitle="document details will be further reviewed if need be"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

            return (
              <div>
                <Form
                    onFinish={(event) => this.handleFormSubmit(event)}
                >

                <FormItem label="Select child"
                     name="selected_child"
                     rules={[
                       {
                         required: true,
                         message: 'Please select child',
                       },
                     ]}           
                  >
                      <Select placeholder="Child"
                       style={{  }} value={this.state.selected_child} 
                       onChange={(val)=>{this.setState({selected_child:val})}}
                      
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      
                      >
                        {this.state.children.map(
                          (chld)=>(
                            <Option value={chld.id}>{chld.FullName}</Option>
                          ))}
                      </Select>
                  </FormItem>

                  <FormItem label="Document type"
                     name="documenttype"
                     rules={[
                       {
                         required: true,
                         message: 'Please select documenttype',
                       },
                     ]}           
                  >
                      <Select placeholder="Child" style={{  }} 
                      value={this.state.documenttype} 
                      onChange={(val)=>{this.setState({documenttype:val})}} 

                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      >
                     
                      <Option value={"reportcard"}>School Report card</Option>
                      <Option value={"other"}>Other</Option>
                      </Select>
                  </FormItem>

                  <FormItem label="File attachment" style={{marginRight:3}}
                    name="attached_file"
                    rules={[
                      {
                        required: true,
                        message: 'Please input file',
                      },
                    ]}
                  >
                        <Input type="file" accept=".pdf" placeholder="file"  onChange={(e)=>{ 
                          
                          var kilbyes=e.target.files[0].size/1024
                          if (kilbyes>Number(1024)){
                            message.error("File is greater than "+"1024"+" Kbs or 1 mb .It will not be uploaded.")
                          }else{
                            this.setState({attached_file:e.target.files[0]})
                          }
                          
                          
                          }}/>
                  </FormItem> 

                  <FormItem label="Document description"
                    name="document_description"
                    rules={[
                      {
                        required: true,
                        message: 'Please input document_description',
                      },
                    ]}
                    
                    >
                        <TextArea
                          placeholder="description here"
                          autoSize={{ minRows: 2, maxRows: 6 }}
                          value={this.state.document_description}
                          onChange={(val)=>{this.setState({document_description:val.target.value})}}
                          
                        />
                    </FormItem>

                <FormItem >
                  <Button  type='primary' htmlType="submit">Upload</Button>
                </FormItem>
    
              </Form>
            </div>
          );
    }

    }
    
  }

}

export default ChildDocumentUploadForm;

