import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Form,InputNumber,Modal,Popover,Spin,Tabs,Popconfirm,message,Tooltip,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,DollarOutlined,RedoOutlined,FundViewOutlined,LoadingOutlined,MehFilled,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import TransactionCreateForm from '../components/Transactioncreateform'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;


var token= ''
var ngoid= ''
var bizuserid= ''


class TransactionBatchList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    ngopaymentbatch: [],
    datarequested:true,
    walletmodalvisible:false,
    selectedsaccooject:{},
    date:moment().format(dateFormat).toString(),
    saccodebitamount:0,
    mobilephone:'',
    userrights:{},
    transactionsmodalvisible:false,
    selectedbatch:{}

  };


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("ngoid")){
    ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
  }else{
    ngoid= ''
  }

  if(localStorage.getItem("bizuserid")){
    bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
  }else{
      bizuserid= ''
  }
  

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/ngopaymentbatch/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          ngopaymentbatch:res.data
        })
        this.setState({datarequested:false})
    })

}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  render() {
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Batch Total amount',
        dataIndex: 'batchtotalamount',
        key: 'id',
        render: (text,record) =><p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
      },

      {
        title: 'No of receivers',
        dataIndex: 'noofreceivers',
        key: 'id',
      },
      
      {
        title: 'Batch number',
        dataIndex: 'batch_number',
        key: 'id',
        ...this.getColumnSearchProps('batch_number'),
      },
      {
        title: 'Preparing user',
        dataIndex: 'user_name',
        key: 'id',
        ...this.getColumnSearchProps('user_name'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'id',
        ...this.getColumnSearchProps('status'),
        render: (text,record) =><p style={{color:text==="pending"?"orange":text==="success"?"green":"red"}}>{text}</p>
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click view details</p>} title="Details">
        <Link to={`/transactionbatchdetails/${text}`}><FundViewOutlined style={{color:'green',fontSize: '30px'}} /> </Link>
        </Popover>
        </p>,
      }

    ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

  return(
    <div>

    <Tabs defaultActiveKey="1" onChange={callback}>

    {
      this.state.userrights.prepare_payouts===true?
      <TabPane tab="Transaction preparation form" key="1">
      <h3>Prepare transactions here</h3>
      <TransactionCreateForm />
      </TabPane>
      :
      null
    }


    {
      this.state.userrights.submit_payouts===true?
    <TabPane tab="Transaction batch list" key="2">
    <Collapse defaultActiveKey={['1']} onChange={this.callback}>
        <Panel header={<h4 style={{fontWeight:'bolder'}}>Batch list</h4>} key="1">
        <Table   
        columns={columns} 
        scroll={{ x: 1000 }}
        dataSource={this.state.ngopaymentbatch} 
        pagination={{showQuickJumper:true,showSizeChanger:true }}
        bordered/>
        <br />
        </Panel>
        </Collapse>
    </TabPane>
    :
    null}


    </Tabs>



    </div>
    )}
  }
}

export default TransactionBatchList; 
