import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin,Select,InputNumber} from 'antd';
import IDTypeForm from '../components/IDTypeForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
  var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';



var token= ''

var sacco= ''
var ngoid= ''

class SchoolFeesDetail extends React.Component{

    state={
        idtype: {},
        idtypeName:'' ,  
        idtypeDescription:'',
        datarequested:true,
        IDvaliditydays:0,

        children:[],
        schoolterms:[],
        selected_child:'',
        selected_term:'',
        school_fees_amount:0,
        other_requirements_amount:0,
        other_requirements_description:''

    }

    componentDidMount(){
      if(localStorage.getItem("ngoid")){
        ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      }else{
        ngoid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }
  
      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }


        const feesID=this.props.match.params.feesID;
        axios.get(`${serverconfig.backendserverurl}/api/schoolfees_budget/${feesID}`)
        .then(res => {  

            this.setState({selected_child:res.data.child})
            this.setState({selected_term:res.data.school_term})
            this.setState({school_fees_amount:res.data.school_fees_amount})
            this.setState({other_requirements_amount:res.data.other_requirements_amount})
            this.setState({other_requirements_description:res.data.other_requirements_description})

            this.setState({datarequested:false})
        })




        axios.get(serverconfig.backendserverurl+`/api/children/?Ngo=${ngoid}`)
        .then(res => {
            this.setState({
              children:res.data
            })
        })
    
    
        axios.get(serverconfig.backendserverurl+`/api/schoolterms/?Ngo=${ngoid}`)
        .then(res => {
            this.setState({
              schoolterms:res.data
            })
        })

    }


    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const feesID=this.props.match.params.feesID;

    let form_data = new FormData();
    form_data.append('child',this.state.selected_child);
    form_data.append('school_term',this.state.selected_term);
    form_data.append('school_fees_amount',this.state.school_fees_amount);
    form_data.append('other_requirements_amount',this.state.other_requirements_amount);
    form_data.append('other_requirements_description',this.state.other_requirements_description);

    axios.put(`${serverconfig.backendserverurl}/api/schoolfees_budget/${feesID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>this.setState({datarequested:false}),window.location.reload(false),message.info('Successfully updated fees'))
    .catch(error => console.log(error))

  }


    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
                <div>
                    
                    <Card 
                    style={{padding:10}}
                    hoverable>
                        <h4>School fees Update</h4>
                        <Form>

                    <FormItem 
                    label="Child"

                    >
                    <Select placeholder="Child"
                      style={{  }}
                      value={this.state.selected_child} 
                      onChange={(val)=>{this.setState({selected_child:val})
                    }} 
                    
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    
                    >
                        {this.state.children.map(
                          (child)=>(
                            <Option value={child.id}>{child.FullName} | {child.school_name}</Option>
                          ))}
                  </Select>     

                  </FormItem>


                   <FormItem 
                    label="School term"

                    >
                    <Select placeholder="School term"
                      style={{  }}
                      value={this.state.selected_term} 
                      onChange={(val)=>{this.setState({selected_term:val})
                    }} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    
                    >
                        {this.state.schoolterms.map(
                          (term)=>(
                            <Option value={term.id}>{term.term_name}</Option>
                          ))}
                  </Select>     

              </FormItem>
  
              <FormItem label={"School fees amount ( "+this.state.school_fees_amount+" )"}

                
                >
                  <InputNumber
                    style={{ width: 150 }} 
                    defaultValue={0.0}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\x\s?|(,*)/g, '')}
                    onChange={(val)=>{this.setState({school_fees_amount:val})}}

                  />    
                              
                </FormItem>
                

                <FormItem label={"Other requirements total amount ("+this.state.other_requirements_amount+" )"}

                >
                  <InputNumber
                    style={{ width: 150 }} 
                    defaultValue={0.0}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\x\s?|(,*)/g, '')}
                    onChange={(val)=>{this.setState({other_requirements_amount:val})}}

                  />    
                              
                </FormItem>

                <FormItem label="Other School requirements"

                  >
                      <TextArea
                        placeholder="requirements break down and description"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.other_requirements_description}
                        onChange={(val)=>{this.setState({other_requirements_description:val.target.value})}}
                      />
                  </FormItem>
                    
                        <FormItem>
                        <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                        </FormItem>
                    </Form>
    
                  </Card>
  
                  <br/>
              </div>
            )

          }
    }
}

export default SchoolFeesDetail; 