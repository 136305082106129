import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,MinusCircleFilled,ToolOutlined, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined,BankFilled, AccountBookOutlined,CalculatorFilled,SwapOutlined,GroupOutlined,AccountBookFilled, DollarCircleOutlined,EditFilled,MessageFilled,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'
import { MdShoppingCart } from 'react-icons/md';

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import  LoanCalculator from '../components/LoanCalculator'
import * as primarycolor from '../primarycolor'
import  QuickMenu from '../containers/QuickMenu'

var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

var bizuserid= ''
var ngoid= ''
var token= ''




class DashBoard extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    companyprofile:{},
    datarequested:true,
    memberformvisible:false,
    transactformvisible:false,
    calculatorformvisible:false,
    members:[],
    loansformvisible:false,
    dashboardlocked:false,
    utilitiesmodalvisible:false,
    userprofile:{},
    userrights:{},
    nonworkingday:{},
    depositsno:0,
    withdrawsno:0,
    depositvolume:0,
    withdrawvolume:0,
    tranquerymode:'daily',
    expensevolumes:0,
    incomevolumes:0,
    datareload:false,
    withdrawpercentage:0,
    withdrwdiff:0,
    depositpercentage:0,
    depositdiff:0,
    percenategincomes:0,
    incomediff:0,
    percenategexpnses:0,
    expensdiff:0,
    weeklydays:[],
    weeklydeposits:[],
    weeklywithdraws:[],
    weeklyincomes:[],
    weeklyexpenses:[],

    weeklychartdata:[],
    weeklychartitems:[],

    graphtranquerymode:'weekly',
    loanproccessuerymode:'daily',

    totalapplications:0,
    totalapplicationamounts:0,
    totalassessments:0,
    totalassessmentsamounts:0,
    totalapprovals:0,
    totalapprovalsamounts:0,
    totaldisbursements:0,
    totaldisbursementsamounts:0,

    projectedmonths:"three",
    monthsprojected:[],
    projectedinterest:[],
    projectedprinciple:[],

    applicationvsdates:[],
    applicationvs:[],
    issuancesvs:[],
    applicvsmode:'weekly',

    children:[],
    otherngos:[],
    guardians:[],


  };


  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){
    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`)
   .then(res => {
       this.setState({
        userprofile:res.data
       })
   })

    axios.get(serverconfig.backendserverurl+`/api/ngos/${ngoid}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/guardians/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          guardians:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/ngos/?ngo_supervisor_id=${ngoid}`)
    .then(res => {
        this.setState({
          otherngos:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/children/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          children:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })

    this.setState({datarequested:false})

}


render() {

if(this.state.dashboardlocked===true){
  return(
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
    <h3 style={{fontWeight:'bolder',color:'red'}}>NOTE: Your dashboard is currently locked. Contact system adminstrators.Your Licence may be expired or some thing else. Thanks</h3>
    </div>
  )
}else{

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
  
    }else{

        return(
          <div className="animated fadeIn">  
            {
            this.state.userrights.can_view_statistics===true?
          <h3 style={{fontStyle:'oblique',fontWeight:'bolder',color:primarycolor.primarycolor}}>{this.state.companyprofile.ngo_name} DASHBOARD</h3>
            :
            null}
          {
            this.state.userrights.can_view_statistics===true?
            <Row>

            {
              this.state.companyprofile.ngo_controller===true?
              <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
             <NavLink to='/managengos/'>
             <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

              <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
              <BankFilled style={{ fontSize: 24 }}  />

              <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.otherngos.length}</h6>
              <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> Total controlled Ngos </h5>

              </div>

                </Card>
             </NavLink>

              </Col>
              :
              null
            }


            <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
            <NavLink to='/guardians/'>
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

              <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
              <UserOutlined style={{ fontSize: 24 }}  />
              <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.guardians.length}</h6>

              <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> Total of guardians </h5>
              </div>

                </Card>

            </NavLink>

            </Col>

            <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
            <NavLink to='/managechildren/'>
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
              <UserOutlined style={{ fontSize: 24 }}  />
              <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.children.length}</h6>

               <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  Total of children</h5>

               </div>

              </Card>

            </NavLink>

            </Col>


          </Row>
         
            :
            null
          }

          <br></br>

          <QuickMenu />

       </div>
      )
    }
  
 }

}
}

export default DashBoard; 
