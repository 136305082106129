import React from 'react';
import  ChildDevelopmentForm from '../components/ChildDevelopmentForm'


import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin, Card,Avatar,Image } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { FilePdfFilled,SearchOutlined,DeleteOutlined,FundViewOutlined,DownloadOutlined,EditOutlined,WalletFilled,LoadingOutlined,PrinterFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var ngoid= ''
var bizuserid= ''
var token= ''


class ReportToPrint extends React.Component {

  componentDidMount(){
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

  }

  //get the age
   getAge=(birth)=> {
    var today = new Date();
    var curr_date = today.getDate();
    var curr_month = today.getMonth() + 1;
    var curr_year = today.getFullYear();
 
    var pieces = birth.split('/');
    var birth_date = pieces[0];
    var birth_month = pieces[1];
    var birth_year = pieces[2];
 
    if (curr_month == birth_month && curr_date >= birth_date) return parseInt(curr_year-birth_year);
    if (curr_month == birth_month && curr_date < birth_date) return parseInt(curr_year-birth_year-1);
    if (curr_month > birth_month) return parseInt(curr_year-birth_year);
    if (curr_month < birth_month) return parseInt(curr_year-birth_year-1);
 }


  render() {
    return (
      <div style={{padding:20}}>
           <h2>Date : {this.props.selected_report.date}</h2>
           <h2>{this.props.selected_report.ngo_name}</h2>

           <h1 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>CHILD DEVELOPMENT REPORT</h1>

           <reactstrp.Table bordered>
                    <tbody>

                        <tr>
                        <td>
                        {
                          this.props.selected_report.child_image!=''?
                          <div style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>
                          <img height="300" width="200"  alt="Logo" src={serverconfig.backendserverurl+"/media/"+this.props.selected_report.child_image} />
                          </div>
                          :
                          null
                        }

                        </td>
                        <td>
                        <h3 style={{fontWeight:'bolder'}}>Name of child: {this.props.selected_report.child_name}</h3>
                        <h3 style={{fontWeight:'bolder'}}>Date of birth: {this.props.selected_report.child_date_of_birth}</h3>

                        <h3 style={{fontWeight:'bolder'}}>Age of child: { this.props.selected_report.child_date_of_birth!=undefined?this.getAge(this.props.selected_report.child_date_of_birth):null}</h3>
                        <h3 style={{fontWeight:'bolder'}}>Guardian: {this.props.selected_report.guardian_name}</h3>
                        <h3 style={{fontWeight:'bolder'}}>Location: {this.props.selected_report.child_location}</h3>

                        </td>

                        </tr>
                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                              Highlights of the month:
                            </h4>
                          </td>
                          <td>
                            <h5>
                            {this.props.selected_report.highlights_ofthemonth}
                            </h5>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                            Main interests of the Child:
                            </h4>
                          </td>
                          <td>
                            <h5>
                            {this.props.selected_report.main_interestsofthechild}
                            </h5>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                            Wishes of the Child:
                            </h4>
                          </td>
                          <td>
                            <h5>
                            {this.props.selected_report.wishes_ofthechild}
                            </h5>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                            Challenges:
                             </h4>
                          </td>
                          <td>
                            <h5>
                            {this.props.selected_report.challenges}
                            </h5>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                            Complaints about the Child:
                            </h4>
                          </td>
                          <td>
                            <h5>
                            {this.props.selected_report.complaints_abt_thechild}
                            </h5>
                          </td>
                        </tr>



                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                            Special comments or results from school:                            </h4>
                          </td>
                          <td>
                            <h5>
                            {this.props.selected_report.special_comments_or_resultsfromschool}
                            </h5>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                            Comments from the Child about thes NGO:      
                            </h4>     
                           </td>
                          <td>
                            <h5>
                            {this.props.selected_report.comments_ofthechild_abt_ngo}
                            </h5>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                            Comments of Child about School:           
                                             </h4>     
                           </td>
                          <td>
                            <h5>
                            {this.props.selected_report.comments_ofthechild_abt_school}
                            </h5>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                            Comments of Guardian about the Child:    
                               </h4>     
                           </td>
                          <td>
                            <h5>
                            {this.props.selected_report.comments_oftheguardian_abt_child}
                            </h5>
                          </td>
                        </tr>



                        <tr>
                          <td>
                            <h4 style={{fontWeight:'bolder'}}>
                            Comments of Guardian about the NGO and the school:    
                               </h4>     
                           </td>
                          <td>
                            <h5>
                            {this.props.selected_report.comments_oftheguardian_abt_ngoandschool}
                            </h5>
                          </td>
                        </tr>


                    </tbody>
           </reactstrp.Table> 

      </div>
    );
  }
}




class DevelopmentReportList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    childdevelopmentreport: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    userrights:{},
    loaded_image:false,

    selected_report:{},
    showprintermodal:false,
    showdetailsmodal:false

  };

  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    
    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }
    
     if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   this.componentmountactions();
}


//component mount method
componentmountactions=()=>{
  axios.get(serverconfig.backendserverurl+`/api/childdevelopmentreport/?Ngo=${ngoid}&user=${bizuserid}`)
  .then(res => {
      this.setState({
        childdevelopmentreport:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
      this.setState({datarequested:false})
  })


}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/childdevelopmentreport/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

      var columns = [
        
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Child number',
          dataIndex: 'child_number',
          key: 'id',
          ...this.getColumnSearchProps('child_number'),
        },
        {
          title: 'Child name',
          dataIndex: 'child_name',
          key: 'id',
          ...this.getColumnSearchProps('child_name'),
        },

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>

          <Popover content={<p>Click here to view report</p>} title="Print">
          <FundViewOutlined style={{color:'blue',fontSize: '20px'}} 
            onClick={()=>{
                this.setState({selected_report:record})
                this.setState({showdetailsmodal:true})
            }}/>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to print report</p>} title="Print">
          <PrinterFilled style={{color:'blue',fontSize: '20px'}} 
            onClick={()=>{
                this.setState({selected_report:record})
                this.setState({showprintermodal:true})
            }}/>
          </Popover>
          </p>,
        }
  
      ];
    
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>

           <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              selected_report={this.state.selected_report}
              ref={el => (this.componentRef = el)} /> 
            </div>


            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>AVAILABLE DEVELOPMENT REPORTS</h4>} key="1">
            
            <Table   
            columns={columns} 
            scroll={{ x: 1000 }}
            dataSource={this.state.childdevelopmentreport} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered/>
            <br />

            </Panel>
            </Collapse>
            <br></br>
            <br></br>

            {
             this.state.userrights.can_create_childdevelopmentreport===true?

            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Collapse Panel to open development Form" key="1">
              <h2>Create new report here</h2>
              <ChildDevelopmentForm /> 
            </Panel>
            </Collapse>  
            :null
             }


            <Modal
                  
                  visible={this.state.showprintermodal}
                  title="Printer Request"
                  width={200}
                  onCancel={(val)=>{this.setState({showprintermodal:false})}}  
                  footer={[
                    <Button key="back" onClick={(val)=>{this.setState({showprintermodal:false})}}>
                      Cancel
                    </Button>,
                    <ReactToPrint
                      trigger={() =>  
                      <Button onClick={(val)=>{this.setState({showprintermodal:false})}} type="primary">Yes</Button>        
                    }
                    content={() => this.componentRef}
                    onAfterPrint={(val)=>{this.setState({showprintermodal:false})}}
  
                    />
                    ]}
                    >
                    <h4>Print?</h4>
                      
               </Modal>


               <Modal
                  visible={this.state.showdetailsmodal}
                  title="Report details"
                  width={700}
                  onCancel={(val)=>{this.setState({showdetailsmodal:false})}}  
                  footer={[
                    <Button key="back" onClick={(val)=>{this.setState({showdetailsmodal:false})}}>
                      Cancel
                    </Button>
                    ]}
                    >
                    <ReportToPrint
                    selected_report={this.state.selected_report}
                    /> 
               </Modal>
        </div>
    )

    }

  }
}

export default DevelopmentReportList; 
