import React from 'react';
import { Form, Input, Button,Select,message,Spin,Switch,InputNumber,DatePicker,Result,Card } from 'antd';
import { Table,Collapse,Popover,Popconfirm,Descriptions,Modal } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';
import uuid from 'react-uuid'
import CurrencyFormat from 'react-currency-format';

import { SearchOutlined,LoadingOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,EditOutlined } from '@ant-design/icons';
var CryptoJS = require("crypto-js");

const dateFormat = 'DD/MM/YYYY';

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var bizuserid=''
var ngoid= ''


class TransactionCreateForm extends React.Component {

  state = {
    ngo_name:'',  
    licence:'',
    companyemail:'',
    ngo_logo:null,   
    companyphone:'',
    boxnumber:'',
    accountslimit:0,
    branchname:'',
    companyphonetwo:'',
    companyphonethree:'',
    website:'',
    address:'',
    datarequested:true,  
    safebalance:0,
    sharevalue:0,
    retainedearnings:0,
    packages:[],
    package:'',
    mobilemoneygatewaycode:'',
    mobilemoneyreceiver_number:'',
    mobilemoneyreceiver_number:'',
    bankaccount:'',
    ngo_controller:false,

    ngos:[],
    selected_ngo:'',
    transfer_amount:0,
    remarks:'',
    reason:'',

    transfer_list:[],
    itemupdate:false,
    itemnumber:0,
    selected_ngo_object:{},
    selectedupdateitem:{},
    transactionchannel:'',
    createdemotransfer:false,
    companyprofile:{},

    ngomobileaccounts: [],
    companyaccounts: [],
    selectedmobilemoneynumber:'',
    selectedbankaccount:'',

    currencies:[],
    selected_currency:'',
    receiver_type:'',

    otherbankaccount:'',
    othermobilemoneynumber:'',
    othercashreceiver:'',
    datasubmittedsuccessfully:false,

    mobilemoney_receivername:'',
    bank_receivername:'',
    date:moment().format(dateFormat).toString(),


  }


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("ngoid")){
    ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
  }else{
    ngoid= ''
  }

   if(localStorage.getItem("bizuserid")){
    bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
  }else{
      bizuserid= ''
  }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/ngos/`)
    .then(res => {
        this.setState({
          ngos:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/ngos/${ngoid}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/currencies/`)
    .then(res => {
        this.setState({
          currencies:res.data
        })
        this.setState({datarequested:false})
    })

}

onBlur=()=> {
  console.log('blur');
}

 onFocus=()=>{
  console.log('focus');
}

onSearch=(val)=> {
  console.log('search:', val);
}


  //submit button pressed
  handleFormSubmit=(event) =>{

    if (this.state.transfer_list.length>0){

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }
  
      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('transfer_list',JSON.stringify(this.state.transfer_list) );
      form_data.append('list_totalno',this.state.transfer_list.length);
      form_data.append('user',bizuserid);
      form_data.append('Ngo', ngoid);
      form_data.append('batchtotal', this.calculatelisttotal());
      form_data.append('date',this.state.date);

      axios.post(serverconfig.backendserverurl+'/customqueries/prepare_transactionbatch', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        this.setState({datarequested:false})
        this.setState({datasubmittedsuccessfully:true})
      })
      .catch(error => console.log(error))
    }else{
      message.info("Transfer List empty")
    }

  }

  //list total
  calculatelisttotal=()=>{
    var total=0;
    this.state.transfer_list.map((item)=>{
      total+=Number(item.transfer_amount)
    })
    return total;
  }


  addtolist=(event) =>{
    if (this.state.itemupdate===true){
      const elementsIndex = this.state.transfer_list.findIndex(element => element.key == this.state.selectedupdateitem.key )

      let newArray = [...this.state.transfer_list]

      newArray[elementsIndex] = {...newArray[elementsIndex], 
        transfer_amount:this.state.transfer_amount,
      }

      this.setState({
        transfer_list: newArray,
        });

        this.setState({selected_ngo_object:{}});
        this.setState({transfer_amount:0})
        this.setState({reason:''})
        this.setState({selected_ngo:''});    
        this.setState({itemupdate:false})
        this.setState({transactionchannel:''});    

    }else{

      //check the channel
      if(this.state.transactionchannel==="mobile" && this.state.selectedmobilemoneynumber==="" && this.state.receiver_type==="ngo"){
        message.info("Please select mobile money number")
      }else if(this.state.transactionchannel==="bank" && this.state.selectedbankaccount==="" && this.state.receiver_type==="ngo"){
        message.info("Please select bank account")
      }else if (this.state.receiver_type==="other" && this.state.transactionchannel==="mobile" && this.state.othermobilemoneynumber===""){
        message.info("Mobile money number missing")
      }else if (this.state.receiver_type==="other" && this.state.transactionchannel==="bank" && this.state.otherbankaccount===""){
        message.info("Bank account missing")
      }
    
      else{
        this.setState({itemnumber:(Number(this.state.itemnumber)+1)})

        const newreceiptitem={
          key:uuid(),
          itemnumber:(Number(this.state.itemnumber)+1),
          ngo_name:this.state.selected_ngo_object.ngo_name,
          ngo:this.state.selected_ngo_object.id,
          transfer_amount:Number(this.state.transfer_amount),
          reason:this.state.reason,
          transactionchannel:this.state.transactionchannel,
          selectedbankaccount:this.state.selectedbankaccount,
          selectedmobilemoneynumber:this.state.selectedmobilemoneynumber,
          receiver_type:this.state.receiver_type,
          selected_currency:this.state.selected_currency,
          otherbankaccount:this.state.otherbankaccount,
          othermobilemoneynumber:this.state.othermobilemoneynumber,
          othercashreceiver:this.state.othercashreceiver,
          mobilemoney_receivername:this.state.mobilemoney_receivername,
          bank_receivername:this.state.bank_receivername,
         }
    
        //add to the receipt item list
        this.setState({
          transfer_list: [...this.state.transfer_list, newreceiptitem]
        });
    
        this.setState({selected_ngo_object:{}});
        this.setState({transfer_amount:0})
        this.setState({reason:''})
        this.setState({selected_ngo:''});
        this.setState({transactionchannel:''});
        this.setState({receiver_type:''});
        this.setState({selected_currency:''});

        this.setState({otherbankaccount:''});
        this.setState({othermobilemoneynumber:''});
        this.setState({othercashreceiver:''});
      
        this.setState({mobilemoney_receivername:''});
        this.setState({bank_receivername:''});

      }

    }

  }


  //remove item
  removeItem=(id)=>{
    console.log(id)
    this.setState({ transfer_list:  [...this.state.transfer_list.filter(todo => todo.key
      !==id)]});
  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{


      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Submitted preparations."
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />

            </Card>
          
        );

      }else{

        const receiver_columns = [
          {
            title: 'Receiver type',
            dataIndex: 'receiver_type',
            key: 'key',
          },
          {
            title: 'Ngo name',
            dataIndex: 'ngo_name',
            key: 'key',
          },
          {
            title: 'Amount to receive',
            dataIndex: 'transfer_amount',
            key: 'key',
            render: (text,record) =><p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
          },
          {
            title: 'Channel',
            dataIndex: 'transactionchannel',
            key: 'key',
          },
          {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'key',
          },

          {
            title: 'Currency',
            dataIndex: 'selected_currency',
            key: 'key',
          },
          {
            title: 'Action',
            dataIndex: 'key',
            key: 'key',
            render: (text,record) =>
            <p>
            <Popover content={<p>Click here to edit</p>} title="Edit,View">
             <FundViewOutlined style={{color:'blue'}}  onClick={()=>{
                this.setState({itemupdate:true})
  
                this.setState({transfer_amount:record.transfer_amount})
                this.setState({reason:record.reason})
                this.setState({selected_ngo:record.ngo})
                this.setState({transactionchannel:record.transactionchannel})
                this.setState({receiver_type:record.receiver_type})
                this.setState({selected_currency:record.selected_currency})
                this.setState({otherbankaccount:record.otherbankaccount})
                this.setState({othermobilemoneynumber:record.othermobilemoneynumber})
                this.setState({othercashreceiver:record.othercashreceiver})

                axios.get(`${serverconfig.backendserverurl}/api/ngos/${record.ngo}`)
                .then(res => {  
                    this.setState({
                        selected_ngo_object: res.data
                    })
                })  
  
                this.setState({selectedupdateitem:record})
    
              }}/>
            </Popover>
            &nbsp;&nbsp;&nbsp;
            <Popover content={<p>Click here to delete client</p>} title="Delete">
            <Popconfirm title="Sure to delete?" onConfirm={() => this.removeItem(text)}>
              <DeleteOutlined style={{color:'red'}} />
             </Popconfirm>
            </Popover>
            </p>,
          }
    
        ];
  
  
  
        return (
          <div >
              <Table 
                scroll={{ x: 1000 }}
                columns={receiver_columns}
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                dataSource={this.state.transfer_list} bordered/>
                <br></br>
                <h3>List Total Amount: {<CurrencyFormat value={this.calculatelisttotal()} displayType={'text'} thousandSeparator={true}/>}  For ( {this.state.transfer_list.length} ) Receivers</h3>
  
              <br></br>
              <h3>Add a new receiver</h3>
              <Form
              onFinish={(event) => this.addtolist(event)}
              >
  
              <FormItem label="Receiver type"
                >
                <Select 
                placeholder="Receiver type" 
                style={{ }} 
                value={this.state.receiver_type}
                onChange={(val)=>{
                  this.setState({receiver_type:val})
                }} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}                
                >
                <Option value={"ngo"}>Ngo</Option>
                <Option value={"other"}>Other</Option>
                </Select>
              </FormItem> 
  
              {
                this.state.receiver_type==="ngo"?
                <FormItem label="NGO to receive money"
                              >
                              <Select 
                              placeholder="Select NGO" 
                              style={{ }} 
                              value={this.state.selected_ngo}
                              onChange={(val)=>{
                                this.setState({selected_ngo:val})
  
                                //selected ngo object set
                                axios.get(`${serverconfig.backendserverurl}/api/ngos/${val}`)
                                .then(res => {  
                                    this.setState({
                                        selected_ngo_object: res.data
                                    })
                                  })
                              }} 
                              showSearch
                              optionFilterProp="children"
                              onFocus={this.onFocus}
                              onBlur={this.onBlur}
                              onSearch={this.onSearch}                 
                              >
                                {this.state.ngos.map(
                                  (accnt)=>{
                                    if(Number(ngoid)===Number(accnt.id)){
                                      console.log("nothg")
                                    }
                                    else
                                    {
                                      return(
                                        <Option value={accnt.id}>{accnt.ngo_name} | {accnt.mobilemoney_contact} | {accnt.bankaccount}</Option>
                                      );
                                    }
  
                                  })
                                
                                }
                            </Select>
                          </FormItem> 
                  :
                  null
                }
                
              
              <FormItem label="Transfer Channel"
                >
                <Select 
                placeholder="Transfer Channel" 
                style={{ }} 
                value={this.state.transactionchannel}
                onChange={(val)=>{
                  this.setState({transactionchannel:val})
  
                  if(val==="mobile"){
                    this.setState({ngomobileaccounts:[]})
                    //get the mobile money accounts
                    axios.get(serverconfig.backendserverurl+`/api/ngomobileaccounts/?Ngo=${this.state.selected_ngo}`)
                    .then(res => {
                        this.setState({
                            ngomobileaccounts:res.data
                        })
                    })
                  }else if(val==="bank"){
                    this.setState({companyaccounts:[]})
  
                    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?Ngo=${this.state.selected_ngo}`)
                    .then(res => {
                        this.setState({
                            companyaccounts:res.data
                        })
                    })
                  }
                }} 

                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}                
                >
                <Option value={"mobile"}>Mobile money</Option>
                <Option value={"bank"}>Bank Transfer</Option>
                <Option value={"cash"}>Cash pay</Option>
                </Select>
              </FormItem> 
  
  
              {
                this.state.transactionchannel==="mobile" && this.state.ngomobileaccounts.length>0 &&this.state.receiver_type==="ngo"?
                <FormItem label="Select Mobile Money Number"
                >
                <Select 
                placeholder="Mobile money number" 
                style={{ }} 
                value={this.state.selectedmobilemoneynumber}
                onChange={(val)=>{
                  this.setState({selectedmobilemoneynumber:val})
  
                }} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}                
                >
                  {
                    this.state.ngomobileaccounts.map((item)=>{
                      return (<Option value={item.id}>{item.mobile_number}</Option>);
                    })
                  }
                
                </Select>
                </FormItem> 
                :
                null
              }
  
              {
                this.state.transactionchannel==="bank" && this.state.companyaccounts.length>0 &&this.state.receiver_type==="ngo"?
                <FormItem label="Select bank account"
                >
                <Select 
                placeholder="bank account" 
                style={{ }} 
                value={this.state.selectedbankaccount}
                onChange={(val)=>{
                  this.setState({selectedbankaccount:val})
                }} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}                
                >
                  {
                    this.state.companyaccounts.map((item)=>{
                      return (<Option value={item.id}>{item.account_number} | {item.bank_name}</Option>);
                    })
                  }
                
                </Select>
              </FormItem> 
                :
                null
              }

  
              {
                  this.state.receiver_type==="other" && this.state.transactionchannel==="mobile"?
                  <div>
                  <FormItem label= { <h6 style={{display:'flex'}} >Mobile money number</h6>}                       
                  >
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.othermobilemoneynumber} onChange={(val)=>{this.setState({othermobilemoneynumber:val})}}/>
                   </FormItem>  

                   <FormItem label= { <h6 style={{display:'flex'}} >Mobile money receiver personel</h6>}                       
                  >
                      <Input 
                        placeholder="Mobile money receiver personel" 
                        value={this.state.mobilemoney_receivername}
                        onChange={(val)=>{this.setState({mobilemoney_receivername:val.target.value})}} 
                        />              
                  </FormItem>
                  </div>
                  :
                  null
  
                }
  
  
                {
                  this.state.receiver_type==="other" && this.state.transactionchannel==="bank"?
                  <div>
                  <FormItem label= { <h6 style={{display:'flex'}} >Bank account</h6>}                       
                  >
                      <Input 
                        placeholder="Bank Account" 
                        value={this.state.otherbankaccount}
                        onChange={(val)=>{this.setState({otherbankaccount:val.target.value})}} 
                        />              
                  </FormItem>  

                  <FormItem label= { <h6 style={{display:'flex'}} >Bank pay receiver name</h6>}                       
                  >
                      <Input 
                        placeholder="Bank receiver name" 
                        value={this.state.bank_receivername}
                        onChange={(val)=>{this.setState({bank_receivername:val.target.value})}} 
                        />              
                  </FormItem> 
                  </div>
               
                  :
                  null
                }
  
              {
                  this.state.receiver_type==="other" && this.state.transactionchannel==="cash"?
                  <FormItem label= { <h6 style={{display:'flex'}} >Cash receiver</h6>}                       
                  >
                      <Input 
                        placeholder="Cash receiver" 
                        value={this.state.othercashreceiver}
                        onChange={(val)=>{this.setState({othercashreceiver:val.target.value})}} 
                        />              
                  </FormItem>                 
                  :
                  null
                }
  
              <FormItem label="Amount"
              >
  
              <Input 
               name="transfer_amount" 
               placeholder="transfer amount" 
               value={this.state.transfer_amount}
               onChange={(val)=>{this.setState({transfer_amount:val.target.value})}} 
               type='number'
                />
              </FormItem> 
  
              <FormItem label="Reason"
                >
                    <TextArea
                      placeholder="Reason for transfer"
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      value={this.state.reason}
                      onChange={(val)=>{this.setState({reason:val.target.value})}}
                    />
                </FormItem>
  
                <FormItem label="Select currency"
                  >
                  <Select 
                  placeholder="Select currency" 
                  style={{  }} 
                  value={this.state.selected_currency}
                  onChange={(val)=>{
                    //rest bank accounts
                    this.setState({selected_currency:val})
                  }} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                                        
                  >
                    {this.state.currencies.map(
                      (accnt)=>
                      {
                      return (
                        <Option value={accnt.currency_code}>{accnt.currency_code} | {accnt.currency_name} | {accnt.country}</Option>
                      );
                      
                    })
                      
                    }
                  </Select>
                </FormItem> 
  
  
              <FormItem>
                <Button type="primary" htmlType="submit" >{this.state.itemupdate?" Update":" Add"} </Button>
              </FormItem>
            </Form>
            <br></br>
  
            <div style={{display:'flex',flexDirection:'column',alignItems:'flex-end',justifyContent:'flex-end'}}>
              <h3>{"Data submission Section"}</h3>

              <FormItem label={"Date ( "+this.state.date+" )"}>
                  <DatePicker onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
              </FormItem>
  
              <FormItem >
                <Button type="primary" htmlType="button" onClick={()=>{
                  this.handleFormSubmit()
  
                }}>Submit Preparation ({this.state.transfer_list.length}) </Button>
              </FormItem>
              </div>
          </div>
        );

      }


    }
  }

}

export default TransactionCreateForm;
