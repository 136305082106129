import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined } from '@ant-design/icons';
var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''
var sacco= ''

class GroupForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accounttypes:[],
    groupname:'' ,  
    District:'',
    subcounty:'',
    parish:'',
    village:'',
    account_type:'',
    accountnumber:'',
    noofmembers:0,
    nooffemales:0,
    noofmales:0,
    MembersFile:null,
    datarequested:true,
    datasubmittedsuccessfully:false,
    saccopackageobj:{},
    members:[],
    saccoboject:{},
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounttypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          accounttypes:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          saccoboject:res.data
        })

        //get the sacco package here
        axios.get(serverconfig.backendserverurl+`/api/packages/${res.data.package}`)
        .then(res => {
            this.setState({
              saccopackageobj:res.data
            })

            this.setState({datarequested:false})

        })

    })

}

//check empty object
 isEmpty=(obj)=> {
  for(var prop in obj) {
      if(obj.hasOwnProperty(prop))
          return false;
  }

  return true;
}


  //submit button pressed
  handleFormSubmit=(event) =>{
    if (this.state.RegisteredPhoneNumber1===''){
      message.info("Group Contact One is Mandatory")
    }else{
      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('date', this.state.date);
      form_data.append('group_name', this.state.groupname);
      form_data.append('district', this.state.District);
      form_data.append('subcounty', this.state.subcounty);
      form_data.append('parish', this.state.parish);
      form_data.append('accountType', this.state.account_type);
      form_data.append('area_of_origin', this.state.village);
      form_data.append('group_account', this.state.accountnumber);
      form_data.append('number_of_members', this.state.noofmembers);
      form_data.append('number_of_females', this.state.nooffemales);
      form_data.append('number_of_males', this.state.noofmales);
      form_data.append('sacco', sacco);
      form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
      form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);

      this.state.MembersFile==null?
      console.log("No Members file")
      :
      form_data.append('all_group_members', this.state.MembersFile, this.state.MembersFile?this.state.MembersFile.name:"");
    
      axios.post(serverconfig.backendserverurl+'/api/groups/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
      console.log(res.data)
      console.log("is empty: "+this.isEmpty(res.data))
      if(this.isEmpty(res.data)===false){
        let form_data1 = new FormData();
        form_data1.append('date', this.state.date);
        form_data1.append('group_name', this.state.groupname);
        form_data1.append('district', this.state.District);
        form_data1.append('subcounty', this.state.subcounty);
        form_data1.append('parish', this.state.parish);
        form_data1.append('accountType', this.state.account_type);
        form_data1.append('area_of_origin', this.state.village);
        form_data1.append('group_account', this.state.accountnumber);
        form_data1.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
        form_data1.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);
  
        form_data1.append('sacco', sacco);

        axios.post(serverconfig.backendserverurl+'/customqueries/creategroupaccount', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
        .then(res => {
          this.setState({datarequested:false})
          //window.location.reload(false)
          this.setState({datasubmittedsuccessfully:true})
        })
        .catch(error => console.log(error))

      }

    }    
    )
    .catch(error => console.log(error))



    }

  }

  handlegroupnameChange= (e) => this.setState({ groupname: e.target.value});
  handleDistrictChange= (e) => this.setState({ District: e.target.value});
  handlesubcountyChange= (e) => this.setState({ subcounty: e.target.value});
  handleparishChange= (e) => this.setState({ parish: e.target.value});
  handleaccount_typeChange= (e) => this.setState({ account_type: e});
  handlevillageChange= (e) => this.setState({ village: e.target.value});
  handleaccountnumberChange= (e) => this.setState({ accountnumber: e.target.value});
  handlenoofmembersChange= (e) => this.setState({ noofmembers: e.target.value});
  handlenooffemalesChange= (e) => this.setState({ nooffemales: e.target.value});
  handlenoofmalesChange= (e) => this.setState({ noofmales: e.target.value});
  handleMembersFileChange= (e) =>{
    this.setState({ MembersFile: e.target.files[0]});
  } 

  handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});
  handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{


      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Created New Group Account "
            subTitle="Group details will be further reviewed if need be"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

          if(this.state.members.length>=this.state.saccopackageobj.memberaccountslimit){
            return (
              <div>
                <h4 style={{color:'red'}}>You have reached members limit.Please contact adminstrators for upgrade of package.Thanx</h4>
              </div>
            );

          }else{

            return (
              <div>
                <Form
                    onFinish={(event) => this.handleFormSubmit(event)}
                >
                <h3>Group Detail Section</h3>
                  <FormItem label="Group Name"
                      name="groupname"
                      rules={[
                        {
                          required: true,
                          message: 'Please input name',
                        },
                      ]}          
                  >
                    <Input  placeholder="Put a name here." value={this.state.groupname} onChange={this.handlegroupnameChange} />
                  </FormItem>


                  <FormItem label="District"
                    name="District"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter district'
                      },
                    ]} 
                  >
                    <Input  placeholder="District." value={this.state.District} onChange={this.handleDistrictChange} />
                  </FormItem>
      
                  <FormItem label="Subcounty"
                    name="Subcounty"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Subcounty'
                      },
                    ]}                   
                  
                  >
                    <Input  placeholder="Subcounty." value={this.state.subcounty} onChange={this.handlesubcountyChange} />
                  </FormItem>
      
                  <FormItem label="Parish"
                    name="Parish"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter parish'
                      },
                    ]}                   
                  
                  >
                    <Input  placeholder="Parish." value={this.state.parish} onChange={this.handleparishChange} />
                  </FormItem>
      
                  <FormItem label="Village"
                    name="Village"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Village'
                      },
                    ]}                   
                                    
                  >
                    <Input  placeholder="Vallage." value={this.state.village} onChange={this.handlevillageChange} />
                  </FormItem>

                  <FormItem label="Account Type"
                     name="accountype"
                     rules={[
                       {
                         required: true,
                         message: 'Please enter account Type',
                       },
                     ]}           
                  >
                      <Select placeholder="Account Type" style={{  }} value={this.state.account_type} onChange={this.handleaccount_typeChange} >
                        {this.state.accounttypes.map(
                          (stf)=>(
                            <Option value={stf.id}>{stf.account_type_name}</Option>
                          ))}
                      </Select>
                  </FormItem>
      
                  <FormItem label="Account Number"
                    name="accountnumber"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter account Number'
                      },
                    ]} 
                  
                  >
                    <Input placeholder="Account Number." value={this.state.accountnumber} onChange={this.handleaccountnumberChange} />
                  </FormItem>
                  
                  <FormItem label="No Of Members">
                    <Input placeholder="Total number of members" value={this.state.noofmembers} onChange={this.handlenoofmembersChange} />
                  </FormItem>
                   
      
                  <FormItem label="No Of Males">
                    <Input placeholder="Total Number Of Males." value={this.state.noofmales} onChange={this.handlenoofmalesChange} />
                  </FormItem>
      
                  <FormItem label="No Of Females">
                    <Input placeholder="Total Number Females." value={this.state.nooffemales} onChange={this.handlenooffemalesChange} />
                  </FormItem>
      
                  <FormItem label="Members Files" style={{marginRight:3}}>
                        <Input type="file" accept="*" placeholder="Members File"  onChange={this.handleMembersFileChange}/>
                  </FormItem> 

                  <h3>Group Account Contacts</h3>

                  <FormItem label="Phone One"                    
                  >
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber1} onChange={this.handleRegisteredPhoneNumber1Change}/>
                   </FormItem> 
        
                   <FormItem label="Phone Two">
                   <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber2} onChange={this.handleRegisteredPhoneNumber2Change}/>
                   </FormItem>

                  <FormItem >
                    <Button  type='primary' htmlType="submit">Submit</Button>
                  </FormItem>

                </Form>
              </div>
            );

          }


      }

    }
    
  }

}

export default GroupForm;



