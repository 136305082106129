import React from 'react';
import AccountTypeForm from '../components/AccountTypeForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin } from 'antd';

import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';

import FDAView from './AccountCreateFDAView'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

var sacco= ''
var token= ''

class FixedDepositLists extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    fixeddeposits: [],
    datarequested:true,

  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/fixeddeposits/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          fixeddeposits:res.data
        })

        this.setState({datarequested:false})

    })
}

 

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {
    const columns = [
      {
        title: 'Account Name',
        dataIndex: 'accountname',
        key: 'id',
        ...this.getColumnSearchProps('accountname'),
      },
      {
        title: 'Account Number',
        dataIndex: 'accountno',
        key: 'id',
        ...this.getColumnSearchProps('accountno'),
      },
      {
        title: 'Fixed Amount',
        dataIndex: 'fixedamount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },

      {
        title: 'Period',
        dataIndex: 'period',
        key: 'id',
      }, 
      {
        title: 'Interest Rate',
        dataIndex: 'interestrate',
        key: 'id',
      },    
      {
        title: 'Interest Type',
        dataIndex: 'interesttype',
        key: 'id',
      },       
      {
        title: 'No of Payments',
        dataIndex: 'no_ofinterestpayments',
        key: 'id',
      },   
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here view schedule & statement</p>} title="Edit,View">
         <Link to={`/fixeddeposits/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        </p>,
        
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="FIXED DEPOSIT LIST" key="1">
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.fixeddeposits} 
            bordered/>
            </Panel>
            </Collapse>
            <br />
            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Collapse Panel to open Form" key="1">
              <h2>Create Fixed Deposit Here</h2>
              <br></br>
              <FDAView />

            </Panel>
            </Collapse>
        </div>
    )

    }

  }
}

export default FixedDepositLists; 
