import React from 'react';

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Form,Popconfirm,message,Descriptions,Modal,Spin, Card,Select } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { FilePdfFilled,SearchOutlined,PrinterOutlined,DeleteOutlined,FundViewOutlined,DownloadOutlined,EditOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
const { Option } = Select;

function callback(key) {
  console.log(key);
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var bizuserid= ''
var token= ''
var ngoid= ''



class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){
    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/ngos/${ngoid}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

  }

//calculates here
  calcu_totalfees=()=>{
    var tot=0
    this.props.schoolfees_budget.map((item)=>{
      tot+=Number(item.school_fees_amount)
    })
    return tot;
  }

  calcu_totalfeespaid=()=>{
    var tot=0
    this.props.schoolfees_budget.map((item)=>{
      tot+=Number(item.fees_amount_paid)
    })
    return tot;
  }

  calcu_totalfeesbalance=()=>{
    var tot=0
    this.props.schoolfees_budget.map((item)=>{
      tot+=Number(item.fees_balance)
    })
    return tot;
  }


  //calreqments
  calcu_totalreqs=()=>{
    var tot=0
    this.props.schoolfees_budget.map((item)=>{
      tot+=Number(item.other_requirements_amount)
    })
    return tot;
  }

  calcu_totalreqspaid=()=>{
    var tot=0
    this.props.schoolfees_budget.map((item)=>{
      tot+=Number(item.rqmnts_paidamount)
    })
    return tot;
  }

  calcu_totalreqsbalance=()=>{
    var tot=0
    this.props.schoolfees_budget.map((item)=>{
      tot+=Number(item.rqmnts_balance)
    })
    return tot;
  }


  render() {
    return (
      <div style={{padding:20}}>
        <Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.ngo_logo} />
              
              </Col>
              <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.ngo_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
              
                </Col>
          </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>CHILDREN SCHOOL FEES BUDGET FOR {this.props.selectedngoname} {this.props.selectedtermname}</h3>

              <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>School term</th>
                          <th>Child name</th>
                          <th>Fees amount</th>
                          <th>Fees Paid</th>
                          <th>Fees balance</th>
                          <th>Requirements amount</th>
                          <th>Requirements amount paid</th>
                          <th>Requirements balance</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.props.schoolfees_budget.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.school_term_name}</td>
                          <td>{item.FullName}</td>
                          <td>{<CurrencyFormat value={item.school_fees_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.fees_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.fees_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.other_requirements_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.rqmnts_paidamount} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.rqmnts_balance} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calcu_totalfees()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calcu_totalfeespaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calcu_totalfeesbalance()} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calcu_totalreqs()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calcu_totalreqspaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calcu_totalreqsbalance()} displayType={'text'} thousandSeparator={true}/>}</td>

                        </tr>

                    </tbody>
           </reactstrp.Table> 
      </div>
    );
  }
}





class FeesListReport_Others extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    schoolfees_budget: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    userrights:{},

    ngos: [],
    selectedngo:'',
    selectedngoname:'',
    schoolterms:[],
    selectedterm:'',
    selectedtermname:'',
  };


  componentDidMount(){

    
    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }
    
     if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   this.componentmountactions();

}

//component mount method
componentmountactions=()=>{

  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/ngos/?ngo_supervisor_id=${ngoid}`)
  .then(res => {
      this.setState({
        ngos:res.data
      })
      this.setState({datarequested:false})
  })

}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/schoolfees_budget/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );

  };



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



//calculates here
calcu_totalfees=()=>{
  var tot=0
  this.state.schoolfees_budget.map((item)=>{
    tot+=Number(item.school_fees_amount)
  })
  return tot;
}

calcu_totalfeespaid=()=>{
  var tot=0
  this.state.schoolfees_budget.map((item)=>{
    tot+=Number(item.fees_amount_paid)
  })
  return tot;
}

calcu_totalfeesbalance=()=>{
  var tot=0
  this.state.schoolfees_budget.map((item)=>{
    tot+=Number(item.fees_balance)
  })
  return tot;
}


//calreqments
calcu_totalreqs=()=>{
  var tot=0
  this.state.schoolfees_budget.map((item)=>{
    tot+=Number(item.other_requirements_amount)
  })
  return tot;
}

calcu_totalreqspaid=()=>{
  var tot=0
  this.state.schoolfees_budget.map((item)=>{
    tot+=Number(item.rqmnts_paidamount)
  })
  return tot;
}

calcu_totalreqsbalance=()=>{
  var tot=0
  this.state.schoolfees_budget.map((item)=>{
    tot+=Number(item.rqmnts_balance)
  })
  return tot;
}




  render() {

      var columns = [
        {
          title: 'Date of entry',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'School term',
          dataIndex: 'school_term_name',
          key: 'id',
          ...this.getColumnSearchProps('school_term_name'),
        },
        {
          title: 'Child Name',
          dataIndex: 'FullName',
          key: 'id',
          ...this.getColumnSearchProps('FullName'),
        },
        {
          title: 'Fees amount',
          dataIndex: 'school_fees_amount',
          key: 'id',
          render: (text,record) =><p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
        },
        {
          title: 'Paid fees amount',
          dataIndex: 'fees_amount_paid',
          key: 'id',
          render: (text,record) =><p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
        },
        {
          title: 'Fees balance amount',
          dataIndex: 'fees_balance',
          key: 'id',
          render: (text,record) =><p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
        },
        {
          title: ' Requirements amount',
          dataIndex: 'other_requirements_amount',
          key: 'id',
          render: (text,record) =><p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
        },

        {
          title: 'Requirements amount paid',
          dataIndex: 'rqmnts_paidamount',
          key: 'id',
          render: (text,record) =><p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
        },
        {
          title: 'Requirements balance',
          dataIndex: 'rqmnts_balance',
          key: 'id',
          render: (text,record) =><p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
        },

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to view payments</p>} title="Payments">
           <Link to={`/schoolfeespayments/${text}`}><WalletFilled style={{color:'blue'}}/></Link>
          </Popover>
          </p>,
        }

      ];
    
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>

            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              schoolfees_budget={this.state.schoolfees_budget} 
              selectedtermname={this.state.selectedtermname} 
              selectedngoname={this.state.selectedngoname}

              ref={el => (this.componentRef = el)} /> 
            </div>



            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>CHILDREN SCHOOL FEES BUDGET</h4>} key="1">
            
            <Form  layout="inline" >


            <FormItem label="Ngo">
                <Select 
                placeholder="select Ngo"
                style={{ width: 200 }} 
                value={this.state.selectedngo} 
                onChange={(val)=>{
                this.setState({selectedngo:val})
              
                axios.get(`${serverconfig.backendserverurl}/api/ngos/${val}`)
                .then(res => {  
                    this.setState({selectedngoname:res.data.ngo_name})
                })

                //get school terms
                axios.get(`${serverconfig.backendserverurl}/api/schoolterms/?Ngo=${val}`)
                .then(res => {  
                    this.setState({schoolterms:res.data})
                 })

                }}

                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }                     
                >
                  {
                    this.state.ngos.map((item)=>{
                      return (<Option value={item.id}>{item.ngo_name}</Option>);
                    })
                  }

                </Select>
            </FormItem>

            {
              this.state.schoolterms.length>0?
              <FormItem label="School term">
              <Select 
              placeholder="select school term"
              style={{ width: 200 }} 
              value={this.state.selectedterm} 
              onChange={(val)=>{
                this.setState({selectedterm:val})

                axios.get(`${serverconfig.backendserverurl}/api/schoolterms/${val}`)
                .then(res => {  
                    this.setState({selectedtermname:res.data.term_name})
                })

              }}

              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }                     
              >
                {
                  this.state.schoolterms.map((item)=>{
                    return (<Option value={item.id}>{item.term_name}</Option>);
                  })
                }

              </Select>
            </FormItem>
            :
            null
          }


        <FormItem>
        <Button onClick={()=>{

            this.setState({schoolfees_budget:[]})

           if (this.state.selectedterm===""){
              message.info("Please select term")
            }else{

              this.setState({datarequested:true})

              let form_data = new FormData();
              form_data.append('ngo', this.state.selectedngo);
              form_data.append('selectedterm', this.state.selectedterm);
  
              axios.post(serverconfig.backendserverurl+`/customqueries/getschoolfeesbudgetbyge`, form_data,{
                headers: {
                  'content-type': 'multipart/form-data'
                }
                })
              .then(res => {
                this.setState({
                  schoolfees_budget:JSON.parse(res.data.report)
  
                })
                this.setState({datarequested:false})
              })

            }


        }} type="primary" htmlType="button">Search</Button>
        </FormItem> 
        </Form>      

        <Table   
        columns={columns} 
        scroll={{ x: 1000 }}
        dataSource={this.state.schoolfees_budget} 
        pagination={{showQuickJumper:true,showSizeChanger:true }}
        bordered/>


        <reactstrp.Table bordered>
                    <tbody>

                        <tr>
                        <td>
                        <h4 style={{fontWeight:'bolder'}}>Total Fees: {<CurrencyFormat value={this.calcu_totalfees()} displayType={'text'} thousandSeparator={true}/>}</h4>
                        <h4 style={{fontWeight:'bolder'}}>Total Fees paid: {<CurrencyFormat value={this.calcu_totalfeespaid()} displayType={'text'} thousandSeparator={true}/>}</h4>
                        <h4 style={{fontWeight:'bolder'}}>Total fees balance{<CurrencyFormat value={this.calcu_totalfeesbalance()} displayType={'text'} thousandSeparator={true}/>}</h4>

                        </td>
                        <td>

                        <h4 style={{fontWeight:'bolder'}}>Total Requirements amount: {<CurrencyFormat value={this.calcu_totalreqs()} displayType={'text'} thousandSeparator={true}/>}</h4>
                        <h4 style={{fontWeight:'bolder'}}>Total requirements paid amount: {<CurrencyFormat value={this.calcu_totalreqspaid()} displayType={'text'} thousandSeparator={true}/>}</h4>
                        <h4 style={{fontWeight:'bolder'}}>Total requirements balance: {<CurrencyFormat value={this.calcu_totalreqsbalance()} displayType={'text'} thousandSeparator={true}/>}</h4>

                        </td>

                        </tr>

                    </tbody>
           </reactstrp.Table> 



        <br />
        </Panel>
        </Collapse>
        <br></br>

        <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
          <ReactToPrint
            trigger={() => 
            <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
            Print
            </Button>
            }
            content={() => this.componentRef}
            />
        </div>
        </div>
    )

    }

  }
}

export default FeesListReport_Others; 
