import React from 'react';
import { Route } from 'react-router-dom';
import QuickMenu from './views/containers/QuickMenu'
import IDTypeList from './views/containers/IDListView'
import IDTypeDetail from './views/containers/IDDetailView'
import ExpenseCategoryList from './views/containers/ExpenseCategorysListView'
import ExpenseCategoryDetail from './views/containers/ExpenseCategoryDetailView'
import StaffRoleList from './views/containers/StaffRolesListView'
import StaffRoleDetail from './views/containers/StaffRoleDetailView'
import ExpenseList from './views/containers/ExpenseListView'
import ExpenseDetail from './views/containers/ExpenseDetailView'
import AssetList from './views/containers/AssetListView'
import AssetDetail from './views/containers/AssetDetailView'
import AssetCategoryList from './views/containers/AssetCategorysListView'
import AssetCategoryDetail from './views/containers/AssetCategoryDetailView'
import StaffList from './views/containers/StaffListView'
import StaffDetail from './views/containers/StaffDetailView'
import AccountTypeList from './views/containers/AccountTypeListView'
import AccountTypeDetail from './views/containers/AccountTypeDetailView'
import LoanTypeList from './views/containers/LoanTypeListView'
import LoanTypeDetail from './views/containers/LoanTypeDetailView'
import NonWorkingDayList from './views/containers/NonWorkingDayListView'
import NonWorkingDayDetail from './views/containers/NonWorkingDayDetailView'
import IncomeCategoryList from './views/containers/IncomeCategorysListView'
import IncomeCategoryDetail from './views/containers/IncomeCategoryDetailView'
import CashAtBankList from './views/containers/CashAtBankListView'
import CashAtBankDetail from './views/containers/CashAtBankDetailView'
import ExternalLoanList from './views/containers/ExternalLoanListView'
import ExternalLoanDetail from './views/containers/ExternalLoanDetailView'
import GuardiansList from './views/containers/GuardiansListView'
import MemberDetail from './views/containers/MemberDetailView'
import AccountDepositView from './views/containers/AccountDepositView'
import AccountWithdrawView from './views/containers/AccountWithdrawView'
import LoanApplicationForm from './views/components/LoanApplicationForm'
import  LoanCalculator from './views/components/LoanCalculator'
import  LoanApplicationsList from './views/containers/LoanApplicationListView'
import  LoanApplicationDetail from './views/containers/LoanApplicationDetailView'
import  LoanAssessmentForm from './views/components/LoanAssessmentForm'
import  LoanAssessmentList from './views/containers/LoanAssessmentListView'
import  LoanAppovalForm from './views/components/LoanApprovalForm'
import  LoanApprovalList from './views/containers/LoanApprovalListView'
import  LoanIssuanceForm from './views/components/LoanIssuanceForm'
import  LoanIssuanceList from './views/containers/LoanIssuancesListView'
import  LoanIssueDetail from './views/containers/LoanIssueDetails'
import  LoanRepaymentView from './views/containers/LoanRepaymentView'
import  IncomeList from './views/containers/IncomeListView'
import IncomeDetail from './views/containers/IncomeDetailView'
import LoanGuarantorsList from './views/containers/LoanGuarantorsListView'
import LoanConsentsList from './views/containers/LoanConsentsListView'
import OpeningItemList from './views/containers/AccountOpeningItemsListView'
import TillSheetReport from './views/containers/TillSheetReportView'
import TransactionReport from './views/containers/TransactionReportView'
import MemberSavingsReport from './views/containers/MemberSavingsReportView'
import IncomeStatementReport from './views/containers/IncomeStatementView'
import DayLoanReport from './views/containers/DayLoanReportView'
import OutStandingLoanReport from './views/containers/OutStandingLoanReportView'
import LoanRepaymentStatusReport from './views/containers/LoanRepaymentStatusReportView'
import LoanDefaultsReport from './views/containers/LoanDefaultsReportView'
import LoanAgingReport from './views/containers/LoanAgingReportView'
import LoanDisbursmentReport from './views/containers/LoanDisbursmentReportView'
import LoanRepaymentsReport from './views/containers/LoanRepaymentsReportView'
import BalanceSheetReport from './views/containers/BalanceSheetView'
import ShareTransferView from './views/containers/ShareTransferView'
import SubAccountTransferView from './views/containers/SubAccountTransferView'
import FinancialStatementReport from './views/containers/FinancialStatementReportView'

import AccountList from './views/containers/AccountsListView'
import AccountDetail from './views/containers/AccountDetailView'
import SendSMSMessageView from './views/containers/SendSMSMessageView'
import GroupList from './views/containers/GroupsListView'
import GroupDetail from './views/containers/GroupDetailView'
import UserBalancesReport from './views/containers/UserBalancesReportView'
import ExternalDonationList from './views/containers/ExternalDonationListView'
import ExternalDonationDetail from './views/containers/ExternalDonationDetailView'
import DashBoard from './views/containers/DashBoard'
import DataSyncForm from './views/components/DataSyncForm'
import FixedDepositLists from './views/containers/FixedDepositListView'
import FixedDepositDetails from './views/containers/FixedDepositDetailsView'
import FixedDepositReport from './views/containers/FixedDepositReportView'
import SalaryRequistionView from './views/containers/SalaryRequistionView'
import SalaryRequistionList from './views/containers/salaryrequisitionListView'
import MemberReserveReport from './views/containers/MemberReserveReportView'
import NSSFReport from './views/containers/NssfReportView'
import  LoanRepaymentDirectView from './views/containers/LoanRepaymentDirectView'
import SaccoSmsUsagestatementreport from './views/containers/saccosmsusagestatementreport'
import NGOList from './views/containers/NGOListView'
import AgeTypeList from './views/containers/AgeTypeListView'
import DistrictList from './views/containers/UgDistrictListView'
import UgDistrictDetail from './views/containers/UgDistrictDetailView'
import SchoolList from './views/containers/SchoolListView'
import ChildrenList from './views/containers/ChildrenListView'
import CompanyAccountList from './views/containers/CompanyAccountsListView'
import TrialBalanceReport from './views/containers/TrialBalanceView'
import LedgerReport from './views/containers/LedgerReportView'
import GuardianDetail from './views/containers/GuardianDetailView'
import ChildDetail from './views/containers/ChildDetailView'
import ChildDocumentsList from './views/containers/ChilddocumentsView'
import TransactionList from './views/containers/transaction_sponsorlist'
import NgoTransactionsList from './views/containers/transaction_ngolist'
import NgoMobileNumberList from './views/containers/NgoMobileNumberListView'
import NgoDetail from './views/containers/NgoDetailView'

import ChildrenReport from './views/containers/childrenreport '
import Expensereport from './views/containers/Expensereport'
import GuardiansReport from './views/containers/guardiansreport'
import NGOUsers from './views/containers/ngousers'
import NGOUserAccountDetail from './views/containers/ngouseraccountdetailview'
import guardian_children from './views/containers/guardian_children'
import SchoolTermList from './views/containers/SchoolTermListView'
import SchoolTermDetail from './views/containers/SchoolTermDetailView'

import FeesList from './views/containers/SchoolFeesListView'
import SchoolFeesDetail from './views/containers/SchoolFeesDetailView'
import FeesPaymentList from './views/containers/SchoolFeesPaymentListView'
import DevelopmentReportList from './views/containers/ChildrenDevelopmentReportListView'
import TransactionBatchList from './views/containers/transaction_batchlist'
import TransactionCreatefinalForm from './views/components/Transactioncreatefinalform'
import SchoolDetail from './views/containers/SchoolDetailView'
import FeesListReport from './views/containers/SchoolFeesListViewReport'
import FeesListReport_Others from './views/containers/SchoolFeesListViewReport_Others'
import ScheduleNotificationList from './views/containers/ScheduleNotificationListView'


const SaccoBaseRouter = () =>(
    <div>
        <Route exact path='/' component={DashBoard}  />
        <Route exact path='/quickmenu/' component={QuickMenu}  />
        <Route exact path='/fixeddeposits/' component={FixedDepositLists}  />
        <Route exact path='/fixeddeposits/:depositid' component={FixedDepositDetails}  />
        <Route exact path='/fixeddepositreport/' component={FixedDepositReport}  />

        <Route exact path='/idtypes/' component={IDTypeList}  />
        <Route exact path='/idtypes/:idtypeID' component={IDTypeDetail}  />
        <Route exact path='/expensecategories/' component={ExpenseCategoryList}  />
        <Route exact path='/expensecategories/:expensecategoryID' component={ExpenseCategoryDetail}  />
        <Route exact path='/staffroles/' component={StaffRoleList}  />
        <Route exact path='/staffroles/:staffroleID' component={StaffRoleDetail}  />
        <Route exact path='/expenses/' component={ExpenseList}  />
        <Route exact path='/expenses/:expenseID' component={ExpenseDetail}  />
        <Route exact path='/assets/' component={AssetList}  />
        <Route exact path='/assets/:assetID' component={AssetDetail}  />
        <Route exact path='/assetcategories/' component={AssetCategoryList}  />
        <Route exact path='/assetcategories/:assetcategoryID' component={AssetCategoryDetail}  />
        <Route exact path='/staffs/' component={StaffList}  />
        <Route exact path='/staffs/:staffID' component={StaffDetail}  />
        <Route exact path='/accounttypes/' component={AccountTypeList}  />
        <Route exact path='/accounttypes/:accountTypeID' component={AccountTypeDetail}  />
        <Route exact path='/loantypes/' component={LoanTypeList}  />
        <Route exact path='/loantypes/:loanTypeID' component={LoanTypeDetail}  />
        <Route exact path='/nonworkingdays/' component={NonWorkingDayList}  />
        <Route exact path='/nonworkingdays/:dayID' component={NonWorkingDayDetail}  />
        <Route exact path='/incomecategories/' component={IncomeCategoryList}  />
        <Route exact path='/incomecategories/:categoryID' component={IncomeCategoryDetail}  />
        <Route exact path='/cashatbank/' component={CashAtBankList}  />
        <Route exact path='/cashatbank/:cashID' component={CashAtBankDetail}  />
        <Route exact path='/externalloans/' component={ExternalLoanList}  />
        <Route exact path='/externalloans/:loanID' component={ExternalLoanDetail}  />
        <Route exact path='/guardians/' component={GuardiansList}  />
        <Route exact path='/members/:memberID' component={MemberDetail}  />
        <Route exact path='/deposit/' component={AccountDepositView}  />
        <Route exact path='/withdraw/' component={AccountWithdrawView}  />
        <Route exact path='/loanapplication/' component={LoanApplicationForm}  />
        <Route exact path='/loancalculator/' component={LoanCalculator}  />
        <Route exact path='/loanapplications/' component={LoanApplicationsList}  />
        <Route exact path='/loanapplications/:loanapplicationID' component={LoanApplicationDetail}  />
        <Route exact path='/makeassessment/:loanapplicationID' component={LoanAssessmentForm}  />
        <Route exact path='/loanassessments/' component={LoanAssessmentList}  />
        <Route exact path='/approveloan/:loanapplicationID' component={LoanAppovalForm}  />
        <Route exact path='/loanapprovals/' component={LoanApprovalList}  />
        <Route exact path='/issueloan/:loanapplicationID' component={LoanIssuanceForm}  />
        <Route exact path='/loanissues/' component={LoanIssuanceList}  />
        <Route exact path='/loanissues/:loanissueID' component={LoanIssueDetail}  />
        <Route exact path='/loanrepayment/' component={LoanRepaymentView}  />
        <Route exact path='/incomes/' component={IncomeList}  />
        <Route exact path='/incomes/:incomeID' component={IncomeDetail}  />
        <Route exact path='/loanguarantors/:applicationID' component={LoanGuarantorsList}  />
        <Route exact path='/loanconsents/:applicationID' component={LoanConsentsList}  />
        <Route exact path='/accountopeningitems/:accountTypeID' component={OpeningItemList}  />
        <Route exact path='/tillsheet/' component={TillSheetReport}  />
        <Route exact path='/accounttransactions/' component={TransactionReport} />
        <Route exact path='/memberbalances/' component={MemberSavingsReport} />
        <Route exact path='/incomestatement/' component={IncomeStatementReport} />
        <Route exact path='/dayloanreport/' component={DayLoanReport} />
        <Route exact path='/loanoutstanding/' component={OutStandingLoanReport} />
        <Route exact path='/loanrepaymentstatus/' component={LoanRepaymentStatusReport} />
        <Route exact path='/loandefaults/' component={LoanDefaultsReport} />
        <Route exact path='/loanaging/' component={LoanAgingReport} />
        <Route exact path='/loandisbursement/' component={LoanDisbursmentReport} />
        <Route exact path='/loanrepaymentreport/' component={LoanRepaymentsReport} />
        <Route exact path='/balancesheet/' component={BalanceSheetReport} />
        <Route exact path='/sharetransfer/' component={ShareTransferView} />
        <Route exact path='/subaccounttransfer/' component={SubAccountTransferView} />
        <Route exact path='/financialstatement/' component={FinancialStatementReport} />
        <Route exact path='/useraccounts/' component={AccountList} />
        <Route exact path='/useraccounts/:accountID' component={AccountDetail} />
        <Route exact path='/messaging/' component={SendSMSMessageView} />
        <Route exact path='/groups/' component={GroupList} />
        <Route exact path='/groups/:groupID' component={GroupDetail}  />
        <Route exact path='/userbalances/' component={UserBalancesReport} />
        <Route exact path='/externaldonations/' component={ExternalDonationList} />
        <Route exact path='/externaldonations/:donationID' component={ExternalDonationDetail}  />
        <Route exact path='/datasync/' component={DataSyncForm}  />
        <Route exact path='/salaryrequisition/' component={SalaryRequistionView}  />
        <Route exact path='/salaryrequisitionlist/' component={SalaryRequistionList} />
        <Route exact path='/loanreservereport/' component={MemberReserveReport} />
        <Route exact path='/nssfreport/' component={NSSFReport} />
        <Route exact path='/loanrepaymentdirect/' component={LoanRepaymentDirectView}  />
        <Route exact path='/saccosmsusagestatement/' component={SaccoSmsUsagestatementreport} />
        <Route exact path='/managengos/' component={NGOList} />

        <Route exact path='/agetypes/' component={AgeTypeList}  />
        <Route exact path='/ugdistricts/' component={DistrictList}  />
        <Route exact path='/ugdistricts/:districtID' component={UgDistrictDetail}  />

        <Route exact path='/manageschools/' component={SchoolList} />
        <Route exact path='/managechildren/' component={ChildrenList} />
        <Route exact path='/companyaccounts/' component={CompanyAccountList}  />

        <Route exact path='/trialbalance/' component={TrialBalanceReport}  />
        <Route exact path='/ledgerreport/' component={LedgerReport}  />
        <Route exact path='/guardians/:guardianID' component={GuardianDetail}  />
        <Route exact path='/children/:childID' component={ChildDetail}  />
        <Route exact path='/childdocuments/:childID' component={ChildDocumentsList}  />
        <Route exact path='/sponsortransactions/' component={TransactionList}  />
        <Route exact path='/ngo-ngotransactions/' component={NgoTransactionsList}  />
        <Route exact path='/ngomobilemoneycontacts/' component={NgoMobileNumberList}  />
        <Route exact path='/ngodetails/:ngoID' component={NgoDetail}  />

        <Route exact path='/childrensreport/' component={ChildrenReport}  />
        <Route exact path='/expensesreport/' component={Expensereport}  />
        <Route exact path='/guardiansreport/' component={GuardiansReport}  />

        <Route exact path='/ngousers/:ngoId' component={NGOUsers}  />
        <Route exact path='/ngousersdetails/:userid' component={NGOUserAccountDetail}  />
        <Route exact path='/guardianchildren/:guardianID' component={guardian_children}  />
        <Route exact path='/manageterms/' component={SchoolTermList}  />
        <Route exact path='/manageterms/:termID' component={SchoolTermDetail}  />

        <Route exact path='/manageschoolfees/' component={FeesList}  />
        <Route exact path='/manageschoolfees/:feesID' component={SchoolFeesDetail}  />
        <Route exact path='/schoolfeespayments/:feesID' component={FeesPaymentList}  />
        <Route exact path='/managedevelopmentreports/' component={DevelopmentReportList}  />
        <Route exact path='/ngo_transactionbatches/' component={TransactionBatchList}  />
        <Route exact path='/transactionbatchdetails/:batchID' component={TransactionCreatefinalForm}  />

        <Route exact path='/schooldetails/:schoolID' component={SchoolDetail}  />
        <Route exact path='/schoolfeesreport/' component={FeesListReport}  />
        <Route exact path='/schoolfeesreport_others/' component={FeesListReport_Others}  />
        <Route exact path='/schedulednotifications/' component={ScheduleNotificationList}  />

    </div>
);

export default SaccoBaseRouter;


