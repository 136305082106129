import React from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import StaffUserSignUpForm from '../components/StaffUserSignUp'

var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Panel } = Collapse;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});



var sacco= ''
var username=''
var token= ''
var ngoid= ''

class AccountList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    accounts: [],
    rightsmodalvisible:false,
    rights: {},
    selectedid:'',
    datarequested:true,

    can_view_statistics:false,    
    can_view_guardians:false,
    can_create_guardians:false,
    can_edit_delete_guardians:false,
    can_view_children:false,
    can_create_children:false,
    can_edit_delete_children:false,
    can_view_otherngos:false,
    can_create_otherngos:false,

    can_edit_delete_otherngos:false,
    can_view_ge_transactions:false,
    can_create_ngotransactions:false,
    can_view_ngotransactions:false,
    can_create_expenses:false,
    can_view_expense:false,
    can_edit_delete_expenses:false,
    can_view_generaljournal:false,
    can_view_ledger:false,
    can_view_trial_balance:false,

    can_create_schoolfees_budget:false,
    can_view_schoolfees_budget:false,
    can_edit_delete_schoolfees_budget:false,

    companyprofile:{},
    show_accountcreate_formvisible:false,

    can_create_childdevelopmentreport:false,
    can_view_childdevelopmentreport:false,

    prepare_payouts:false,
    submit_payouts:false,
    receive_ge_payments:false,

    view_staff:false,
    create_staff:false,
    view_school_fees_budget:false


  };

  displaypasswordupdatemodal=(id)=>{
    this.setState({showpasswordupdatemodal:true})
    this.setState({selectedid:id})
  }
  
  closepasswordupdatemodal=()=>{
    this.setState({showpasswordupdatemodal:false})
  }
  
  //////////////////////////////////////
  ///// change password here
  handlePasswordChange=(values)=>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('password1', values.new_password1);
    form_data.append('password2', values.new_password2);
    form_data.append('userid', this.state.selectedid);
  
    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/updatecompanyuserpassword', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      this.setState({datarequested:false})

      message.info(res.data.message);
    }
    )
    .catch(error => console.log(error)) 
  
  }




  ///////////////////////////////////////////
  //getting simple user account rights
  showAccountRights=(text) =>{
    this.setState({ rightsmodalvisible: true });

    //make api call to get the user rights
    axios.get(`${serverconfig.backendserverurl}/api/accountrights?user=${text}`)
    .then(res => {  
        this.setState({
            rights: res.data[0]
        })

        console.log(res.data[0])

        this.setState({can_view_statistics:res.data[0].can_view_statistics})
      
        this.setState({can_view_guardians:res.data[0].can_view_guardians})
        this.setState({can_create_guardians:res.data[0].can_create_guardians})
        this.setState({can_edit_delete_guardians:res.data[0].can_edit_delete_guardians})
        this.setState({can_view_children:res.data[0].can_view_children})
        this.setState({can_create_children:res.data[0].can_create_children})
        this.setState({can_edit_delete_children:res.data[0].can_edit_delete_children})
        this.setState({can_view_otherngos:res.data[0].can_view_otherngos})
        this.setState({can_create_otherngos:res.data[0].can_create_otherngos})

        this.setState({can_edit_delete_otherngos:res.data[0].can_edit_delete_otherngos})
        this.setState({can_view_ge_transactions:res.data[0].can_view_ge_transactions})
        this.setState({can_create_ngotransactions:res.data[0].can_create_ngotransactions})
        this.setState({can_view_ngotransactions:res.data[0].can_view_ngotransactions})
        this.setState({can_create_expenses:res.data[0].can_create_expenses})
        this.setState({can_view_expense:res.data[0].can_view_expense})
        this.setState({can_edit_delete_expenses:res.data[0].can_edit_delete_expenses})
        this.setState({can_view_generaljournal:res.data[0].can_view_generaljournal})
        this.setState({can_view_ledger:res.data[0].can_view_ledger})
        this.setState({can_view_trial_balance:res.data[0].can_view_trial_balance})

        this.setState({can_create_schoolfees_budget:res.data[0].can_create_schoolfees_budget})
        this.setState({can_view_schoolfees_budget:res.data[0].can_view_schoolfees_budget})
        this.setState({can_edit_delete_schoolfees_budget:res.data[0].can_edit_delete_schoolfees_budget})

        this.setState({can_create_childdevelopmentreport:res.data[0].can_create_childdevelopmentreport})
        this.setState({can_view_childdevelopmentreport:res.data[0].can_view_childdevelopmentreport})

        this.setState({prepare_payouts:res.data[0].prepare_payouts})
        this.setState({submit_payouts:res.data[0].submit_payouts})
        this.setState({receive_ge_payments:res.data[0].receive_ge_payments})

        this.setState({view_staff:res.data[0].view_staff})
        this.setState({create_staff:res.data[0].create_staff})
        this.setState({view_school_fees_budget:res.data[0].view_school_fees_budget})

    })

  }

  //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/accounts/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ rightsmodalvisible: false });
  };


  //make an update now on the user rights
  updaterights=values=>{

    let form_data = new FormData();
    form_data.append('can_view_statistics', this.state.can_view_statistics)

    form_data.append('can_view_guardians', this.state.can_view_guardians)
    form_data.append('can_create_guardians', this.state.can_create_guardians)
    form_data.append('can_edit_delete_guardians', this.state.can_edit_delete_guardians)
    form_data.append('can_view_children', this.state.can_view_children)
    form_data.append('can_create_children', this.state.can_create_children)
    form_data.append('can_edit_delete_children', this.state.can_edit_delete_children)
    form_data.append('can_view_otherngos', this.state.can_view_otherngos)
    form_data.append('can_create_otherngos', this.state.can_create_otherngos)
    form_data.append('can_edit_delete_otherngos', this.state.can_edit_delete_otherngos)
    form_data.append('can_view_ge_transactions', this.state.can_view_ge_transactions)
    form_data.append('can_create_ngotransactions', this.state.can_create_ngotransactions)
    form_data.append('can_view_ngotransactions', this.state.can_view_ngotransactions)
    form_data.append('can_create_expenses', this.state.can_create_expenses)
    form_data.append('can_view_expense', this.state.can_view_expense)
    form_data.append('can_edit_delete_expenses', this.state.can_edit_delete_expenses)
    form_data.append('can_view_generaljournal', this.state.can_view_generaljournal)
    form_data.append('can_view_ledger', this.state.can_view_ledger)
    form_data.append('can_view_trial_balance', this.state.can_view_trial_balance)

    form_data.append('can_create_schoolfees_budget', this.state.can_create_schoolfees_budget)
    form_data.append('can_view_schoolfees_budget', this.state.can_view_schoolfees_budget)
    form_data.append('can_edit_delete_schoolfees_budget', this.state.can_edit_delete_schoolfees_budget)

    form_data.append('can_create_childdevelopmentreport', this.state.can_create_childdevelopmentreport)
    form_data.append('can_view_childdevelopmentreport', this.state.can_view_childdevelopmentreport)

    form_data.append('prepare_payouts', this.state.prepare_payouts)
    form_data.append('submit_payouts', this.state.submit_payouts)
    form_data.append('receive_ge_payments', this.state.receive_ge_payments)

    form_data.append('view_staff', this.state.view_staff)
    form_data.append('create_staff', this.state.create_staff)
    form_data.append('view_school_fees_budget', this.state.view_school_fees_budget)

    //Now send the post resquest
    axios.put(`${serverconfig.backendserverurl}/api/accountrights/${this.state.rights.id}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => window.location.reload(false)
    )
    .catch(error => console.log(error))

  }


  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
      ngoid= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/accounts/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          accounts:res.data
        })

       this.setState({datarequested:false})

    })

    axios.get(serverconfig.backendserverurl+`/api/ngos/${ngoid}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })
    })
  


}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [
      {
        title: 'Profile',
        dataIndex: 'profile_pic',
        key: 'profile_pic',
        render: text => <Avatar
        size={60}
        icon={<UserOutlined />}
        alt="User Pic"
        src={text}
        />,

      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username',
        ...this.getColumnSearchProps('username'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/useraccounts/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>

        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to edit,view user rights</p>} title="Edit,View rights">
         <UserOutlined onClick={(event) => this.showAccountRights(text)} style={{color:'blue'}}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click to change password</p>} title="Password Change">
          <LockFilled style={{color:'#154360'}} onClick={() => this.displaypasswordupdatemodal(text)}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete account</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      },

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return(
        <div>
           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL NGO ACCOUNTS" key="1">
            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.accounts} bordered/>
            </Panel>
            </Collapse>
            <br></br>
            <br></br>
            <Button type='primary' onClick={()=>{this.setState({show_accountcreate_formvisible:true})}}>Add New User account</Button>


            <Drawer
                title={this.state.rights.username+" "+"Account Rights"}
                width={520}
                placement="right"
                closable={false}
                onClose={this.onresetdrawerClose}
                visible={this.state.rightsmodalvisible}
              >

                    <h3 style={{color:'red'}}>Edit User Rights</h3>

                    <Form  
                      onFinish={this.updaterights}        
                      >

                      <FormItem name="can_view_statistics" label="Can view statistics" >
                          <Switch
                            checked={this.state.can_view_statistics}
                            onChange={val=>{this.setState({can_view_statistics:!this.state.can_view_statistics})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>  

                      <FormItem name="can_view_guardians" label="Can view guardians" >
                          <Switch
                            checked={this.state.can_view_guardians}
                            onChange={val=>{this.setState({can_view_guardians:!this.state.can_view_guardians})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem> 


                      <FormItem name="can_create_guardians" label="Can create guardians" >
                          <Switch
                            checked={this.state.can_create_guardians}
                            onChange={val=>{this.setState({can_create_guardians:!this.state.can_create_guardians})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem> 

                      <FormItem name="can_edit_delete_guardians" label="Can edit, delete guardians" >
                          <Switch
                            checked={this.state.can_edit_delete_guardians}
                            onChange={val=>{this.setState({can_edit_delete_guardians:!this.state.can_edit_delete_guardians})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem> 

                      
                      <FormItem name="can_view_children" label="Can view children" >
                          <Switch
                            checked={this.state.can_view_children}
                            onChange={val=>{this.setState({can_view_children:!this.state.can_view_children})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>

                      <FormItem name="can_create_children" label="Can create children" >
                          <Switch
                            checked={this.state.can_create_children}
                            onChange={val=>{this.setState({can_create_children:!this.state.can_create_children})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>

                      <FormItem name="can_edit_delete_children" label="Can edit,delete children" >
                          <Switch
                            checked={this.state.can_edit_delete_children}
                            onChange={val=>{this.setState({can_edit_delete_children:!this.state.can_edit_delete_children})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>



                      {
                  this.state.companyprofile.ngo_controller===true?
                  <FormItem name="can_view_otherngos" label="can_view_otherngos" >
                          <Switch
                            checked={this.state.can_view_otherngos}
                            onChange={val=>{this.setState({can_view_otherngos:!this.state.can_view_otherngos})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>
                  :
                  null
                  }
    

                      {
                  this.state.companyprofile.ngo_controller===true?
                  <FormItem name="can_create_otherngos" label="can_create_otherngos" >
                  <Switch
                    checked={this.state.can_create_otherngos}
                    onChange={val=>{this.setState({can_create_otherngos:!this.state.can_create_otherngos})}}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    />
                  
              </FormItem>
                  :
                  null
                }
   

                      {
                  this.state.companyprofile.ngo_controller===true?
                  <FormItem name="can_edit_delete_otherngos" label="can_edit_delete_otherngos" >
                  <Switch
                    checked={this.state.can_edit_delete_otherngos}
                    onChange={val=>{this.setState({can_edit_delete_otherngos:!this.state.can_edit_delete_otherngos})}}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    />
                  
              </FormItem>
                  :
                  null
                }




              {
                  this.state.companyprofile.ngo_controller===true?
                  <FormItem name="can_view_ge_transactions" label="can_view_ge_transactions" >
                  <Switch
                    checked={this.state.can_view_ge_transactions}
                    onChange={val=>{this.setState({can_view_ge_transactions:!this.state.can_view_ge_transactions})}}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    />
                  
              </FormItem>
                  :
                  null
                }




                      {
                  this.state.companyprofile.ngo_controller===true?
                  <FormItem name="can_create_ngotransactions" label="can_create_ngotransactions" >
                  <Switch
                    checked={this.state.can_create_ngotransactions}
                    onChange={val=>{this.setState({can_create_ngotransactions:!this.state.can_create_ngotransactions})}}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    />
                  
              </FormItem>
                  :
                  null
                }


              {
                  this.state.companyprofile.ngo_controller===true?
                  <FormItem name="can_view_ngotransactions" label="can_view_ngotransactions" >
                  <Switch
                    checked={this.state.can_view_ngotransactions}
                    onChange={val=>{this.setState({can_view_ngotransactions:!this.state.can_view_ngotransactions})}}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    />
                  
              </FormItem>
                  :
                  null
                }


                      <FormItem name="can_create_expenses" label="can_create_expenses" >
                          <Switch
                            checked={this.state.can_create_expenses}
                            onChange={val=>{this.setState({can_create_expenses:!this.state.can_create_expenses})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>
                      <FormItem name="can_view_expense" label="can_view_expense" >
                          <Switch
                            checked={this.state.can_view_expense}
                            onChange={val=>{this.setState({can_view_expense:!this.state.can_view_expense})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>
                      <FormItem name="can_edit_delete_expenses" label="can_edit_delete_expenses" >
                          <Switch
                            checked={this.state.can_edit_delete_expenses}
                            onChange={val=>{this.setState({can_edit_delete_expenses:!this.state.can_edit_delete_expenses})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>
                      <FormItem name="can_view_generaljournal" label="can_view_generaljournal" >
                          <Switch
                            checked={this.state.can_view_generaljournal}
                            onChange={val=>{this.setState({can_view_generaljournal:!this.state.can_view_generaljournal})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>
                      <FormItem name="can_view_ledger" label="can_view_ledger" >
                          <Switch
                            checked={this.state.can_view_ledger}
                            onChange={val=>{this.setState({can_view_ledger:!this.state.can_view_ledger})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>
                      <FormItem name="can_view_trial_balance" label="can_view_trial_balance" >
                          <Switch
                            checked={this.state.can_view_trial_balance}
                            onChange={val=>{this.setState({can_view_trial_balance:!this.state.can_view_trial_balance})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>

                      <FormItem name="can_create_schoolfees_budget" label="can_create_schoolfees_budget" >
                          <Switch
                            checked={this.state.can_create_schoolfees_budget}
                            onChange={val=>{this.setState({can_create_schoolfees_budget:!this.state.can_create_schoolfees_budget})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>


                      <FormItem name="can_view_schoolfees_budget" label="can_view_schoolfees_budget" >
                          <Switch
                            checked={this.state.can_view_schoolfees_budget}
                            onChange={val=>{this.setState({can_view_schoolfees_budget:!this.state.can_view_schoolfees_budget})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                      </FormItem>


                      <FormItem name="can_edit_delete_schoolfees_budget" label="can_edit_delete_schoolfees_budget" >
                          <Switch
                            checked={this.state.can_edit_delete_schoolfees_budget}
                            onChange={val=>{this.setState({can_edit_delete_schoolfees_budget:!this.state.can_edit_delete_schoolfees_budget})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>

                      <FormItem name="can_create_childdevelopmentreport" label="can_create_childdevelopmentreport" >
                          <Switch
                            checked={this.state.can_create_childdevelopmentreport}
                            onChange={val=>{this.setState({can_create_childdevelopmentreport:!this.state.can_create_childdevelopmentreport})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>

                      <FormItem name="can_view_childdevelopmentreport" label="can_view_childdevelopmentreport" >
                          <Switch
                            checked={this.state.can_view_childdevelopmentreport}
                            onChange={val=>{this.setState({can_view_childdevelopmentreport:!this.state.can_view_childdevelopmentreport})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>

                      
                      <FormItem name="prepare_payouts" label="prepare_payouts" >
                          <Switch
                            checked={this.state.prepare_payouts}
                            onChange={val=>{this.setState({prepare_payouts:!this.state.prepare_payouts})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>

                      <FormItem name="submit_payouts" label="submit_payouts" >
                          <Switch
                            checked={this.state.submit_payouts}
                            onChange={val=>{this.setState({submit_payouts:!this.state.submit_payouts})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>

                      <FormItem name="receive_ge_payments" label="receive_ge_payments" >
                          <Switch
                            checked={this.state.receive_ge_payments}
                            onChange={val=>{this.setState({receive_ge_payments:!this.state.receive_ge_payments})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>

                      <FormItem name="view_staff" label="view_staff" >
                          <Switch
                            checked={this.state.view_staff}
                            onChange={val=>{this.setState({view_staff:!this.state.view_staff})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>


                      <FormItem name="create_staff" label="create_staff" >
                          <Switch
                            checked={this.state.create_staff}
                            onChange={val=>{this.setState({create_staff:!this.state.create_staff})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                          
                      </FormItem>

                      <FormItem name="view_school_fees_budget" label="view_school_fees_budget" >
                          <Switch
                            checked={this.state.view_school_fees_budget}
                            onChange={val=>{this.setState({view_school_fees_budget:!this.state.view_school_fees_budget})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                      </FormItem>


                      <FormItem>
                        <Button htmlType="submit" type="primary">Update</Button>
                      </FormItem>
                      
                    </Form>

              </Drawer>


              <Modal              
                visible={this.state.showpasswordupdatemodal}
                title="Password change Form"
                width={600}
                onCancel={this.closepasswordupdatemodal}

                footer={[
                  <Button key="back" onClick={this.closepasswordupdatemodal}>
                    Cancel
                  </Button>
                  ]}
                  >
              <Form
              onFinish={this.handlePasswordChange}        
              name="passwordreset"
              className="login-form">
        
              <Form.Item
                  name="new_password1"
                  label="New Password one"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password one"
                  />


                </Form.Item>

          
                <Form.Item
                  name="new_password2"
                  label="Confirm New Password"
                  dependencies={['new_password1']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('new_password1') === value) {
                          return Promise.resolve();
                        }
          
                        return Promise.reject('The two passwords that you entered do not match!');
                      },
                    }),
                  ]}
                >
                  <Input.Password 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Confirm" />

                </Form.Item>

              <Form.Item >
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Confirm Change
                  </Button>
              </Form.Item>
            </Form>
                    
            </Modal>

            <Modal              
                visible={this.state.show_accountcreate_formvisible}
                title="Account create Form"
                width={600}
                onCancel={()=>{this.setState({show_accountcreate_formvisible:false})}}

                footer={[
                  <Button key="back" onClick={()=>{this.setState({show_accountcreate_formvisible:false})}}>
                    Cancel
                  </Button>
                  ]}
                  >
                    <StaffUserSignUpForm />

            </Modal>

        </div>
    )
    }
   
  }
}

export default AccountList; 
