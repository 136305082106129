import React from 'react';
import { Form, Input,message,Button,Select,DatePicker,InputNumber,Modal,Spin,Result } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import {
  Card,
  Col,
  Row,
} from 'reactstrap';
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var sacco= ''
var username=''
var token= ''
var bizuserid= ''
var ngoid= ''


class SchoolTermForm extends React.Component {

  state = {
    date_from:moment().format(dateFormat).toString(),  
    date_to:moment().format(dateFormat).toString(),  
    companyprofile:{},
    userprofile:{},
    term_name:'',
    term_description:''
  }
  

  componentDidMount(){
    if(localStorage.getItem("ngoid")){
       ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       ngoid= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        bizuserid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })



}

  //submit button pressed
  handleFormSubmit=(event) =>{

      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('date_to', this.state.date_to);
      form_data.append('date_from', this.state.date_from);
      form_data.append('term_name', this.state.term_name);
      form_data.append('term_description', this.state.term_description);
      form_data.append('Ngo', ngoid);
        
      axios.post(serverconfig.backendserverurl+'/api/schoolterms/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => {
      this.setState({datarequested:false})
      message.info('Successfully added school term')
      this.setState({datasubmittedsuccessfully:true})
    }        
  
    )
    .catch(error => console.log(error))
    
  }

 
  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Created School term "
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{


     return (
        <div>
          <Form 
          name="School term form"
          onFinish={(event) => this.handleFormSubmit(event)}
          >
    
        <FormItem label="Term name"
            name="term_name"
            rules={[
              {
                required: true,
                message: 'Please enter term name',
              },
            ]}          
          
          
          >
            <Input  placeholder="Term name." value={this.state.term_name} onChange={(val)=>{this.setState({term_name:val.target.value})}} />
          </FormItem>


          <FormItem label="Description"
              name="term_description"
              rules={[
                {
                  required: true,
                  message: 'Please enter description',
                },
              ]}          
          >

            <TextArea
                  placeholder="Description."
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  value={this.state.term_description}
                  onChange={(val)=>{this.setState({term_description:val.target.value})}}
                />
          </FormItem>


          
          <FormItem label="Date"
            name='date'
            rules={[
              {
                required: true,
                message: 'Please Select date',
              },
            ]}
          >
              <RangePicker defaultValue={moment()} 
              onChange={(date, dateString) =>{
                this.setState({ date_from: dateString[0]});
                this.setState({ date_to: dateString[1]});
              } } format={dateFormat} />
          </FormItem>
          

          <FormItem>
            <Button type="primary" htmlType="submit" >Create</Button>
          </FormItem>
       </Form>
    
        </div>
        );

      }


    }
  }

}


export default SchoolTermForm;



