import React from 'react';
import { Form, Input, Button,Select,Spin,Card } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''

class DataSyncForm extends React.Component {

  state = {
    assetcategories:[],
    assetname:'' ,  
    assetcategory:'',
    netbookvalue:0,
    assetphoto:null,
    details:'',
    datarequested:true,
  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/assetcategories/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          assetcategories:res.data
        })
        this.setState({datarequested:false})

    })
}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('sacco', sacco);
    
    axios.post(serverconfig.backendserverurl+'/customqueries/datasyncronization', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
    console.log(res.data)
    this.setState({datarequested:false})
     window.location.reload(false)
  }
  )
  .catch(error => console.log(error))
  }


    //submit button pressed
    importdatafromoldsystem=(event) =>{
      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('sacco', sacco);
      
      axios.post(serverconfig.backendserverurl+'/customqueries/importdatafromoldsysystem', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => {
      console.log(res.data)
      this.setState({datarequested:false})
      window.location.reload(false)
    }
    )
    .catch(error => console.log(error))
    }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>

          <Row >
                  <Col xs="12" sm="6" lg="6">
                  <Card>
                  <h3>Cloud Sync Now</h3>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)} 
                    >
                      
                      <FormItem>
                        <Button  type="primary" htmlType="submit">Sync Now</Button>
                      </FormItem>
                    </Form>


                  </Card>

                  </Col>

                  <Col xs="12" sm="6" lg="6">
                  <Card>
                  <h3>Old system data import</h3>
                    <Form
                      onFinish={(event) => this.importdatafromoldsystem(event)} 
                    >
                      
                      <FormItem>
                        <Button  type="primary" htmlType="submit">Import Now</Button>
                      </FormItem>
                    </Form>

                </Card>

                </Col>
            </Row>


        </div>
      );


    }


  }

}


export default DataSyncForm;



