import React from 'react';
import axios from 'axios';
import { Card, Button,Avatar,Form, Input,Select,DatePicker,message,Spin,Image,Tooltip} from 'antd';
import { UserOutlined,LoadingOutlined,EditOutlined } from '@ant-design/icons';
import { Drawer,Divider } from 'antd';
import StaffUserSignUpForm from '../components/StaffUserSignUp'
import * as serverconfig from '../serverconn'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import * as primarycolor from '../primarycolor'
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';


var token= ''
var sacco= ''
var ngoid= ''


class StaffDetail extends React.Component{

    state={
        staff: {},
        visible: false,
        childrenDrawer: false,
        //////form data here
        staffroles:[],
        idtypes:[],
        sur_name:'' ,  
        middle_name:'',
        given_name:'',
        nationality:'',
        ID_type:'',
        ID_number:'',
        date_Of_Birth:'',
        Email_Address:'',
        P_O_Box:'',
        Area_Of_Residence:'',
        District:'',
        RegisteredPhoneNumber1:'',
        RegisteredPhoneNumber2:'',
        next_of_kin:'',
        Next_Of_Kin_Physical_Address:'',
        relationship:'',
        Photo:null,
        Date_Of_Joining:'',
        staff_role:'',
        date_of_appointment:'',
        status:'',
        staff_number:'',
        reference_one:'',
        Referee1PhoneNumber:'',
        datarequested:true,
        gender:'',

        editform_visible:false,

        photofile:'',
        photofile:null,
        id_photofile:null,
        contract_file:false

    }

    showDrawer = () => {
        this.setState({
          visible: true,
        });
      };
    
      onClose = () => {
        this.setState({
          visible: false,
        });
      };
    
      showChildrenDrawer = () => {
        this.setState({
          childrenDrawer: true,
        });
      };
    
      onChildrenDrawerClose = () => {
        this.setState({
          childrenDrawer: false,
        });
      };



      handlesur_nameChange= (e) => this.setState({ sur_name: e.target.value});
      handlemiddle_nameChange= (e) => this.setState({ middle_name: e.target.value});
      handlegiven_nameChange= (e) => this.setState({ given_name: e.target.value});
      handlenationalityChange= (e) => this.setState({ nationality: e.target.value});
      handleID_typeChange= (e) => this.setState({ ID_type: e});
    
      handleID_numberChange= (e) => this.setState({ ID_number: e.target.value});
      handledate_Of_BirthChange= (date, dateString) => this.setState({ date_Of_Birth: dateString});
    
      handleEmail_AddressChange= (e) => this.setState({ Email_Address: e.target.value});
      handleP_O_BoxChange= (e) => this.setState({ P_O_Box: e.target.value});
      handleArea_Of_ResidenceChange= (e) => this.setState({ Area_Of_Residence: e.target.value});
      handleDistrictChange= (e) => this.setState({ District: e.target.value});
      handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});
    
      handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});
      handlenext_of_kinChange= (e) => this.setState({ next_of_kin: e.target.value});
      handleNext_Of_Kin_Physical_AddressChange= (e) => this.setState({ Next_Of_Kin_Physical_Address: e.target.value});
      handlerelationshipChange= (e) => this.setState({ relationship: e.target.value});
      handlePhotoChange= async(e) =>{
        if(e.target.files[0]){
          this.setState({
            photofile: URL.createObjectURL(e.target.files[0])
          })
        }
    
    
        const file = e.target.files[0];
        //send to resizer
        const image = await this.resizeFile(file);
        const newFile = this.dataURIToBlob(image);
        this.setState({ Photo: newFile});

      }
      
      
      handleid_photoChange= async(e) =>{
        this.setState({ id_photo: e.target.files[0]});
        if(e.target.files[0]){
          this.setState({
            id_photofile: URL.createObjectURL(e.target.files[0])
          })
        }
    
        const file = e.target.files[0];
        //send to resizer
        const image = await this.resizeFile(file);
        const newFile = this.dataURIToBlob(image);
        this.setState({ id_photo: newFile});
        console.log(newFile);
    
      }


      handleDate_Of_JoiningChange= (date, dateString) => this.setState({ Date_Of_Joining: dateString});
      handlestaff_roleChange= (e) => this.setState({ staff_role: e});
      handledate_of_appointmentChange= (date, dateString) => this.setState({ date_of_appointment: dateString});
    
      handlestatusChange= (e) => this.setState({ status: e});
      handlestaff_numberChange= (e) => this.setState({ staff_number: e.target.value});
      handlereference_oneChange= (e) => this.setState({ reference_one: e.target.value});
      handleReferee1PhoneNumberChange= (e) => this.setState({ Referee1PhoneNumber: e});
      handlegenderChange= (e) => this.setState({ gender: e});

    //submit button pressed
    handleFormSubmit=() =>{
      this.setState({datarequested:true})
      const staffID=this.props.match.params.staffID;

      let form_data = new FormData();
      form_data.append('sur_name', this.state.sur_name);
      form_data.append('middle_name', this.state.middle_name);
      form_data.append('given_name', this.state.given_name);
      form_data.append('nationality', this.state.nationality);
      form_data.append('ID_type', this.state.ID_type);
      form_data.append('ID_number', this.state.ID_number);
      form_data.append('date_Of_Birth', this.state.date_Of_Birth);
      form_data.append('gender', this.state.gender);

      form_data.append('Email_Address', this.state.Email_Address);

      form_data.append('P_O_Box', this.state.P_O_Box);
      form_data.append('Area_Of_Residence', this.state.Area_Of_Residence);
      form_data.append('District', this.state.District);
      form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
      form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);

      form_data.append('next_of_kin', this.state.next_of_kin);
      form_data.append('Next_Of_Kin_Physical_Address', this.state.Next_Of_Kin_Physical_Address);
      form_data.append('relationship', this.state.relationship);
      form_data.append('Date_Of_Joining', this.state.Date_Of_Joining);

      form_data.append('staff_role', this.state.staff_role);
      form_data.append('date_of_appointment', this.state.date_of_appointment);
      form_data.append('status', this.state.status);
      form_data.append('staff_number', this.state.staff_number);
      form_data.append('reference_one', this.state.reference_one);
      form_data.append('Referee1PhoneNumber', this.state.Referee1PhoneNumber);


      this.state.Photo==null?
      console.log("No profile file")
      :
      form_data.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");


      this.state.id_photo==null?
      console.log("No id_photo file")
      :
      form_data.append('id_photo', this.state.id_photo, this.state.id_photo?this.state.id_photo.name:"");

      this.state.contract_file==null?
      console.log("No contract_file file")
      :
      form_data.append('contract_file', this.state.contract_file, this.state.contract_file?this.state.contract_file.name:"");


      axios.put(`${serverconfig.backendserverurl}/api/staffs/${staffID}/`,form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>this.setState({datarequested:false}), window.location.reload(false),message.info('Successfully updated'))
    .catch(error => console.log(error))
    }



    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      }else{
         sacco= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      if(localStorage.getItem("ngoid")){
        ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      }else{
        ngoid= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }


      axios.get(serverconfig.backendserverurl+`/api/staffroles/?Ngo=${ngoid}`)
      .then(res => {
          this.setState({
            staffroles:res.data
          })
      })
  
      axios.get(serverconfig.backendserverurl+`/api/idtypes/?Ngo=${ngoid}`)
      .then(res => {
          this.setState({
            idtypes:res.data
          })
      })


        const staffID=this.props.match.params.staffID;
        axios.get(`${serverconfig.backendserverurl}/api/staffs/${staffID}`)
        .then(res => {  
            this.setState({
                staff: res.data
            })
            this.setState({sur_name:res.data.sur_name});
            this.setState({given_name:res.data.given_name});
            this.setState({middle_name:res.data.middle_name});
            this.setState({nationality:res.data.nationality});
            this.setState({ID_type:res.data.ID_type})
            this.setState({ID_number:res.data.ID_number})
            this.setState({date_Of_Birth:res.data.date_Of_Birth})
            this.setState({Email_Address:res.data.Email_Address})
            this.setState({P_O_Box:res.data.P_O_Box})
            this.setState({Area_Of_Residence:res.data.Area_Of_Residence})
            this.setState({District:res.data.District})
            this.setState({RegisteredPhoneNumber1:res.data.RegisteredPhoneNumber1})
            this.setState({RegisteredPhoneNumber2:res.data.RegisteredPhoneNumber2})
            this.setState({next_of_kin:res.data.next_of_kin})
            this.setState({Next_Of_Kin_Physical_Address:res.data.Next_Of_Kin_Physical_Address})
            this.setState({relationship:res.data.relationship})
            this.setState({Date_Of_Joining:res.data.Date_Of_Joining})
            this.setState({staff_role:res.data.staff_role})
            this.setState({date_of_appointment:res.data.date_of_appointment})
            this.setState({status:res.data.status})
            this.setState({staff_number:res.data.staff_number})
            this.setState({reference_one:res.data.reference_one})
            this.setState({Referee1PhoneNumber:res.data.Referee1PhoneNumber})
            this.setState({gender:res.data.gender})

            this.setState({datarequested:false})

          })
    }


//imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};



    render(){

      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{
        return(
          <div>
              <div style={{display:'flex',flexDirection:'row'}}>
              <h2 style={{display:'flex',margin:4}}> {this.state.staff.full_Name} | {this.state.staff.RegisteredPhoneNumber1}</h2>
              
              <Tooltip title={"Click to edit staff information"}>
              <EditOutlined style={{color:primarycolor.primarycolor,fontSize: '30px',}} onClick={()=>{this.setState({editform_visible:!this.state.editform_visible})}} />
              </Tooltip>
              </div>              
              
              <div style={{display:'flex',overflowX:'auto'}}>
              <reactstrp.Table bordered >
                  <thead>
                  <tr>
                  <th>
                  <Image
                  width={100}
                  height={100}
                  src={this.state.staff.Photo}    
                  />
                  <br></br>
                  <br></br>

                  <Image
                  width={100}
                  height={100}
                  src={this.state.staff.id_photo}    
                  />  

                    
                    </th>
                    <th>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Nationality: {this.state.staff.nationality}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Gender: {this.state.staff.gender}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>ID Type: {this.state.staff.idtypename}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>ID Number: {this.state.staff.ID_number}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Date Of Birth: {this.state.staff.date_Of_Birth}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Email: {this.state.staff.Email_Address}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Box Number: {this.state.staff.P_O_Box}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Residence: {this.state.staff.Area_Of_Residence}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>District: {this.state.staff.District}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>System User?: {this.state.staff.sytem_user}</p>
                    </th>
                    <th>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Tel one: {this.state.staff.RegisteredPhoneNumber1}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Tel two: {this.state.staff.RegisteredPhoneNumber2}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Next of kin: {this.state.staff.next_of_kin}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Next of kin(Address): {this.state.staff.Next_Of_Kin_Physical_Address}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Next of kin(Relationship): {this.state.staff.relationship}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Date of Joining: {this.state.staff.Date_Of_Joining}</p>
                    </th>
                    <th>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Staff Role: {this.state.staff.staffrolename}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Date of Appointment: {this.state.staff.date_of_appointment}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Staff status: {this.state.staff.status}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Satff Number: {this.state.staff.staff_number}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Reference Personel: {this.state.staff.reference_one}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Ref Personel Contact: {this.state.staff.Referee1PhoneNumber}</p>

                    </th>
                  </tr>
                </thead>
              </reactstrp.Table>   
              </div>
              <br></br>
              
              {
                this.state.editform_visible===true?
              <Card 
                      style={{padding:10}}
                      hoverable>

                   <h4>Staff Update</h4>
                  <Form>
                  <FormItem label="Sur Name">
                    <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={this.handlesur_nameChange} />
                  </FormItem>

                  <FormItem label="Middle Name">
                    <Input  placeholder="Put a name here." value={this.state.middle_name} onChange={this.handlemiddle_nameChange} />
                  </FormItem>

                  <FormItem label="Given Name">
                    <Input  placeholder="Put a name here." value={this.state.given_name} onChange={this.handlegiven_nameChange} />
                  </FormItem>

                  <FormItem label="Gender">
                  <Select placeholder="Gender" style={{ width: 120 }} value={this.state.gender} onChange={this.handlegenderChange} >
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                  </Select>
                 </FormItem>

                  <FormItem label="Nationality">
                    <Input  placeholder="Nationality." value={this.state.nationality} onChange={this.handlenationalityChange} />
                  </FormItem>


                  <FormItem label="ID Type">
                      <Select placeholder="Id Type" style={{ width: 120 }} value={this.state.ID_type} onChange={this.handleID_typeChange} >
                        {this.state.idtypes.map(
                          (type)=>(
                            <Option value={type.id}>{type.id_type_name}</Option>
                          ))}
                      </Select>
                  </FormItem>

                  <FormItem label="ID Number">
                    <Input  placeholder="ID Number." value={this.state.ID_number} onChange={this.handleID_numberChange} />
                  </FormItem>

                  <FormItem label={"Date of Birth ("+this.state.date_Of_Birth+" )"}>
                        <DatePicker  onChange={this.handledate_Of_BirthChange} format={dateFormat} />
                  </FormItem>


                  <FormItem label="Email Address">
                    <Input placeholder="Email Address." value={this.state.Email_Address} onChange={this.handleEmail_AddressChange} />
                  </FormItem>

                  <FormItem label="Box Number">
                    <Input placeholder="box number." value={this.state.P_O_Box} onChange={this.handleP_O_BoxChange} />
                  </FormItem>

                  <FormItem label="Area of Residence">
                    <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={this.handleArea_Of_ResidenceChange} />
                  </FormItem>

                  <FormItem label="District">
                    <Input placeholder="District." value={this.state.District} onChange={this.handleDistrictChange} />
                  </FormItem>

                  <FormItem label="Phone One">
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber1} onChange={this.handleRegisteredPhoneNumber1Change}/>
                  </FormItem> 

                  <FormItem label="Phone Two">
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber2} onChange={this.handleRegisteredPhoneNumber2Change}/>
                  </FormItem> 


                  <FormItem label="Next of kin">
                    <Input placeholder="Next of kin." value={this.state.next_of_kin} onChange={this.handlenext_of_kinChange} />
                  </FormItem>

                  <FormItem label="Next of kin Address">
                    <Input placeholder="Next of Kin Address." value={this.state.Next_Of_Kin_Physical_Address} onChange={this.handleNext_Of_Kin_Physical_AddressChange} />
                  </FormItem>

                  <FormItem label="Relationship">
                    <Input placeholder="Relationship." value={this.state.relationship} onChange={this.handlerelationshipChange} />
                  </FormItem>

                  <div style={{display:'flex',flexDirection:'row'}}>

                  <FormItem label="Staff Photo">
                        <Input type="file" accept="image/png, image/jpeg" placeholder="Staff Photo"  onChange={this.handlePhotoChange}/>
                  </FormItem>
                  &nbsp;
                  {this.state.Photo?
                    <Image
                    width={50}
                    height={50}
                    src={this.state.photofile}    
                    />
                    :
                    null
                    } 
                  </div>

                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Staff Id photo" style={{marginRight:3}}>
                      <Input type="file" accept="image/png, image/jpeg" placeholder="Staff Id photo"  onChange={this.handleid_photoChange}/>
                  </FormItem> 
                  {this.state.id_photo?
                    <Image
                    width={50}
                    height={50}
                    src={this.state.id_photofile}    
                    />
                    :
                    null
                    }
                  </div>

                  <FormItem label={"Date of Joining ("+this.state.Date_Of_Joining+" )"}>
                        <DatePicker  onChange={this.handleDate_Of_JoiningChange} format={dateFormat}/>
                  </FormItem>


                  <FormItem label="Staff Role">
                      <Select placeholder="Staff Role" style={{ width: 120 }} value={this.state.staff_role} onChange={this.handlestaff_roleChange} >
                        {this.state.staffroles.map(
                          (stf)=>(
                            <Option value={stf.id}>{stf.role_name}</Option>
                          ))}
                      </Select>
                  </FormItem>
                  

                  <FormItem label={"Date of Appointment ("+this.state.date_of_appointment+" )"}>
                        <DatePicker onChange={this.handledate_of_appointmentChange} format={dateFormat}/>
                  </FormItem>
                  

                  <FormItem label="Staff Status">
                      <Select placeholder="Status" style={{ width: 120 }} value={this.state.status} onChange={this.handlestatusChange} >
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">Inactive</Option>
                      </Select>
                  </FormItem>


                  <FormItem label="Staff Number">
                    <Input placeholder="Staff Number." value={this.state.staff_number} onChange={this.handlestaff_numberChange} />
                  </FormItem>

                  <FormItem label="Job Reference Personel">
                    <Input placeholder="Reference Name." value={this.state.reference_one} onChange={this.handlereference_oneChange} />
                  </FormItem>

                  <FormItem label="Reference Phone No.">
                    <PhoneInput
                          countrySelectProps={{ unicodeFlags: true }}
                          defaultCountry="UG"
                          placeholder="Enter Refree Tel"
                          value={this.state.Referee1PhoneNumber} onChange={this.handleReferee1PhoneNumberChange}/>
                  </FormItem>

                  <FormItem label="Contract attachment">
                  <Input type="file" accept=".pdf" placeholder="Contract attachment" 
                   onChange={(e)=>{
                    var kilbyes=e.target.files[0].size/1024
                    if (kilbyes>Number(1024)){
                      message.error("File is greater than "+"1024"+" Kbs or 1 mb .It will not be uploaded.")
                    }else{
                      this.setState({contract_file:e.target.files[0]})
                    }
                    
                  }}/>
                   </FormItem>


                  <FormItem>
                    <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                  </FormItem>
                </Form>

              </Card>
              :
              null
              }
       

          </div>
      )

      }

      
    }
}

export default StaffDetail; 