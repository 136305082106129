import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,MinusCircleFilled,ToolOutlined,DollarCircleFilled, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined, AccountBookOutlined,CalculatorFilled,SwapOutlined,GroupOutlined,AccountBookFilled, DollarCircleOutlined,EditFilled,MessageFilled,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled,CloudSyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'
import { MdFileUpload, MdShoppingCart } from 'react-icons/md';

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import  GuardianForm from '../components/GuardianForm'
import  ChildForm from '../components/ChildForm'
import  ChildDocumentUploadForm from '../components/ChildDocumentUploadForm'
import  SchoolFeesForm from '../components/SchoolFeesForm'
import  ChildFeesPaymentForm from '../components/ChildFeesPaymentForm'
import  ChildDevelopmentForm from '../components/ChildDevelopmentForm'

import  LoanCalculator from '../components/LoanCalculator'
import  AccountReserveTransferView from '../containers/AccountReserveTransferView'
import * as primarycolor from '../primarycolor'

var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

var bizuserid= ''
var ngoid= ''
var token= ''


class QuickMenu extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    companyprofile:{},
    datarequested:true,
    totalmemberaccounts:0,
    totalfemaleaccounts:0,
    totalmaleaccounts:0,
    totalloansthismonth:0,
    totalmembersavings:0,
    guardian_form_visible:false,
    transactformvisible:false,
    calculatorformvisible:false,
    members:[],
    loansformvisible:false,
    monthlyloanissuances:0,
    monthlyloanapplications:0,
    monthlyloanassessments:0,
    monthlyloanapprovals:0,
    dashboardlocked:false,
    utilitiesmodalvisible:false,
    userprofile:{},
    userrights:{},
    nonworkingday:{},
    reservetranfermodalvisible:false,
    child_form_visible:false,
    child_upload_form_visible:false,
    child_fees_form_visible:false,
    feespayment_form_visible:false,
    development_form_visible:false


  };

  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){
    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }



   let form_data = new FormData();
   form_data.append('userid', bizuserid);
   
   //get the user profile here
   axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`)
   .then(res => {
       this.setState({
        userprofile:res.data
       })
   })


    axios.get(serverconfig.backendserverurl+`/api/ngos/${ngoid}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
        this.setState({datarequested:false})
    })

  }


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ?(
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};


render() {

        return(
          <div className="animated fadeIn">  
          <h3 style={{fontStyle:'oblique',fontWeight:'bolder',color:primarycolor.primarycolor}}>{this.state.companyprofile.sacco_name} QUICK MENU</h3>
    
           <Row>

           {
            this.state.userrights.can_create_guardians===true?
            <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
            <Tooltip title="Click to create guardian" placement="top">
            <Card
                  onClick={()=>{this.setState({guardian_form_visible:true})}}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                   <PlusCircleFilled style={{color:'#154360',fontSize: '50px'}}/>
                  <h4 style={{fontWeight:'bold'}}>NEW GUARDIAN</h4>
                      </div>
                </Card>
              </Tooltip>
             
            </Col>
            :
            null}



{
            this.state.userrights.can_create_children===true?
            <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
            <Tooltip title="Click to add new child" placement="top">
              <Card
                  onClick={()=>{this.setState({child_form_visible:true})}}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignSelf:'center',
                    alignItems:'center' }}
                >
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                      <PlusCircleFilled style={{color:'#154360',fontSize: '50px'}}/>
                      <h4 style={{fontWeight:'bold'}}>NEW CHILD</h4>
                     </div>   
    
                </Card>
                </Tooltip>
              </Col> 
                          :
                          null}
    
    {
      this.state.userrights.can_create_children===true?
            <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
            <Tooltip title="Click to upload child document" placement="top">
              <Card
                  onClick={()=>{this.setState({child_upload_form_visible:true})}}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignSelf:'center',
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                      <MdFileUpload style={{color:'#154360',fontSize: '50px'}}/>
                      <h4 style={{fontWeight:'bold'}}>NEW CHILD DOCUMENT</h4>
                     </div>   
    
                </Card>
                </Tooltip>
              </Col> 
              :
              null}


          {
           this.state.userrights.can_create_schoolfees_budget===true?
            <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
            <Tooltip title="Click to add child school fees budget" placement="top">
              <Card
                  onClick={()=>{this.setState({child_fees_form_visible:true})}}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignSelf:'center',
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                      <PlusCircleFilled style={{color:'#154360',fontSize: '50px'}}/>
                      <h4 style={{fontWeight:'bold'}}>NEW CHILD FEES</h4>
                     </div>   
    
                </Card>
                </Tooltip>
              </Col> 
              :
              null}


{
           this.state.userrights.can_create_schoolfees_budget===true?
            <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
            <Tooltip title="Click to add fees payment" placement="top">
              <Card
                  onClick={()=>{this.setState({feespayment_form_visible:true})}}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignSelf:'center',
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                      <PlusCircleFilled style={{color:'#154360',fontSize: '50px'}}/>
                      <h4 style={{fontWeight:'bold'}}>FEES PAYMENT</h4>
                     </div>   
    
                </Card>
                </Tooltip>
              </Col> 
              :
              null}


            {
             this.state.userrights.can_create_childdevelopmentreport===true?
            <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
            <Tooltip title="Click to add child development report" placement="top">
              <Card
                  onClick={()=>{this.setState({development_form_visible:true})}}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignSelf:'center',
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                      <PlusCircleFilled style={{color:'#154360',fontSize: '50px'}}/>
                      <h4 style={{fontWeight:'bold'}}>CHILD DEVELOPMENT REPORT</h4>
                     </div>   
    
                </Card>
                </Tooltip>
              </Col> 
              :
              null
              }



            </Row>
    
    
            <Modal              
            visible={this.state.guardian_form_visible}
            title="Guardian Creation"
            onCancel={()=>{this.setState({guardian_form_visible:false})}}
    
            footer={[
              <Button key="back" onClick={()=>{this.setState({guardian_form_visible:false})}}>
                Cancel
              </Button>
              ]}
              >
              <GuardianForm />
                
            </Modal>  

            <Modal              
            visible={this.state.child_form_visible}
            title="Child Creation"
            onCancel={()=>{this.setState({child_form_visible:false})}}
    
            footer={[
              <Button key="back" onClick={()=>{this.setState({child_form_visible:false})}}>
                Cancel
              </Button>
              ]}
              >
              <ChildForm />
                
            </Modal>

            <Modal              
            visible={this.state.child_upload_form_visible}
            title="Child Document upload"
            onCancel={()=>{this.setState({child_upload_form_visible:false})}}
    
            footer={[
              <Button key="back" onClick={()=>{this.setState({child_upload_form_visible:false})}}>
                Cancel
              </Button>
              ]}
              >
              <ChildDocumentUploadForm />
                
            </Modal>

            <Modal              
            visible={this.state.child_fees_form_visible}
            title="Fees budget upload form"
            onCancel={()=>{this.setState({child_fees_form_visible:false})}}
    
            footer={[
              <Button key="back" onClick={()=>{this.setState({child_fees_form_visible:false})}}>
                Cancel
              </Button>
              ]}
              >
              <SchoolFeesForm />
                
            </Modal>

            <Modal              
            visible={this.state.feespayment_form_visible}
            title="Child fees payment"
            onCancel={()=>{this.setState({feespayment_form_visible:false})}}
    
            footer={[
              <Button key="back" onClick={()=>{this.setState({feespayment_form_visible:false})}}>
                Cancel
              </Button>
              ]}
              >
              <ChildFeesPaymentForm />
                
            </Modal>


            <Modal              
            visible={this.state.development_form_visible}
            title="Child Development form"
            onCancel={()=>{this.setState({development_form_visible:false})}}
    
            footer={[
              <Button key="back" onClick={()=>{this.setState({development_form_visible:false})}}>
                Cancel
              </Button>
              ]}
              >
              <ChildDevelopmentForm />
                
            </Modal>

          </div>
        )

    }
  
}

export default QuickMenu; 
