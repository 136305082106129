import React from 'react';
import { Form, Input, Button,Select,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var ngoid= ''

class IncomeCategoryForm extends React.Component {

  state = {
    categoryname:'' ,  
    description:'',
    datarequested:false,

  }

  componentDidMount(){
    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('category_name', this.state.categoryname);
    form_data.append('description', this.state.description);
    form_data.append('Ngo', ngoid);

      axios.post(serverconfig.backendserverurl+'/api/incomecategories/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>  this.setState({datarequested:false})
    ,window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  handlecategorynameChange= (e) => this.setState({ categoryname: e.target.value});
  handledescriptionChange= (e) => this.setState({ description: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
          <Form
            onFinish={(event) => this.handleFormSubmit(event)}
          >
            <FormItem label="Category Name"
              name="categoryname"
              rules={[
                {
                  required: true,
                  message: 'Please input category',
                },
              ]}           
            >
              <Input name="categoryname"  placeholder="Put a name here." value={this.state.categoryname} onChange={this.handlecategorynameChange} />
            </FormItem>
            <FormItem label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Please input desc',
                },
              ]}           
            >
              <Input name="description"  placeholder="Description here" value={this.state.description} onChange={this.handledescriptionChange} />
            </FormItem>
          
            <FormItem>
              <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
            </FormItem>
          </Form>
        </div>
      );

    }
    
  }

}

export default IncomeCategoryForm;



