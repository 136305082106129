import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image,message } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined,WarningFilled,CheckCircleFilled } from '@ant-design/icons';
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var ngoid= ''
var bizuserid= ''

class StaffForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    staffroles:[],
    idtypes:[],
    sur_name:'' ,  
    middle_name:'',
    given_name:'',
    nationality:'',
    ID_type:'',
    ID_number:'',
    date_Of_Birth:'',
    Email_Address:'',
    P_O_Box:'',
    Area_Of_Residence:'',
    District:'',
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    next_of_kin:'',
    Next_Of_Kin_Physical_Address:'',
    relationship:'',
    Photo:null,
    Date_Of_Joining:'',
    staff_role:'',
    date_of_appointment:'',
    status:'',
    staff_number:'',
    reference_one:'',
    Referee1PhoneNumber:'',
    datarequested:true,
    gender:'',

    id_expirydate:'',
    ugdistricts:[],
    id_valid:false,
    photofile:'',
    photofile:null,
    id_photofile:null,

    age_valid:false,
    agetypeobject:{},
    idtypeobject:{},

    age_types:[],
    age_type:'',
    contract_file:false

  }

  componentDidMount(){
    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)

    }else{
      ngoid= ''

    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/staffroles/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          staffroles:res.data
        })
    })

    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/ugdistricts/?Ngo=${ngoid}`)
    .then(res => {
      this.setState({
        ugdistricts:res.data
      })
    })

    axios.get(serverconfig.backendserverurl+`/api/idtypes/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          idtypes:res.data
        })

    })


    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/age_types/?Ngo=${ngoid}`)
    .then(res => {
      this.setState({
        age_types:res.data
      })
        this.setState({datarequested:false})
    })
}



//imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};


handle_agetypeChange= (e) =>{

  axios.get(serverconfig.backendserverurl+`/api/age_types/${e}`)
  .then(res => {
      this.setState({
        agetypeobject:res.data
      })
  })


}



  //submit button pressed
  handleFormSubmit=(event) =>{

    if (this.state.RegisteredPhoneNumber1===''){
      message.info("Phone Number missing")
    }else if(this.state.id_valid===false && this.state.membertype==="senior"){
      message.info("ID is invalid")

    }else if(this.state.age_valid===false){
      message.info("Age is invalid")
    }
    else{

      this.setState({datarequested:true})


      let form_data = new FormData();
      form_data.append('sur_name', this.state.sur_name);
      form_data.append('middle_name', this.state.middle_name);
      form_data.append('given_name', this.state.given_name);
      form_data.append('nationality', this.state.nationality);
      form_data.append('ID_type', this.state.ID_type);
      form_data.append('ID_number', this.state.ID_number);
      form_data.append('date_Of_Birth', this.state.date_Of_Birth);
  
      form_data.append('Email_Address', this.state.Email_Address);
      form_data.append('gender', this.state.gender);
  
      form_data.append('P_O_Box', this.state.P_O_Box);
      form_data.append('Area_Of_Residence', this.state.Area_Of_Residence);
      form_data.append('District', this.state.District);
      form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
      form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);
  
      form_data.append('next_of_kin', this.state.next_of_kin);
      form_data.append('Next_Of_Kin_Physical_Address', this.state.Next_Of_Kin_Physical_Address);
      form_data.append('relationship', this.state.relationship);
      form_data.append('Date_Of_Joining', this.state.Date_Of_Joining);
  
      form_data.append('staff_role', this.state.staff_role);
      form_data.append('date_of_appointment', this.state.date_of_appointment);
      form_data.append('status', this.state.status);
      form_data.append('staff_number', this.state.staff_number);
      form_data.append('reference_one', this.state.reference_one);
      form_data.append('Referee1PhoneNumber', this.state.Referee1PhoneNumber);
      form_data.append('Ngo', ngoid);
      
      form_data.append('id_expirydate', this.state.id_expirydate);

      this.state.Photo==null?
      console.log("No profile file")
      :
      form_data.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");
  
      this.state.id_photo==null?
      console.log("No id_photo file")
      :
      form_data.append('id_photo', this.state.id_photo, this.state.id_photo?this.state.id_photo.name:"");
  
      this.state.contract_file==null?
      console.log("No contract_file file")
      :
      form_data.append('contract_file', this.state.contract_file, this.state.contract_file?this.state.contract_file.name:"");


      axios.post(serverconfig.backendserverurl+'/api/staffs/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>    this.setState({datarequested:false})
  ,    window.location.reload(false)
    )
    .catch(error => console.log(error))

    }
 
  }

  handlesur_nameChange= (e) => this.setState({ sur_name: e.target.value});
  handlemiddle_nameChange= (e) => this.setState({ middle_name: e.target.value});
  handlegiven_nameChange= (e) => this.setState({ given_name: e.target.value});
  handlenationalityChange= (e) => this.setState({ nationality: e.target.value});
  handleID_typeChange= (e) =>{
    this.setState({ ID_type: e});
    axios.get(serverconfig.backendserverurl+`/api/idtypes/${e}`)
    .then(res => {
        this.setState({
          idtypeobject:res.data
        })
    })
  } 

  handleID_numberChange= (e) => this.setState({ ID_number: e.target.value});

  handledate_Of_BirthChange= (date, dateString) =>{
    this.setState({ date_Of_Birth: dateString});
    this.checkagevalidity(dateString)
  } 
  handleEmail_AddressChange= (e) => this.setState({ Email_Address: e.target.value});
  handleP_O_BoxChange= (e) => this.setState({ P_O_Box: e.target.value});
  handleArea_Of_ResidenceChange= (e) => this.setState({ Area_Of_Residence: e.target.value});
  handleDistrictChange= (e) => this.setState({ District: e.target.value});
  handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});

  handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});
  handlenext_of_kinChange= (e) => this.setState({ next_of_kin: e.target.value});
  handleNext_Of_Kin_Physical_AddressChange= (e) => this.setState({ Next_Of_Kin_Physical_Address: e.target.value});
  handlerelationshipChange= (e) => this.setState({ relationship: e.target.value});
 
  handlePhotoChange=async (e) =>{

    if(e.target.files[0]){
      this.setState({
        photofile: URL.createObjectURL(e.target.files[0])
      })
    }


    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ Photo: newFile});

  } 

  handleid_photoChange= async(e) =>{
    //this.setState({ id_photo: e.target.files[0]});
    if(e.target.files[0]){
      this.setState({
        id_photofile: URL.createObjectURL(e.target.files[0])
      })
    }

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ id_photo: newFile});
    console.log(newFile);

  }


  handleDate_Of_JoiningChange= (date, dateString) => this.setState({ Date_Of_Joining: dateString});
  handlestaff_roleChange= (e) => this.setState({ staff_role: e});
  handledate_of_appointmentChange= (date, dateString) => this.setState({ date_of_appointment: dateString});

  handlestatusChange= (e) => this.setState({ status: e});
  handlestaff_numberChange= (e) => this.setState({ staff_number: e.target.value});
  handlereference_oneChange= (e) => this.setState({ reference_one: e.target.value});
  handleReferee1PhoneNumberChange= (e) => this.setState({ Referee1PhoneNumber: e});
  handlegenderChange= (e) => this.setState({ gender: e});



  //check agge validity
  checkagevalidity=(val)=>{
    var agelimit_months_cap=Number(this.state.agetypeobject.agelimit_months_cap)

      var dateMomentObjectone = moment(val, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dobobject = dateMomentObjectone.toDate();

      var datetoday=moment(this.state.date, "DD/MM/YYYY")

      var date1 = datetoday.toDate();
      var date2 = dobobject;

      var Difference_In_Time =  date1.getTime()-date2.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      console.log("diff days"+String(Difference_In_Days))

    if(Number(Difference_In_Days)>=Number(agelimit_months_cap)){
      this.setState({age_valid:true})
      console.log("Age is valid")
    }else{
      this.setState({age_valid:false})
      console.log("Age is not valid")
    }
  }

  //check agge validity
  checkidvalidity=(val)=>{
      var idvaliditydays=Number(this.state.idtypeobject.IDvaliditydays)

      var dateMomentObjectone = moment(val, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dobobject = dateMomentObjectone.toDate();

      var datetoday=moment(this.state.date, "DD/MM/YYYY")

      var date1 = datetoday.toDate();
      var date2 = dobobject;

      var Difference_In_Time =  date2.getTime()-date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      console.log("diff days"+String(Difference_In_Days))

    if(Number(Difference_In_Days)>=Number(idvaliditydays)){
      this.setState({id_valid:true})
      console.log("ID is valid")
    }else{
      this.setState({id_valid:false})
      console.log("ID is not valid")
    }
  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
          <Form
              onFinish={(event) => this.handleFormSubmit(event)}
          >

          <FormItem label="Age type set"  
                name="guadian_child_Type"
                rules={[
                  {
                    required: true,
                    message: 'Please select type set',
                  },
                ]}           
            >
                <Select placeholder="Age Type Set" style={{  }} value={this.state.age_type} 
                onChange={(val)=>{this.setState({age_type:val})
                this.handle_agetypeChange(val)
              }} >
                  {this.state.age_types.map(
                    (tye)=>(
                      <Option value={tye.id}>{tye.setting_type_name}</Option>
                    ))}
                </Select>
            </FormItem>



            <FormItem label="Sur Name"
                name="surname"
                rules={[
                  {
                    required: true,
                    message: 'Please input sur name',
                  },
                ]}          
            >
              <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={this.handlesur_nameChange} />
            </FormItem>
  
            <FormItem label="Middle Name">
              <Input  placeholder="Put a name here." value={this.state.middle_name} onChange={this.handlemiddle_nameChange} />
            </FormItem>
  
            <FormItem label="Given Name"
                name="givenname"
                rules={[
                  {
                    required: true,
                    message: 'Please input given name',
                  },
  
                ]}           
            >
              <Input  placeholder="Put a name here." value={this.state.given_name} onChange={this.handlegiven_nameChange} />
            </FormItem>

            <FormItem label="Gender"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: 'Please select',
                    },
                  ]}           
              
              >
                  <Select placeholder="Gender" style={{ width: 120 }} value={this.state.gender} onChange={this.handlegenderChange} >
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                  </Select>
              </FormItem>
  
            <FormItem label="Nationality"
                name="nationality"
                rules={[
                  {
                    required: true,
                    message: 'Please input nationality',
                  },
                ]}            
            >
              <Input  placeholder="Nationality." value={this.state.nationality} onChange={this.handlenationalityChange} />
            </FormItem>
  
  
            <FormItem label="ID Type"
                name="idtype"
                rules={[
                  {
                    required: true,
                    message: 'Please select id type',
                  },
                ]}           
            >
                <Select placeholder="Id Type" style={{  }} value={this.state.ID_type} onChange={this.handleID_typeChange} >
                  {this.state.idtypes.map(
                    (type)=>(
                      <Option value={type.id}>{type.id_type_name}</Option>
                    ))}
                </Select>
            </FormItem>
  
            <FormItem label="ID Number">
              <Input  placeholder="ID Number." value={this.state.ID_number} onChange={this.handleID_numberChange} />
            </FormItem>

            <div>

            <div style={{display:'flex',flexDirection:'row'}}>
            <FormItem label="ID date of expiry"
              name="id_expirydate"
              rules={[
                {
                  required: true,
                  message: 'Please select Id date of expiry ',
                },
              ]}                   
              style={{display:'flex',margin:3}} 
              >
                  <DatePicker onChange={(date, dateString)=>{
                    this.setState({id_expirydate:dateString})
                    this.checkidvalidity(dateString)

                  }} format={dateFormat} />
              </FormItem>
              {
              this.state.id_valid===false?
              <WarningFilled style={{ fontSize: 30,display:'flex',color:'red',alignSelf:'center' }} />
              :
              <CheckCircleFilled style={{ fontSize: 30,display:'flex',color:'green',alignSelf:'center' }} />
              }
            </div>
            </div>


  
            <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Date of Birth"
                      name="dateofbirth"
                      rules={[
                        {
                          required: true,
                          message: 'Please select date of birth',
                        },
                      ]}   
                      style={{display:'flex',margin:3}}        
                  >
                        <DatePicker onChange={ this.handledate_Of_BirthChange} format={dateFormat} />
                  </FormItem>

                  {
                  this.state.age_valid===false?
                  <WarningFilled style={{ fontSize: 30,display:'flex',color:'red',alignSelf:'center' }}  />
                  :
                  <CheckCircleFilled style={{ fontSize: 30,display:'flex',color:'green',alignSelf:'center' }}  />
                  }

              </div>
  
  
            <FormItem label="Email Address"
                name="emailaddress"
                rules={[
                  {
                    required: false,
                    message: 'Please input mail',
                  },
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ]}            
            
            >
            <Input  type="mail" 
                    prefix={<MailOutlined className="site-form-item-icon" />} 
                    placeholder="Email"
                    value={this.state.Email_Address} 
                    onChange={this.handleEmail_AddressChange} />
            </FormItem>
  
            <FormItem label="Box Number">
              <Input placeholder="box number." value={this.state.P_O_Box} onChange={this.handleP_O_BoxChange} />
            </FormItem>
  
            <FormItem label="Area of Residence"
                name="areaofresidence"
                rules={[
                  {
                    required: true,
                    message: 'Please enter residence',
                  },
                ]}           
            >
              <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={this.handleArea_Of_ResidenceChange} />
            </FormItem>
  
            <FormItem label="District"
                name="district"
                rules={[
                  {
                    required: true,
                    message: 'Please enter district of residence',
                  },
                ]}           
            >
              <Select placeholder="District" style={{  }} value={this.state.District} onChange={(val)=>{this.setState({District:val})}} >
                {this.state.ugdistricts.map(
                  (dist)=>(
                    <Option value={dist.district_name}>{dist.district_name} | {dist.region}</Option>
                  ))}
              </Select>
            </FormItem>
  
            <FormItem label="Phone One"                    
            >
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.RegisteredPhoneNumber1} onChange={this.handleRegisteredPhoneNumber1Change}/>
             </FormItem> 
  
             <FormItem label="Phone Two">
             <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.RegisteredPhoneNumber2} onChange={this.handleRegisteredPhoneNumber2Change}/>
             </FormItem> 
  
  
            <FormItem label="Next of kin">
              <Input placeholder="Next of kin." value={this.state.next_of_kin} onChange={this.handlenext_of_kinChange} />
            </FormItem>
  
            <FormItem label="Next of kin Address">
              <Input placeholder="Next of Kin Address." value={this.state.Next_Of_Kin_Physical_Address} onChange={this.handleNext_Of_Kin_Physical_AddressChange} />
            </FormItem>
  
            <FormItem label="Relationship">
              <Input placeholder="Relationship." value={this.state.relationship} onChange={this.handlerelationshipChange} />
            </FormItem>
  
            <div style={{display:'flex',flexDirection:'row'}}>

            <FormItem label="Staff Photo">
                  <Input type="file" accept="image/png, image/jpeg" placeholder="Staff Photo"  onChange={this.handlePhotoChange}/>
            </FormItem>
            &nbsp;
            {this.state.Photo?
              <Image
              width={50}
              height={50}
              src={this.state.photofile}    
              />
              :
              null
              } 
            </div>

            <div style={{display:'flex',flexDirection:'row'}}>
            <FormItem label="Staff Id photo" style={{marginRight:3}}>
                <Input type="file" accept="image/png, image/jpeg" placeholder="Staff Id photo"  onChange={this.handleid_photoChange}/>
            </FormItem> 
            {this.state.id_photo?
              <Image
              width={50}
              height={50}
              src={this.state.id_photofile}    
              />
              :
              null
              }
            </div>


  
            <FormItem label="Date of Joining">
                  <DatePicker onChange={this.handleDate_Of_JoiningChange} format={dateFormat}/>
            </FormItem>
  
  
            <FormItem label="Staff Role"
               name="staffrole"
               rules={[
                 {
                   required: true,
                   message: 'Please enter staff role',
                 },
               ]}           
            >
                <Select placeholder="Staff Role" style={{  }} value={this.state.staff_role} onChange={this.handlestaff_roleChange} >
                  {this.state.staffroles.map(
                    (stf)=>(
                      <Option value={stf.id}>{stf.role_name}</Option>
                    ))}
                </Select>
            </FormItem>
            
  
            <FormItem label="Date of Appointment">
                  <DatePicker onChange={this.handledate_of_appointmentChange} format={dateFormat}/>
            </FormItem>
            
  
            <FormItem label="Staff Status"
               name="staffstatus"
               rules={[
                 {
                   required: true,
                   message: 'Please select staff status',
                 },
               ]}           
            
            >
                <Select placeholder="Status" style={{ width: 120 }} value={this.state.status} onChange={this.handlestatusChange} >
                      <Option value="Active">Active</Option>
                      <Option value="Inactive">Inactive</Option>
                </Select>
            </FormItem>
  
  
            <FormItem label="Staff Number">
              <Input placeholder="Staff Number." value={this.state.staff_number} onChange={this.handlestaff_numberChange} />
            </FormItem>
  
            <FormItem label="Job Reference Personel">
              <Input placeholder="Reference Name." value={this.state.reference_one} onChange={this.handlereference_oneChange} />
            </FormItem>
  
            <FormItem label="Reference Phone No.">
              <PhoneInput
                    countrySelectProps={{ unicodeFlags: true }}
                    defaultCountry="UG"
                    placeholder="Enter Refree Tel"
                    value={this.state.Referee1PhoneNumber} onChange={this.handleReferee1PhoneNumberChange}/>
            </FormItem>

            <FormItem label="Contract attachment">
                  <Input type="file" accept=".pdf" placeholder="Contract attachment" 
                   onChange={(e)=>{
                    var kilbyes=e.target.files[0].size/1024
                    if (kilbyes>Number(1024)){
                      message.error("File is greater than "+"1024"+" Kbs or 1 mb .It will not be uploaded.")
                    }else{
                      this.setState({contract_file:e.target.files[0]})
                    }
                    
                  }}/>
            </FormItem>
  
  
            <FormItem>
              <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
            </FormItem>
          </Form>
        </div>
      );

    }
    
  }

}

export default StaffForm;



