import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined,WarningFilled,CheckCircleFilled } from '@ant-design/icons';
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var ngoid= ''
var bizuserid= ''


class ChildForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accounttypes:[],
    idtypes:[],
    sur_name:'' ,  
    middle_name:'',
    given_name:'',
    nationality:'',
    ID_type:'',
    ID_number:'',
    date_Of_Birth:'',
    Email_Address:'',
    P_O_Box:'',
    Area_Of_Residence:'',
    District:'',
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    next_of_kin:'',
    Next_Of_Kin_Physical_Address:'',
    relationship:'',
    Photo:null,
    Signature:null,
    Thumbprint:null,

    Date_Of_Joining:'',
    account_type:'',
    date_of_appointment:'',
    status:'',
    staff_number:'',
    reference_one:'',
    Referee1PhoneNumber:'',
    datarequested:true,
    accountnumber:'',
    photofile:null,
    signaturefile:null,
    thumbprintfile:null,
    marital_status:'',
    own_residence:'',
    tribe:'',
    recommender:'',
    language1:'',
    language2:'',
    gender:'',
    any_other_account:'',
    other_account_number:'',
    other_bank:'',
    shares:0,
    title:'',
    datasubmittedsuccessfully:false,
    saccopackageobj:{},
    members:[],
    companyprofile:{},

    occupation:'',
    postaladdress:'',
    homecelllocaltion:'',
    cell:'',
    cellleader:'',
    nameofemployer_business:'',

    addressofemployer_business:'',
    refree_institutionalmember_name:'',
    refree_institutionalmember_contact:'',
    refree_cellleader_name:'',
    refree_cellleader_contact:'',
    next_of_kin_contact:'',

    child_account_school:'',
    child_account_school_class:'',

    membertype:'senior',
    pysical_form:null,
    saving_period:'',

    id_expirydate:'',
    ugdistricts:[],
    cellleader_contact:'',
    id_valid:false,
    agetypeobject:{},
    idtypeobject:{},
    reapersrefobj:{},
    reapersselectedref:'',

    age_types:[],
    age_type:'',
    bankaccount:'',
    mobilemoney_contact:'',
    schools:[],
    selected_school:'',
    child_account_school_class:'',
    guardians:[],
    selected_guardian:'',

  }

  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/idtypes/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          idtypes:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/ngos/${ngoid}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })

    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/ugdistricts/?Ngo=${ngoid}`)
    .then(res => {
      this.setState({
        ugdistricts:res.data
      })
    })

    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/age_types/?Ngo=${ngoid}`)
    .then(res => {
      this.setState({
        age_types:res.data
      })
    })

    axios.get(serverconfig.backendserverurl+`/api/schools/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          schools:res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/guardians/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          guardians:res.data
        })

        this.setState({datarequested:false})
    })

})

}

//imgae resizer
 resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      400,
      "JPEG",
      50,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

  //convert back to file
   dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };



  //submit button pressed
  handleFormSubmit=(event) =>{

      if (this.state.RegisteredPhoneNumber1===''){
        message.info("Phone Number missing")
      }else{
        this.setState({datarequested:true})
  
        let form_data = new FormData();
        form_data.append('date', this.state.date);
        form_data.append('SurName', this.state.sur_name);
        form_data.append('MiddleName', this.state.middle_name);
        form_data.append('OtherName', this.state.given_name);
        form_data.append('Nationality', this.state.nationality);
          
        form_data.append('DateOfBirth', this.state.date_Of_Birth);
    
        form_data.append('EmailAddress', this.state.Email_Address);
        form_data.append('POBox', this.state.P_O_Box);
        form_data.append('AreaOfResidence', this.state.Area_Of_Residence);
        form_data.append('District', this.state.District);
        form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
        form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);
    
        form_data.append('next_of_kin', this.state.next_of_kin);
        form_data.append('NextOfKinPhysicalAddress', this.state.Next_Of_Kin_Physical_Address);
        form_data.append('relationship', this.state.relationship);
        form_data.append('DateOfJoining', this.state.Date_Of_Joining);
    
        form_data.append('accountType', this.state.account_type);
        form_data.append('gender', this.state.gender);
        form_data.append('marital_status', this.state.marital_status);
        form_data.append('title', this.state.title);
    
        form_data.append('own_residence', this.state.own_residence);
        form_data.append('tribe', this.state.tribe);
    
        form_data.append('language1', this.state.language1);
        form_data.append('language2', this.state.language2);
  
        form_data.append('Ngo', ngoid);
        form_data.append('user', bizuserid);
  
        form_data.append('next_of_kin_contact', this.state.next_of_kin_contact);
    
        this.state.Photo==null?
        console.log("No profile file")
        :
        form_data.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");
      
        form_data.append('guadian_child_Type', this.state.age_type);
        form_data.append('school', this.state.selected_school);
        form_data.append('child_account_school_class', this.state.child_account_school_class);
        form_data.append('guadian', this.state.selected_guardian);

        axios.post(serverconfig.backendserverurl+'/api/children/', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res =>{
          console.log(res.data)
          this.setState({datarequested:false})
          this.setState({datasubmittedsuccessfully:true})
          
        })
        .catch(error => console.log(error))
      }

  }

  handlesur_nameChange= (e) => this.setState({ sur_name: e.target.value});
  handlemiddle_nameChange= (e) => this.setState({ middle_name: e.target.value});
  handlegiven_nameChange= (e) => this.setState({ given_name: e.target.value});
  handlenationalityChange= (e) => this.setState({ nationality: e.target.value});

  handletitleChange= (e) => this.setState({ title: e});

  handleID_numberChange= (e) => this.setState({ ID_number: e.target.value});
  handledate_Of_BirthChange= (date, dateString) =>{
    this.setState({ date_Of_Birth: dateString});
  } 

  handleEmail_AddressChange= (e) => this.setState({ Email_Address: e.target.value});
  handleP_O_BoxChange= (e) => this.setState({ P_O_Box: e.target.value});
  handleArea_Of_ResidenceChange= (e) => this.setState({ Area_Of_Residence: e.target.value});
  handleDistrictChange= (e) => this.setState({ District: e.target.value});
  handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});

  handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});
  handlenext_of_kinChange= (e) => this.setState({ next_of_kin: e.target.value});
  handleNext_Of_Kin_Physical_AddressChange= (e) => this.setState({ Next_Of_Kin_Physical_Address: e.target.value});
  handlerelationshipChange= (e) => this.setState({ relationship: e.target.value});
 
 //image provessing
  handlePhotoChange= async(e) =>{
    //this.setState({ Photo: e.target.files[0]});
    console.log(e.target.files[0])

    if(e.target.files[0]){
      this.setState({
        photofile: URL.createObjectURL(e.target.files[0])
      })

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ Photo: newFile});
    console.log(newFile);

    }

  } 

  handleSignatureChange= (e) =>{
    this.setState({ Signature: e.target.files[0]});
    if(e.target.files[0]){
      this.setState({
        signaturefile: URL.createObjectURL(e.target.files[0])
      })
    }


  } 

  handleDate_Of_JoiningChange= (date, dateString) => this.setState({ Date_Of_Joining: dateString});
  
  handle_agetypeChange= (e) =>{
    this.setState({account_type: e});

    axios.get(serverconfig.backendserverurl+`/api/age_types/${e}`)
    .then(res => {
        this.setState({
          agetypeobject:res.data
        })
    })

  
  }
  handledate_of_appointmentChange= (date, dateString) => this.setState({ date_of_appointment: dateString});

  handlestatusChange= (e) => this.setState({ status: e});
  handlemarital_statusChange= (e) => this.setState({ marital_status: e});
  handleown_residenceChange= (e) => this.setState({ own_residence: e});

  handlestaff_numberChange= (e) => this.setState({ staff_number: e.target.value});
  handlereference_oneChange= (e) => this.setState({ reference_one: e.target.value});
  handleReferee1PhoneNumberChange= (e) => this.setState({ Referee1PhoneNumber: e});

  handleaccountnumberChange= (e) => this.setState({ accountnumber: e.target.value});
  handletribeChange= (e) => this.setState({ tribe: e.target.value});
  handlerecommenderChange= (e) => this.setState({ recommender: e.target.value});
  handlelanguage1Change= (e) => this.setState({ language1: e.target.value});
  handlelanguage2Change= (e) => this.setState({ language2: e.target.value});
  handlegenderChange= (e) => this.setState({ gender: e});

  handleother_account_numberChange= (e) => this.setState({ other_account_number: e.target.value});
  handleany_other_accountChange= (e) => this.setState({ any_other_account: e});
  handleother_bankChange= (e) => this.setState({ other_bank: e.target.value});
  handlesharesChange= (e) => this.setState({ shares: e.target.value});



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Created New Child "
            subTitle="child details will be further reviewed if need be"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

            return (
              <div>
                <Form
                    onFinish={(event) => this.handleFormSubmit(event)}
                >

                <h3>Bio Data Section</h3>
                  <FormItem label="Sur Name"
                      name="surname"
                      rules={[
                        {
                          required: true,
                          message: 'Please input sur name',
                        },
                      ]}          
                  >
                    <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={this.handlesur_nameChange} />
                  </FormItem>
        
                  <FormItem label="Middle Name">
                    <Input  placeholder="Put a name here." value={this.state.middle_name} onChange={this.handlemiddle_nameChange} />
                  </FormItem>
        
                  <FormItem label="Given Name"
                      name="givenname"
                      rules={[
                        {
                          required: true,
                          message: 'Please input given name',
                        },
        
                      ]}           
                  >
                    <Input  placeholder="Put a name here." value={this.state.given_name} onChange={this.handlegiven_nameChange} />
                  </FormItem>
      
                  <FormItem label="Gender"
                     name="gender"
                     rules={[
                       {
                         required: true,
                         message: 'Please select',
                       },
                     ]}           
                  >
                      <Select placeholder="Gender" style={{ width: 120 }} value={this.state.gender} onChange={this.handlegenderChange} >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                      </Select>
                  </FormItem>

                  <FormItem label="Date of Birth"
                      name="dateofbirth"
                      rules={[
                        {
                          required: false,
                          message: 'Please select date of birth',
                        },
                      ]}   
                      style={{display:'flex',margin:3}}        
                  >
                        <DatePicker onChange={ this.handledate_Of_BirthChange} format={dateFormat} />
                  </FormItem>

        
                  <FormItem label="Nationality"
                      name="nationality"
                      rules={[
                        {
                          required: true,
                          message: 'Please input nationality',
                        },
                      ]}            
                  >
                    <Input  placeholder="Nationality." value={this.state.nationality} onChange={this.handlenationalityChange} />
                  </FormItem>
        


                  <FormItem label="Tribe">
                    <Input  placeholder="Tribe." value={this.state.tribe} onChange={this.handletribeChange} />
                  </FormItem>
      
                  <FormItem label="Language one">
                    <Input  placeholder="Language one." value={this.state.language1} onChange={this.handlelanguage1Change} />
                  </FormItem>
      
                  <FormItem label="Language two">
                    <Input  placeholder="Language two." value={this.state.language2} onChange={this.handlelanguage2Change} />
                  </FormItem>
      
                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Child Photo" style={{marginRight:3}}>
                        <Input type="file" accept="image/png, image/jpeg" placeholder="Member Photo"  onChange={this.handlePhotoChange}/>
                  </FormItem> 
      
                  {this.state.Photo?
                  <Image
                  width={50}
                  height={50}
                  src={this.state.photofile}    
                  />
                  :
                  null
                  }
                  </div>

                
                  <h3>Contact Section</h3>
                  <FormItem label="Email Address"
                      name="emailaddress"
                      rules={[
                        {
                          required: false,
                          message: 'Please input mail',
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                      ]}            
                  
                  >
                  <Input  type="mail" 
                          prefix={<MailOutlined className="site-form-item-icon" />} 
                          placeholder="Email"
                          value={this.state.Email_Address} 
                          onChange={this.handleEmail_AddressChange} />
                  </FormItem>
        
                  <FormItem label="Postal Address (P O Box)">
                    <Input placeholder="box number." value={this.state.P_O_Box} onChange={this.handleP_O_BoxChange} />
                  </FormItem>


                  <FormItem label="Residential (Physical) Address"
                      name="areaofresidence"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter residence',
                        },
                      ]}           
                  >
                    <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={this.handleArea_Of_ResidenceChange} />
                  </FormItem>
        
                  <FormItem label="District"
                     name="district"
                     rules={[
                       {
                         required: true,
                         message: 'Please enter district of residence',
                       },
                     ]}           
                  >
                    <Select placeholder="District" style={{  }} value={this.state.District} onChange={(val)=>{this.setState({District:val})}} >
                      {this.state.ugdistricts.map(
                        (dist)=>(
                          <Option value={dist.district_name}>{dist.district_name} | {dist.region}</Option>
                        ))}
                    </Select>
                  </FormItem>
        
                  <FormItem label= { <h6 style={{display:'flex'}} >* Phone One</h6>}                       
                  >
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber1} onChange={this.handleRegisteredPhoneNumber1Change}/>
                   </FormItem> 
        
                   <FormItem label="Phone Two">
                   <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber2} onChange={this.handleRegisteredPhoneNumber2Change}/>
                   </FormItem>


                   <h3>Parent / Next of Kin Section </h3>

                  <FormItem label="Parent / Next of kin">
                    <Input placeholder="Next of kin." value={this.state.next_of_kin} onChange={this.handlenext_of_kinChange} />
                  </FormItem>

                  <FormItem label="parent / Next Of Kin Contact">
                   <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="next of kin contact"
                        value={this.state.RegisteredPhoneNumber2} onChange={(val)=>{this.setState({next_of_kin_contact:val})}}/>
                   </FormItem>

        
                  <FormItem label="Next of kin Address">
                    <Input placeholder="Next of Kin Address." value={this.state.Next_Of_Kin_Physical_Address} onChange={this.handleNext_Of_Kin_Physical_AddressChange} />
                  </FormItem>
        
                  <FormItem label="Next Of Kin Relationship">
                    <Input placeholder="Next of kin Relationship." value={this.state.relationship} onChange={this.handlerelationshipChange} />
                  </FormItem>

                  <FormItem label="Date of Joining"
                    name="dateofjoining"
                    rules={[
                      {
                        required: true,
                        message: 'Please select',
                      },
                    ]}  
                  >
                        <DatePicker onChange={this.handleDate_Of_JoiningChange} format={dateFormat}/>
                  </FormItem>

                  <h3>School Section</h3>
                  <FormItem label="School"
                     name="school"
                     rules={[
                       {
                         required: false,
                         message: 'Please select school',
                       },
                     ]}           
                  >
                    <Select placeholder="School"
                      style={{  }}
                      value={this.state.selected_school} 
                      onChange={(val)=>{this.setState({selected_school:val})
                    }} >
                        {this.state.schools.map(
                          (tye)=>(
                            <Option value={tye.id}>{tye.school_name}</Option>
                          ))}
                      </Select>
                  </FormItem>

                  <FormItem label="Student Class"
                    name="child_account_school_class"
                    rules={[
                      {
                        required: false,
                        message: 'Please enter class',
                      },
                    ]}
                  >
                    <Input placeholder="Child class." value={this.state.child_account_school_class} onChange={(val)=>{this.setState({child_account_school_class:val.target.value})}} />
                  </FormItem>

                    <h3>Child Guardian</h3>
                  <FormItem label="Guardian"
                     name="selected_guardian"
                     rules={[
                       {
                         required: true,
                         message: 'Please select school',
                       },
                     ]}           
                  >
                    <Select placeholder="Guardian"
                      style={{  }}
                      value={this.state.selected_guardian} 
                      onChange={(val)=>{this.setState({selected_guardian:val})
                    }} >
                        {this.state.guardians.map(
                          (tye)=>(
                            <Option value={tye.id}>{tye.FullName}</Option>
                          ))}
                      </Select>
                  </FormItem>
        
                <FormItem >
                  <Button  type='primary' htmlType="submit">Submit</Button>
                </FormItem>
    
              </Form>
            </div>
          );
    }

    }
    
  }

}

export default ChildForm;

