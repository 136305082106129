
import React from 'react'
import { connect } from 'react-redux'
import { Layout,Avatar, Menu, Breadcrumb,Button,Row, Col, Divider,Card,Dropdown,Switch,
    message, Tooltip,InputNumber,Table,Popover,Modal,Image,Form,Select,Spin} from 'antd';
import { Drawer, List,Alert,notification,Affix } from 'antd';

import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LaptopOutlined,
  NotificationOutlined,
  SettingOutlined,
  AuditOutlined,
  IdcardOutlined,
  BarsOutlined,
  ShopOutlined,
  DollarOutlined,
  PicLeftOutlined,
  LogoutOutlined,
  EditOutlined, 
  EllipsisOutlined,
  HomeOutlined,
  SnippetsOutlined,
  KeyOutlined,
  FilePdfFilled,
  RightCircleFilled,
  DollarCircleOutlined,
  AccountBookOutlined,
  FilePptOutlined,
  PayCircleOutlined,
  BarChartOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  BankFilled,
  AndroidOutlined,
  DeploymentUnitOutlined,
  GroupOutlined,
  AccountBookFilled,
  CalculatorFilled,
  FileFilled,
  MessageFilled,
  LoadingOutlined,
  FundViewOutlined,
  LeftCircleFilled,
  HomeFilled,
  ArrowLeftOutlined

} from '@ant-design/icons';
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';
import UIfx from 'uifx';

import { NavLink } from 'react-router-dom'
import { PageHeader, Descriptions } from 'antd';
import { Link,withRouter} from 'react-router-dom';
import * as actions from '../../store/actions/auth'
import axios from 'axios'
import UserAccountUpdateForm from '../components/UserAccountUpdateForm'
import WrappedPasswordResetForm from './PasswordResetForm'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'

import logo from '../../assets/Pitech-logoconv.png';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import ReconnectingWebSocket from 'reconnecting-websocket';
import tickAudio from '../../assets/sounds/that-was-quick.mp3';

var CryptoJS = require("crypto-js");

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const style = { background: '#0092ff', padding: '8px 0' };
const { Meta } = Card;
const dateFormat = 'DD/MM/YYYY';
const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const tick = new UIfx(tickAudio,  {
  volume: 1.0, // number between 0.0 ~ 1.0
  throttleMs: 100
});

const pStyle = {
  fontSize: 16,
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};

var ngomoneycomingnotificationsocket = new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/ngomoneycomingnotification/');


const DescriptionItem = ({ title, content }) => (
  <div
    className="site-description-item-profile-wrapper"
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
    }}
  >
    <p
      className="site-description-item-profile-p"
      style={{
        marginRight: 8,
        display: 'inline-block',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

var bizuserid= ''
var ngoid= ''
var token= ''


class SaccoLayout extends React.Component{

  state = {
    collapsed: false,
    theme: 'dark',
    current: '1',
    titlecolor: '#fff',
    titlevisibility:"visible",
    userprofile:{},
    companyprofile:{},
    isToggle: null,
    visible: false,
    resetmodalvisible:false,
    updateformvisible:'none',
    userrights:{},
    date_today:moment().format(dateFormat).toString(),
    dashboardlocked:false,
    userbalancemodalvisible:false,
    users:[],
    userto:'',
    transferamount:0,
    datarequested:false,
    transfermode:'',
    safemanagertransfermode:'',
    numberofnotifications:0,
    notificationinfo:'',
    balanceacceptancemodalvisible:false,
    selectedtransferobject:{},
    acceptancestatus:'',
    shownotificationmodal:false,
    notifications:[]

  };

  // show the profile modal
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  // update user profile
  showUpdateForm=()=>{
    this.state.updateformvisible=="none"?
    this.setState({
      updateformvisible: "block",
    })
    :
    this.setState({
      updateformvisible: "none",
    })    
  }


  /////////////////////////////////////////////
  //PASSWORD RESET MODAL METHODS BEGIN HERE

  showpasswordresetModal = () => {
    this.setState({
      resetmodalvisible: true,
    });
  };

  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ resetmodalvisible: false });

  };


  //show user balance modal
  showuserbalancemodalvisible=()=>{
    this.setState({ userbalancemodalvisible: true });
  }

  hideuserbalancemodalvisible=()=>{
    this.setState({ userbalancemodalvisible: false });
  }

  //show hide balance acceptance modal
  openbalanceacceptancemodalmodal=(record)=>{
    this.setState({ acceptancestatus: ''})
    //console.log(record)
    this.setState({balanceacceptancemodalvisible: true });
    this.setState({selectedtransferobject:record})

  }

  closebalanceacceptancemodalmodal=()=>{
    this.setState({balanceacceptancemodalvisible: false });

  }


//component did mount
  componentDidMount(){
    if(localStorage.getItem("ngoid")){
       ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       ngoid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        bizuserid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    //component mount method
    this.componentmountmethod();
    

    

  }






//component mount method
componentmountmethod=()=>{
  let form_data = new FormData();
  form_data.append('userid', bizuserid);
  
  //get the user profile here
  axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
      this.setState({
        userprofile:res.data
      })
   
  })

  axios.get(serverconfig.backendserverurl+`/api/ngos/${ngoid}`)
  .then(res => {
      this.setState({
        companyprofile:res.data
      })

      if (res.data.activated===false){
        this.setState({dashboardlocked:true})
      }

  })


  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/accounts/?Ngo=${ngoid}`)
  .then(res => {
      this.setState({
        users:res.data
      })
    })


    //destroy all previous notifications
    notification.destroy()

    axios.get(`${serverconfig.backendserverurl}/api/ngonotification?Ngo=${ngoid}&read=${false}`)
    .then(res => {

        console.log(res.data)
      //now loop through the tasks
      res.data.map(
        (ntfy)=>{
          notification.open({
            top:60,
            duration:0,
            message:  String(ntfy.title),
            description:String(ntfy.message),
            icon: <MessageFilled style={{ color: "green" }} />,
          })
          
          this.setState({numberofnotifications:Number(this.state.numberofnotifications)+1})
        })

        this.setState({notifications:res.data})

    })


    //observer the socket changes now
    ngomoneycomingnotificationsocket.onopen = () => {
      console.log("onopen for money notification");
    // connection opened
    };

    ngomoneycomingnotificationsocket.onmessage = async (e) => {
        notification.destroy()

        // a message was received
        console.log("onmessage for stock",e.data);
        const data = JSON.parse(e.data);

        if(Number(data.ngoto)===Number(ngoid)){

          this.setState({numberofnotifications:Number(this.state.numberofnotifications)+1})

          //play notification
          tick.play();
  
          notification.open({
            top:60,
            duration:0,
            message:  data.title,
            description:data.message,
            icon: <MessageFilled style={{ color:"green" }} />,
          })

        }else{
          console.log("Message not for the ngo")
        }

    };

}



  //method for changing color theme 
  changeTheme = value => {
    this.setState({
      theme: value ? 'dark' : 'light',
    });

    this.setState({
      titlecolor: value ? '#fff' : '#A9A9A9',
    });
    

  };

  //method for collupsing of the menu
  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
    this.setState({titlevisibility: collapsed ? "hidden" :"visible"});
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });

    this.setState({titlevisibility:"hidden"});

  };


  handleusertoChange= (e) => this.setState({ userto: e});
  handletransferamountChange= (e) => this.setState({ transferamount: e});
  handletransfermodeChange= (e) => this.setState({ transfermode: e});
  handlesafemanagertransfermodeChange= (e) => this.setState({ safemanagertransfermode: e});
  handleacceptancestatusChange= (e) => this.setState({ acceptancestatus: e});


  //submit the deposit now
  handleFormSubmit=(event) =>{
    if (this.state.userto===''){
      message.info("User To cant be empty")
    }else if(Number(this.state.transferamount)>Number(this.state.userprofile.User_balance)){
      message.info("Transfer Amount cant be greater than available user balance")
    }else if(Number(this.state.userto)===Number(bizuserid)){
      message.info("You cant transfer to own account")
    }
    else{
      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('date',moment().format(dateFormat).toString());
      form_data.append('userfrom', bizuserid);
      form_data.append('userto', this.state.userto);
      form_data.append('transferAmount', this.state.transferamount);
      form_data.append('status', "pending");
      form_data.append('ngoid', ngoid);

      axios.post(serverconfig.backendserverurl+'/customqueries/userbalancetransfer', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
      })
      .catch(error => console.log(error))
    }
    
  }


  handlemanagerTransferSubmit=(event) =>{
    if (this.state.safemanagertransfermode===''){
      message.info("Safe Manager Transfer Mode cant be empty")
    }else if(this.state.safemanagertransfermode==="manager_safe" &&  Number(this.state.transferamount)>Number(this.state.userprofile.User_balance)){
      message.info("Transfer Amount cant be greater than available user balance")
    }else if(this.state.safemanagertransfermode==="safe_manager" &&  Number(this.state.transferamount)>Number(this.state.companyprofile.safe_balance)){
      message.info("Transfer Amount cant be greater than available safe balance")
    }
    else{
      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('date',moment().format(dateFormat).toString());
      form_data.append('userid', bizuserid);
      form_data.append('transferAmount', this.state.transferamount);
      form_data.append('ngoid', ngoid);
      form_data.append('safemanagertransfermode', this.state.safemanagertransfermode);

      axios.post(serverconfig.backendserverurl+'/customqueries/managersafetransfer', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
      })
      .catch(error => console.log(error))
    }

  }

//confirm the transfer here
  receiveUserBalance = () => {
    let form_data1 = new FormData();
    form_data1.append('transferid', this.state.selectedtransferobject.id);
    form_data1.append('ngoid', ngoid);
    form_data1.append('acceptancestatus', this.state.acceptancestatus);

    axios.post(`${serverconfig.backendserverurl}/customqueries/receiveuserbalance`, form_data1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      }).then(res =>{
       message.info("successfully response") 
     }
     );

  };



   render(){

    var transfercolumns = [

      {
        title: 'User From',
        dataIndex: 'userfromname',
        key: 'id',
      },

      {
        title: 'Transfer Amount',
        dataIndex: 'transferAmount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
     
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Accept Or Reject</p>} title="Accept Reject">
         <EditOutlined style={{color:'blue'}} onClick={()=>{this.openbalanceacceptancemodalmodal(record)}}/>
        </Popover>
       
        </p>,
      }

    ];


    return (
      <div>
        {
         this.props.isAuthenticated ?
         <Layout style={{ minHeight: '100vh' }} >

             <Sider
             collapsedWidth="0"
             collapsed={this.state.collapsed}
             onCollapse={this.onCollapse} 
             theme={this.state.theme}
             breakpoint="lg"
             onBreakpoint={broken => {
               console.log(broken);
             }}
             zeroWidthTriggerStyle={{display:'none'}}
             style={{width:400}}
             >
               <br></br>
              <div className="logo" style={{ paddingLeft: 30}} mode="inline">
              <Row>
   
                <Col span={6}>
   {/*               <Avatar size={40} icon={<UserOutlined />} alt="Logo" src={this.state.companyprofile.company_logo} />
           */}               
                <Image
                 width={50}
                 height={50}
                 src={this.state.companyprofile.ngo_logo}   
                 preview={false} 
                 />
                <h6 style={{display:'flex',color:'white',margin:3}}>{this.state.companyprofile.ngo_name}</h6> 

                </Col>
   {/*             <Col span={18}><NavLink to='/'><h5 style={{color:this.state.titlecolor,paddingTop: 10,visibility:this.state.collapsed?"hidden":"visible" }}>{this.state.companyprofile.company_name}</h5></NavLink></Col>
         */}           </Row>
              <br/>
              </div>
   
              <Menu 
              theme={this.state.theme}
              onClick={this.handleClick}
              defaultSelectedKeys={['1']} 
              mode="vertical">
   
               <SubMenu
                  key="sub1"
                  title={
                    <span>
                      <HomeOutlined />
                      <span>Quick Menu</span>
                    </span>
                  }
                >
{ /*              <Menu.Item key="0" >
                 <RightCircleFilled />
                 <NavLink to='/'><span>Home</span></NavLink>
   
               </Menu.Item> */}
   
               <Menu.Item key="890" >
                 <RightCircleFilled />
                 <NavLink to='/quickmenu/'><span>Quick Menu</span></NavLink>
               </Menu.Item>
   
               </SubMenu>

                {
                  this.state.companyprofile.ngo_controller===true && this.state.userrights.can_view_otherngos===true?
                  <SubMenu
                  key="sub22"
                  title={
                    <span>
                      <UserOutlined />
                      <span>NGOs</span>
                      </span>
                    }
                  >
                  <Menu.Item key="1" >
                  <RightCircleFilled />
                  <NavLink to='/managengos/'><span>Manage NGOs</span></NavLink>
                  </Menu.Item>
                 </SubMenu>
                  :
                  null
                }


                {
                 this.state.userrights.can_view_guardians===true?

               <SubMenu
                  key="sub2"
                  title={
                    <span>
                      <UserOutlined />
                      <span>Guardians</span>
                    </span>
                  }
                >
                  <Menu.Item key="001" >
                  <RightCircleFilled />
                  <NavLink to='/guardians/'><span>Manage Guardians</span></NavLink>
                  </Menu.Item>
               </SubMenu>
                :
                null
              }


            {
              this.state.userrights.can_view_children===true?
               <SubMenu
                  key="sub3"
                  title={
                    <span>
                      <AccountBookFilled />
                      <span>Children</span>
                    </span>
                  }
                >
                  <Menu.Item key="3" >
                  <RightCircleFilled />
                  <NavLink to='/managechildren/'><span>Manage children</span></NavLink>
                 </Menu.Item>

                 {              
                 this.state.userrights.can_view_schoolfees_budget===true? 
                 <Menu.Item key="67663" >
                  <RightCircleFilled />
                  <NavLink to='/manageschoolfees/'><span>Manage fees</span></NavLink>
                 </Menu.Item>
                 :
                 null
                 }


                {              
                 this.state.userrights.can_view_childdevelopmentreport===true? 
                 <Menu.Item key="67622263" >
                  <RightCircleFilled />
                  <NavLink to='/managedevelopmentreports/'><span>Manage development reports</span></NavLink>
                 </Menu.Item>
                 :
                 null
                }
                
               </SubMenu>
                :
                null
              }
               
   
                {
                  this.state.companyprofile.ngo_controller===true && this.state.userrights.can_view_ge_transactions===true?
                  <SubMenu
                  key="sub333"
                  title={
                    <span>
                      <DollarCircleOutlined />
                      <span>GE Transactions</span>
                    </span>
                  }
                >

                  <Menu.Item key="000977" >
                  <RightCircleFilled />
                  <NavLink to='/sponsortransactions/'><span>Manage GE money</span></NavLink>
                 </Menu.Item>
                
               </SubMenu>
                  :
                  null
                }


              {
                  this.state.companyprofile.ngo_controller===true && this.state.userrights.can_view_ngotransactions===true?
                  <SubMenu
                  key="sub33333"
                  title={
                    <span>
                      <DollarCircleOutlined />
                      <span>Ngo-Ngo Transactions</span>
                    </span>
                  }
                >

                  <Menu.Item key="001111094477" >
                  <RightCircleFilled />
                  <NavLink to='/ngo_transactionbatches/'><span>Preparations</span></NavLink>
                  </Menu.Item>
                  
                  <Menu.Item key="00094477" >
                  <RightCircleFilled />
                  <NavLink to='/ngo-ngotransactions/'><span>Transfers</span></NavLink>
                 </Menu.Item>
                
               </SubMenu>
                :
                null
              }

          {
          this.state.userrights.can_view_expense===true?
            <SubMenu
            key="sub4"
            title={
              <span>
                <CalculatorFilled />
                <span>Expenses</span>
              </span>
            }
          >

            <Menu.Item key="7" >
              <RightCircleFilled />
              <NavLink to='/expenses/'><span>Manage Expenses</span></NavLink>
            </Menu.Item>

            </SubMenu>
            :
            null
          }

         {
          this.state.userrights.view_staff===true?
            <SubMenu
            key="sub334"
            title={
              <span>
                <CalculatorFilled />
                <span>Staff</span>
              </span>
            }
          >

            <Menu.Item key="711222" >
              <RightCircleFilled />
              <NavLink to='/staffs/'><span>Manage Staff</span></NavLink>
            </Menu.Item>

            </SubMenu>
            :
            null
          }

                        
               {
                  this.state.userprofile.managerial_account?
                  <SubMenu
                  key="sub8"
                  title={
                    <span>
                      <SettingOutlined />
                      <span>Settings</span>
                    </span>
                  }
                >

               <Menu.Item key="38" >
                 <NavLink to='/useraccounts/'><span>User Accounts</span></NavLink>
               </Menu.Item>
  
               <Menu.Item key="14" >
                 <NavLink to='/idtypes/'><span>ID Types</span></NavLink>
               </Menu.Item> 

               <Menu.Item key="1114" >
                 <NavLink to='/agetypes/'><span>Age Types</span></NavLink>
               </Menu.Item> 

               <Menu.Item key="17" >
                 <NavLink to='/expensecategories/'><span>Expense Categories</span></NavLink>
               </Menu.Item>            


               <Menu.Item key="122334402" >
                 <NavLink to='/companyaccounts/'><span>Ngo bank Accounts</span></NavLink>
               </Menu.Item> 

               <Menu.Item key="122336664402" >
                 <NavLink to='/ngomobilemoneycontacts/'><span>Ngo mobile money contacts</span></NavLink>
               </Menu.Item> 


               <Menu.Item key="12202" >
                 <NavLink to='/ugdistricts/'><span>Ugandan Districts</span></NavLink>
               </Menu.Item> 

               <Menu.Item key="1224402" >
                 <NavLink to='/manageschools/'><span>Ngo Schools</span></NavLink>
               </Menu.Item> 

               <Menu.Item key="122440002" >
                 <NavLink to='/manageterms/'><span>School terms</span></NavLink>
               </Menu.Item> 

               <Menu.Item key="3333343332" >
                 <NavLink to='/staffroles/'><span>Staff roles</span></NavLink>
               </Menu.Item> 

               <Menu.Item key="333334333002" >
                 <NavLink to='/schedulednotifications/'><span>Scheduled notifications</span></NavLink>
               </Menu.Item> 
               
               </SubMenu>
                  :
                  null
                } 

              
               <SubMenu
                  key="sub9"
                  title={
                    <span>
                      <FilePdfFilled />
                      <span>Reports</span>
                    </span>
                  }
                >

              {
              this.state.userrights.can_view_generaljournal===true?
              <Menu.Item key="23" >
              <NavLink to='/tillsheet/'><span>Journal</span></NavLink>
              </Menu.Item>   
              :
              null}               

            {
              this.state.userrights.can_view_ledger===true?
              <Menu.Item key="221123" >
              <NavLink to='/ledgerreport/'><span>Ledger</span></NavLink>
              </Menu.Item>                  
              :
              null}  

            {
              this.state.userrights.can_view_trial_balance===true?
              <Menu.Item key="22123" >
              <NavLink to='/trialbalance/'><span>Trial Balance</span></NavLink>
              </Menu.Item> 
              :
              null}                   

            {
              this.state.userrights.view_school_fees_budget===true?
            <Menu.Item key="200099332123" >
              <NavLink to='/schoolfeesreport/'><span>School fees report</span></NavLink>
            </Menu.Item>
            :
            null}  

            </SubMenu>


              {
                this.state.userrights.can_view_otherngos===true?
                <SubMenu
                key="sub229"
                title={
                  <span>
                    <FilePdfFilled />
                    <span>Other Ngo Reports</span>
                  </span>
                }
              >

              <Menu.Item key="8897"><NavLink to='/childrensreport/'> <RightCircleFilled /> Children Report</NavLink></Menu.Item>
              <Menu.Item key="88335597"><NavLink to='/guardiansreport/'> <RightCircleFilled /> Guardians Report</NavLink></Menu.Item>
              <Menu.Item key="885597"><NavLink to='/expensesreport/'> <RightCircleFilled /> Expenses Report</NavLink></Menu.Item>                 
              <Menu.Item key="881110995597"><NavLink to='/schoolfeesreport_others/'> <RightCircleFilled /> School fees budget Report</NavLink></Menu.Item>                 

             </SubMenu>
                :
                null
              }

   
              </Menu>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
   { /*          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <br></br>
                 <Switch
                    checked={this.state.theme === 'dark'}
                    onChange={this.changeTheme}
                    checkedChildren="Dark"
                    unCheckedChildren="Light"
                  />
              <br></br>
                 </div> */}
   
            </Sider>
           
         <Layout className="site-layout">
   
            <PageHeader
              style={{backgroundColor: "white"}}
              ghost={true}
              tags={React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {className: 'trigger',
              onClick: this.toggle,
            })}
              extra={[
                <Badge  onClick={()=>{
                  this.setState({shownotificationmodal:true})
              
                }}  pill color="danger">{this.state.numberofnotifications}</Badge>,
                <Avatar size={30} icon={<UserOutlined />} alt="User Pic" src={serverconfig.backendserverurl+this.state.userprofile.profile_pic} />,
                <Dropdown.Button overlay={(    <Menu >
                  <Menu.Item key="1" onClick={this.props.logout}>
                    <LogoutOutlined />
                    Log out
                  </Menu.Item>
                  <Menu.Item key="2" onClick={this.showDrawer}>
                    <UserOutlined />
                    View Profile
                  </Menu.Item>
                  <Menu.Item key="3" onClick={this.showpasswordresetModal}>
                    <KeyOutlined />
                    Change Password
                  </Menu.Item>
                  {
                    this.state.companyprofile.userbalancesfeature?
                    <Menu.Item key="4" onClick={this.showuserbalancemodalvisible}>
                    <DollarCircleOutlined />
                    User Balance
                    </Menu.Item>        
                    :
                    null
                  }
            
                </Menu>)}>
                Hi, {this.state.userprofile.username}
                </Dropdown.Button>
              ]}
              >
          </PageHeader>
        


           <Content style={{ margin: '0 16px' }}>
             <br></br>
             {
               this.state.notificationinfo===""?
               null
               :
               <NavLink >
                <Alert showIcon message={this.state.notificationinfo} type="info" onClick={()=>{this.showuserbalancemodalvisible()}} />
               </NavLink>
             }

             <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
             <br></br>
             <div style={{display:'flex',flexDirection:'row'}}>
              
             {
              this.props.location.pathname==='/'?
              null:
             <Affix>
              <Tooltip title="Go to home">
              <HomeOutlined style={{display:'flex',fontSize: 30,marginRight:30}} onClick={()=>{
                this.props.history.push('/')      
                }}/>
              </Tooltip>
            </Affix>
           }
            
            {
              this.props.location.pathname==='/'?
              null:
             <Affix>
              <Tooltip title="Go back">
              <ArrowLeftOutlined style={{display:'flex',fontSize: 30,marginRight:5}} onClick={()=>{
                this.props.history.goBack()      
                }}/>
              </Tooltip>
              </Affix>
             }

{/*              <Affix>

              <Tooltip title="Next page">
              <RightCircleFilled style={{display:'flex',fontSize: 30,marginLeft:5}} onClick={()=>{
                this.props.history.goForward()    


              }}/>
              </Tooltip>
              </Affix> */}

              </div>

              <br></br>           
            
             {this.props.children}
             </div>
           </Content>
           <Footer style={{ textAlign: 'center' }}>System Design © 2021 Created by PI-Tech</Footer>
         </Layout>
       </Layout>
         :
         <Layout style={{ minHeight: '100vh' }}>
         <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
           <div className="site-layout-background" style={{padding: 24, minHeight: 380,display: 'flex',  justifyContent:'center', alignItems:'center' }}>
             {this.props.children}
           </div>
         </Content>
         <Footer style={{ textAlign: 'center' }}>System Design © 2021 Created by PI-Tech</Footer>
         </Layout>
        }

        <Drawer
          title="Account Profile"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Avatar
              size={100}
              icon={<UserOutlined />}
              alt="client logo"
              src={serverconfig.backendserverurl+this.state.userprofile.profile_pic}
              />
          </p>
          <Row>
            <Col span={12}>
              <DescriptionItem title="User Name" content={this.state.userprofile.username} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Email" content={this.state.userprofile.email} />
            </Col>
          </Row>         
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Button type="primary" onClick={this.showUpdateForm}>
               {
                 this.state.updateformvisible=="none"?
                 "Open Update Form"
                 :
                 "Close Update Form"
               }
               
               </Button>
          </p>
          
          <div style={{display:this.state.updateformvisible}}>
           <UserAccountUpdateForm accountID={this.state.userprofile.id} />       

          </div>

        </Drawer>


        <Drawer
          title="Password Change Form"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onresetdrawerClose}
          visible={this.state.resetmodalvisible}
        >
            <WrappedPasswordResetForm />

        </Drawer>


        <Modal    
          visible={this.state.balanceacceptancemodalvisible}
          title="Balance Acceptance Modal"
          onCancel={this.closebalanceacceptancemodalmodal}
      
          footer={[
            <Button key="back" onClick={this.closebalanceacceptancemodalmodal}>
              Cancel
            </Button>
            ]}
            >
          <h6 style={{fontWeight:'bolder'}}>Accept or Reject {this.state.selectedtransferobject.transferAmount} from {this.state.selectedtransferobject.userfromname}</h6>
          <FormItem 
                label={<h6 style={{fontWeight:'bold'}}>Status </h6>}
                >
                    <Select 
                    value={this.state.acceptancestatus} 
                    onChange={this.handleacceptancestatusChange}
                    placeholder="Acceptance Status" >
                          <Option value='accept'>Accept</Option>
                          <Option value='reject'>Reject</Option>
                    </Select>
          </FormItem>
          <FormItem >
            <Button  type="primary" onClick={()=>{this.receiveUserBalance()}}>Submit</Button>
          </FormItem>

        </Modal>  

         <Modal            
          visible={this.state.shownotificationmodal}
          title="Notifications"
          onCancel={()=>{
            this.setState({shownotificationmodal:false})

            //mark them as read
            this.state.notifications.map((item)=>{
              let form_data = new FormData();
              form_data.append('read', true);

              axios.put(serverconfig.backendserverurl+`/api/ngonotification/${item.id}/`, form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
              .then(res => {
                console.log(res.data)
              })

            })

          }}
      
          footer={[
            <Button key="back" onClick={()=>{
              this.setState({shownotificationmodal:false})

              console.log(this.state.notifications)
              //mark them as read
              this.state.notifications.map((item)=>{
                console.log(item.id)
                let form_data = new FormData();
                form_data.append('read', true);

                axios.put(serverconfig.backendserverurl+`/api/ngonotification/${Number(item.id)}`, form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(res => {
                  console.log(res.data)
                })

              }) 

            }}>
              Cancel
            </Button>
            ]}
            >

          {this.state.notifications.map(
            (note)=>{
              return(<Alert closable style={{margin:6}} showIcon message={note.title} description={note.message} type="success" />);
              
            }

          )}

        </Modal>  

    </div>
  
  );

  }
}


const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(
      actions.logout(),
      )
  }
}


export default withRouter(connect(null,mapDispatchToProps)(SaccoLayout)) ;
