import React from 'react';
import {Form,Input,Button,Checkbox,message,Spin} from 'antd';
import axios from 'axios';
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var token= ''
var sacco= ''
var ngoid= ''

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

class  StaffUserSignUpForm extends React.Component {

  state = {
    isactive:false,
    ismanager:false,
    profilepic:null,  
    datarequested:false,
    saccopackageobj:{},
    saccoboject:{},
    useraccounts:[]

  }


  formRef = React.createRef();

  componentDidMount() {

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      ngoid= ''
   }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


  }


  onActiveCheckboxChange = e => {
    this.setState({isactive:e.target.checked});
  };

  onManagerCheckboxChange = e => {
    this.setState({ismanager:e.target.checked});
  };


//imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};


  handleprofilepicChange= async(e) =>{

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ profilepic: newFile});

    //this.setState({ profilepic: e.target.files[0]});
  } 


    // on form submit heree
    onFinish = values => {
      this.setState({datarequested:true})

      //create form data for updating staff status

      let form_data = new FormData();
      form_data.append('username', values.username);
      form_data.append('email', values.email,);
      form_data.append('password1', values.password);
      form_data.append('password2', values.confirm);
      form_data.append('is_active', this.state.isactive);
      form_data.append('managerial_account', this.state.ismanager);
      form_data.append('Ngo', ngoid);
    
      this.state.profilepic==null?
      console.log("No profile file")
      :
      form_data.append('profile_pic', this.state.profilepic, this.state.profilepic?this.state.profilepic.name:"");

      //Register the account first
          axios.post(serverconfig.backendserverurl+'/customqueries/createcompanyuserbycompany',form_data)
          .then(res =>{
            message.info(res.data.message)

            this.setState({datarequested:false})
            window.location.reload(false)

        })
      .catch(error => console.log(error))


    };



  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

        return (
          <Form
            ref={this.formRef}
            name="register"
            onFinish={this.onFinish}
          >
  
          <Form.Item
              label="User Name"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username"  defaultValue={this.props.username}  />
            </Form.Item>
  
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input type="mail" prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" defaultValue={this.props.email}  />
  
            </Form.Item>
      
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              <Input.Password 
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              />
  
  
            </Form.Item>
  
      
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
      
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
              <Input.Password 
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password" />
  
            </Form.Item>
  
            <Form.Item
              name="active"
              label="Active"
              rules={[
                {
                  required: false,
                  message: '',
                },
              ]}
            >
              <Checkbox onChange={this.onActiveCheckboxChange}></Checkbox>
              </Form.Item>
  
              <Form.Item
              name="ismanager"
              label="Is Managerial account"
              rules={[
                {
                  required: false,
                  message: '',
                },
              ]}
            >
              <Checkbox onChange={this.onManagerCheckboxChange}></Checkbox>
              </Form.Item>
  
            <Form.Item label="Profile Image"
              name="profilepic">
                <Input  type="file" accept="image/png, image/jpeg" placeholder="Profile Image" onChange={this.handleprofilepicChange}/>
            </Form.Item>  
  
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create Account
              </Button>
              </Form.Item>
            
          </Form>
        );

    }
   
  }

}

export default StaffUserSignUpForm;
