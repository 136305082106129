import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Card,Form,InputNumber,Modal,Result,Popover,Spin,Popconfirm,message,Tooltip,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DownloadOutlined,DeleteOutlined,CheckCircleFilled,DollarOutlined,RedoOutlined,FundViewOutlined,LoadingOutlined,MehFilled,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import TransactionCreateForm from '../components/Transactioncreateform'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

function callback(key) {
  console.log(key);
}

var token= ''
var ngoid= ''
var bizuserid= ''


class TransactionList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    transactions: [],
    companymanagers:[],
    datarequested:true,
    walletmodalvisible:false,
    selectedsaccooject:{},
    date:moment().format(dateFormat).toString(),
    saccodebitamount:0,
    mobilephone:'',
    userrights:{},
    datasubmittedsuccessfully:false,

  };


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("ngoid")){
    ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
  }else{
    ngoid= ''
  }

  if(localStorage.getItem("bizuserid")){
    bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
  }else{
      bizuserid= ''
  }
  

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+'/api/transactions/')
    .then(res => {
        this.setState({
          transactions:res.data
        })
        this.setState({datarequested:false})

    })


    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })

}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  render() {
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Amount',
        dataIndex: 'Amount',
        key: 'id',
        render: (text,record) =><p><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}/></p>
      },

      {
        title: 'Account to',
        dataIndex: 'accountnumber_to',
        key: 'id',
        ...this.getColumnSearchProps('accountnumber_to'),

      },
      {
        title: 'Transaction Id',
        dataIndex: 'transactionID',
        key: 'id',
        ...this.getColumnSearchProps('transactionID'),

      },
      {
        title: 'Ngo to',
        dataIndex: 'ngo_name',
        key: 'id',
        ...this.getColumnSearchProps('ngo_name'),
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'id',
        ...this.getColumnSearchProps('currency'),
      },
      {
        title: 'Batch Number',
        dataIndex: 'batch_number',
        key: 'id',
        ...this.getColumnSearchProps('batch_number'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'id',
        render: (text,record) =><p style={{color:text==="pending"?"orange":text==="success"?"green":"red"}}>{text}</p>
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to download proof of payment attachment</p>} title="Download">
        {
          record.proof_of_payment_attachment===null?
          "No file"
          :
          <a href={record.proof_of_payment_attachment}><DownloadOutlined style={{color:'green',fontSize: '30px'}}/></a> 
        }
        </Popover>
        &nbsp;&nbsp;&nbsp;
        {
        this.state.userrights.receive_ge_payments===true && record.status==="pending"?
        <Popover content={<p>Click here to acknowledge transaction</p>} title="Confirm">
          <Popconfirm title="Sure to confirm money received" onConfirm={() => {
              
               this.setState({datarequested:true})

                let form_data = new FormData();
                form_data.append('trans_action', record.id);
                form_data.append('userid', bizuserid);
              
                axios.post(serverconfig.backendserverurl+'/customqueries/confirm_gemoney_received', form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
              .then(res => {
                this.setState({datarequested:false})
                this.setState({datasubmittedsuccessfully:true})
              }        
            
              )
              .catch(error => console.log(error))

          }}>
          <CheckCircleFilled style={{color:'green',fontSize: '30px'}}/>
         </Popconfirm>
        
        </Popover>
        :
        null
      }
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully made system transaction acknowledgement"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="GARDEN EDEN TRANSACTIONS" key="1">
            <Table 
            columns={columns}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.transactions}
            scroll={{ x: 1000 }}
            bordered/>
            </Panel>
            </Collapse>

        </div>
    )
  
  
      }
  
  
  }

  }
}

export default TransactionList; 

