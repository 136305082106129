import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Descriptions,Result,InputNumber,Form,Input,message,Select,DatePicker,Progress,Image } from 'antd';
import { Row, Col,Collapse,Spin } from 'antd';
import ExpenseForm from '../components/ExpenseForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


function callback(key) {
    console.log(key);
  }

  var token= ''
  var sacco= ''
  var username= ''

class ExpenseReceiptAttachmentForm extends React.Component{

    state={
        expense: {},
        staffs:[],
        expensecategories:[],
        date:moment().format(dateFormat).toString(),  
        quantity:0,
        rate:0,
        amounttobepaid:0,
        balance:0,
        description:'',
        expensecategory:'',
        Particular:'',
        debitor:'',
        debitorphoneone:'',
        debitorphonetwo:'',
        datarequested:true,
        expensepayments:[],
        datasubmittedsuccessfully:false,

        attachment:null,
        attachment_url:'',
        percentage_uploaded:0,
        data_uploading:false,
    
    }

    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
      }else{
         sacco= ''
         username= ''
      }
    
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

        const expenseID=this.props.expenseID;
        axios.get(`${serverconfig.backendserverurl}/api/expenses/${expenseID}`)
        .then(res => {  
            this.setState({expense: res.data})

            this.setState({date: res.data.date})
            this.setState({quantity: res.data.quantity})
            this.setState({rate: res.data.rate})
            this.setState({description:res.data.details})
            this.setState({expensecategory:res.data.expense_category})
            this.setState({Particular:res.data.particular})
            this.setState({debitor:res.data.debitor})
            this.setState({debitorphoneone:res.data.RegisteredPhoneNumber1})
            this.setState({debitorphonetwo:res.data.RegisteredPhoneNumber2})

        })

        axios.get(serverconfig.backendserverurl+`/api/staffs/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              staffs:res.data
            })
        })
    
        axios.get(serverconfig.backendserverurl+`/api/expensecategories/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              expensecategories:res.data
            })

            this.setState({datarequested:false})

        })

        axios.get(serverconfig.backendserverurl+`/api/expensepayments/?sacco=${sacco}&expense=${expenseID}`)
        .then(res => {
            this.setState({
              expensepayments:res.data
            })
        })


    }



    //submit button pressed
  handleFormSubmit=(event) =>{

    //progress tracker
    const upload_config = {
      onUploadProgress: (progressEvent)=> {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        this.setState({percentage_uploaded:percentCompleted})
        
      }
    }


    const expenseID=this.props.expenseID;

    this.setState({data_uploading:true})

    let form_data_1 = new FormData();
    form_data_1.append('return_exp_id', expenseID);
    this.state.attachment==null?
    console.log("No Attachment file")
    :
    form_data_1.append('attachment', this.state.attachment, this.state.attachment?this.state.attachment.name:"");
    axios.post(serverconfig.backendserverurl+'/customqueries/expense_attachment', form_data_1,upload_config, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
   .then(res => {
      this.setState({data_uploading:false})
      this.setState({datasubmittedsuccessfully:true})

    })

  }


    render(){
      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        if (this.state.datasubmittedsuccessfully===true){
          return(
              <Card>
              <Result
              status="success"
              title="Successfully Updated expense "
              subTitle="Expenses are reported in the Income Statement"
              extra={[
                <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
              ]}
              />
              </Card>
          );
  
        }else{

          return(
            <div>
               

            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Open Expense receipt attachemnt Form" key="1">

            <Form 
              name="expense receipt attachment Form"
              onFinish={(event) => this.handleFormSubmit(event)}
              >
  
               
           <div style={{display:'flex',flexDirection:'row'}}>
            <FormItem label="Receipt image attachment" style={{marginRight:3}}>
                  <Input type="file" accept="image/png, image/jpeg" placeholder="Receipt attachment" 
                   onChange={ async(e) =>{
    
                    //this.setState({ Photo: e.target.files[0]});
                    this.setState({ attachment: e.target.files[0]});

                    
                    if(e.target.files[0]){
                      this.setState({
                        attachment_url: URL.createObjectURL(e.target.files[0])
                      })
                    }
                    
                    /*

                    const file = e.target.files[0];
                    //send to resizer
                    const image = await this.resizeFile(file);
                    const newFile = this.dataURIToBlob(image);
                    this.setState({ attachment: newFile});
                    console.log(newFile); */

                  } }/>
            </FormItem> 

              {this.state.attachment?
              <Image
              width={50}
              height={50}
              src={this.state.attachment_url}    
              />
              :
              null
              }
          </div>

                <FormItem>
                    <Button type="primary" htmlType="submit" >Upload attachment</Button>
                </FormItem>
               </Form>

               <br></br>
        {
            this.state.data_uploading===true?
            <div style={{display:'flex',alignSelf:'center',alignItems:'center',alignContent:'center',justifyContent:'center'}}>
            <Progress type="circle" percent={this.state.percentage_uploaded} />
            </div>            :
            null
          }
              
          </Panel>
          </Collapse>
      
            </div>
        )

        }

      }

    }
}

export default ExpenseReceiptAttachmentForm; 