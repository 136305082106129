import React from 'react';
import { Form, Input, Button,Select,InputNumber,DatePicker,message,Card,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var token= ''
var ngoid= ''
var bizuserid= ''


class ChildDevelopmentForm extends React.Component {

  state = {
    productName:'',  
    unit:'',
    unitprice:'',
    productDescription:'',
    productSerial:'',
    productImage:null,   
    paymentmode:'',
    date:moment().format(dateFormat).toString(),
    datarequested:false,

    children:[],
    schoolterms:[],
    selected_child:'',
    selected_term:'',
    school_fees_amount:0,
    other_requirements_amount:0,
    highlights_ofthemonth:'',
    main_interestsofthechild:'',
    wishes_ofthechild:'',
    challenges:'',
    complaints_abt_thechild:'',
    special_comments_or_resultsfromschool:'',
    comments_ofthechild_abt_ngo:'',
    comments_ofthechild_abt_school:'',
    comments_oftheguardian_abt_child:'',
    comments_oftheguardian_abt_ngoandschool:''

  }
  componentDidMount(){
    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        bizuserid= ''
    }

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/children/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          children:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/schoolterms/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          schoolterms:res.data
        })
    })

  }

  

  //submit button pressed
  onFinish=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('child',this.state.selected_child);
    form_data.append('highlights_ofthemonth',this.state.highlights_ofthemonth);
    form_data.append('main_interestsofthechild',this.state.main_interestsofthechild);
    form_data.append('wishes_ofthechild',this.state.wishes_ofthechild);
    form_data.append('challenges',this.state.challenges);
    form_data.append('complaints_abt_thechild',this.state.complaints_abt_thechild);
    form_data.append('special_comments_or_resultsfromschool',this.state.special_comments_or_resultsfromschool);
    form_data.append('comments_ofthechild_abt_ngo',this.state.comments_ofthechild_abt_ngo);
    form_data.append('comments_ofthechild_abt_school',this.state.comments_ofthechild_abt_school);
    form_data.append('comments_oftheguardian_abt_child',this.state.comments_oftheguardian_abt_child);
    form_data.append('comments_oftheguardian_abt_ngoandschool',this.state.comments_oftheguardian_abt_ngoandschool);

    form_data.append('Ngo', ngoid);
    form_data.append('user', bizuserid);

    ///make a post request now
      axios.post(serverconfig.backendserverurl+'/api/childdevelopmentreport/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>        this.setState({datarequested:false})
,    window.location.reload(false),message.info('Successfully added fees')
    )
    .catch(error => console.log(error))

    }

    handlepaymentmodeChange= (e) => {
       this.setState({paymentmode:e})
    }

    handledatechange= (date, dateString) => this.setState({ date: dateString});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
          <Card>
  
          <Form
                name="childform"
                onFinish={(event) => this.onFinish(event)}
            >
  
              <FormItem 
                    label="Child"
                    name="child"
                    rules={[
                      {
                        required: true,
                        message: 'Please select child',
                      },
                    ]}
                    >
                    <Select placeholder="Child"
                      style={{  }}
                      value={this.state.selected_child} 
                      onChange={(val)=>{this.setState({selected_child:val})
                    }} 
                    
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    
                    >
                        {this.state.children.map(
                          (child)=>(
                            <Option value={child.id}>{child.FullName} | {child.school_name}</Option>
                          ))}
                  </Select>     

              </FormItem>

                <FormItem label="Highlights of the month"
                  name="highlights_ofthemonth"
                  rules={[
                    {
                      required: true,
                      message: 'Please input highlights',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="Highlights of the month"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.highlights_ofthemonth}
                        onChange={(val)=>{this.setState({highlights_ofthemonth:val.target.value})}}
                      />
                  </FormItem>



                  <FormItem label="Main interests of the child"
                  name="main_interestsofthechild"
                  rules={[
                    {
                      required: true,
                      message: 'Please input interests',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="Main interests of the child"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.main_interestsofthechild}
                        onChange={(val)=>{this.setState({main_interestsofthechild:val.target.value})}}
                      />
                  </FormItem>

                  <FormItem label="Wishes of the child"
                  name="wishes_ofthechild"
                  rules={[
                    {
                      required: true,
                      message: 'Please input wishes',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="Wishes of the child"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.wishes_ofthechild}
                        onChange={(val)=>{this.setState({wishes_ofthechild:val.target.value})}}
                      />
                  </FormItem>

                  <FormItem label="Challenges"
                  name="challenges"
                  rules={[
                    {
                      required: true,
                      message: 'Please input challenges',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="Challenges"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.challenges}
                        onChange={(val)=>{this.setState({challenges:val.target.value})}}
                      />
                  </FormItem>

                  <FormItem label="Complaints about the child"
                  name="complaints_abt_thechild"
                  rules={[
                    {
                      required: true,
                      message: 'Please input complaints',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="Complaints about the child"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.complaints_abt_thechild}
                        onChange={(val)=>{this.setState({complaints_abt_thechild:val.target.value})}}
                      />
                  </FormItem>


                  <FormItem label="Special comments or results from school"
                  name="special_comments_or_resultsfromschool"
                  rules={[
                    {
                      required: true,
                      message: 'Please input comments',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="Special comments or results from school"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.special_comments_or_resultsfromschool}
                        onChange={(val)=>{this.setState({special_comments_or_resultsfromschool:val.target.value})}}
                      />
                  </FormItem>

                  <FormItem label="Comments of the child about Ngo"
                  name="comments_ofthechild_abt_ngo"
                  rules={[
                    {
                      required: true,
                      message: 'Please input comments',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="Comments of the child about Ngo"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.comments_ofthechild_abt_ngo}
                        onChange={(val)=>{this.setState({comments_ofthechild_abt_ngo:val.target.value})}}
                      />
                  </FormItem>

                  <FormItem label="Comments of the child about school"
                  name="comments_ofthechild_abt_school"
                  rules={[
                    {
                      required: true,
                      message: 'Please input comments',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="Comments of the child about school"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.comments_ofthechild_abt_school}
                        onChange={(val)=>{this.setState({comments_ofthechild_abt_school:val.target.value})}}
                      />
                  </FormItem>

                  <FormItem label="Comments of the guardian about child"
                  name="comments_oftheguardian_abt_child"
                  rules={[
                    {
                      required: true,
                      message: 'Please input comments',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="Comments of the guardian about child"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.comments_oftheguardian_abt_child}
                        onChange={(val)=>{this.setState({comments_oftheguardian_abt_child:val.target.value})}}
                      />
                  </FormItem>

                  <FormItem label="Comments of the guardian about ngo and school"
                  name="comments_oftheguardian_abt_ngoandschool"
                  rules={[
                    {
                      required: true,
                      message: 'Please input comments',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="Comments of the guardian about ngo and school"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.comments_oftheguardian_abt_ngoandschool}
                        onChange={(val)=>{this.setState({comments_oftheguardian_abt_ngoandschool:val.target.value})}}
                      />
                  </FormItem>

                  <h4 style={{color:'red'}}>Note: If submitted development report is not editable</h4>
                  
              <FormItem>
                <Button  type="primary" htmlType="submit">Submit Report</Button>
              </FormItem>
              </Form>
          </Card>
  
        </div>
      );
    }
    
  }
}

export default ChildDevelopmentForm;
