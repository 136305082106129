import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined,WarningFilled,CheckCircleFilled } from '@ant-design/icons';
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var ngoid= ''
var bizuserid= ''


class GuardianForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accounttypes:[],
    idtypes:[],
    sur_name:'' ,  
    middle_name:'',
    given_name:'',
    nationality:'',
    ID_type:'',
    ID_number:'',
    date_Of_Birth:'',
    Email_Address:'',
    P_O_Box:'',
    Area_Of_Residence:'',
    District:'',
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    next_of_kin:'',
    Next_Of_Kin_Physical_Address:'',
    relationship:'',
    Photo:null,
    id_photo:null,
    Thumbprint:null,

    Date_Of_Joining:'',
    account_type:'',
    date_of_appointment:'',
    status:'',
    staff_number:'',
    reference_one:'',
    Referee1PhoneNumber:'',
    datarequested:true,
    accountnumber:'',
    photofile:null,
    id_photofile:null,
    thumbprintfile:null,
    marital_status:'',
    own_residence:'',
    tribe:'',
    recommender:'',
    language1:'',
    language2:'',
    gender:'',
    any_other_account:'',
    other_account_number:'',
    other_bank:'',
    shares:0,
    title:'',
    datasubmittedsuccessfully:false,
    saccopackageobj:{},
    members:[],
    companyprofile:{},

    occupation:'',
    postaladdress:'',
    homecelllocaltion:'',
    cell:'',
    cellleader:'',
    nameofemployer_business:'',

    addressofemployer_business:'',
    refree_institutionalmember_name:'',
    refree_institutionalmember_contact:'',
    refree_cellleader_name:'',
    refree_cellleader_contact:'',
    next_of_kin_contact:'',

    child_account_school:'',
    child_account_school_class:'',

    membertype:'senior',
    pysical_form:null,
    saving_period:'',

    id_expirydate:'',
    ugdistricts:[],
    cellleader_contact:'',
    id_valid:false,
    age_valid:false,
    agetypeobject:{},
    idtypeobject:{},
    reapersrefobj:{},
    reapersselectedref:'',

    age_types:[],
    age_type:'',
    bankaccount:'',
    mobilemoney_contact:'',
    contract_file:false


  }

  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/idtypes/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          idtypes:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/ngos/${ngoid}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })

    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/ugdistricts/?Ngo=${ngoid}`)
    .then(res => {
      this.setState({
        ugdistricts:res.data
      })
    })

    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/age_types/?Ngo=${ngoid}`)
    .then(res => {
      this.setState({
        age_types:res.data
      })
        this.setState({datarequested:false})
    })

})

}


//imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};




  //submit button pressed
  handleFormSubmit=(event) =>{

      if (this.state.RegisteredPhoneNumber1===''){
        message.info("Phone Number missing")
      }else if(this.state.id_valid===false && this.state.membertype==="senior"){
        message.info("ID is invalid")
  
      }else if(this.state.age_valid===false){
        message.info("Age is invalid")
      }
      else{
        this.setState({datarequested:true})
  
        let form_data = new FormData();
        form_data.append('date', this.state.date);
        form_data.append('SurName', this.state.sur_name);
        form_data.append('MiddleName', this.state.middle_name);
        form_data.append('OtherName', this.state.given_name);
        form_data.append('Nationality', this.state.nationality);
        form_data.append('member_type', this.state.membertype);
  
        form_data.append('IDType', this.state.ID_type);
        form_data.append('IDNumber', this.state.ID_number);
          
        form_data.append('DateOfBirth', this.state.date_Of_Birth);
    
        form_data.append('EmailAddress', this.state.Email_Address);
        form_data.append('POBox', this.state.P_O_Box);
        form_data.append('AreaOfResidence', this.state.Area_Of_Residence);
        form_data.append('District', this.state.District);
        form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
        form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);
    
        form_data.append('next_of_kin', this.state.next_of_kin);
        form_data.append('NextOfKinPhysicalAddress', this.state.Next_Of_Kin_Physical_Address);
        form_data.append('relationship', this.state.relationship);
        form_data.append('DateOfJoining', this.state.Date_Of_Joining);
    
        form_data.append('accountType', this.state.account_type);
        form_data.append('gender', this.state.gender);
        form_data.append('marital_status', this.state.marital_status);
        form_data.append('title', this.state.title);
    
        form_data.append('own_residence', this.state.own_residence);
        form_data.append('tribe', this.state.tribe);
        form_data.append('recommender', this.state.recommender);
        form_data.append('how_did_you_know_sacco', '');
    
        form_data.append('language1', this.state.language1);
        form_data.append('language2', this.state.language2);
  
        form_data.append('Ngo', ngoid);
        form_data.append('user', bizuserid);
  
        form_data.append('next_of_kin_contact', this.state.next_of_kin_contact);
  
        form_data.append('occupation', this.state.occupation);
  
        form_data.append('nameofemployer_business', this.state.nameofemployer_business);
        form_data.append('addressofemployer_business', this.state.addressofemployer_business);
  
        form_data.append('id_expirydate', this.state.id_expirydate);
  
        this.state.Photo==null?
        console.log("No profile file")
        :
        form_data.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");
    
        this.state.id_photo==null?
        console.log("No id_photo file")
        :
        form_data.append('id_photo', this.state.id_photo, this.state.id_photo?this.state.id_photo.name:"");
    
        this.state.contract_file==null?
        console.log("No contract_file file")
        :
        form_data.append('contract_file', this.state.contract_file, this.state.contract_file?this.state.contract_file.name:"");
  
  
        form_data.append('guadian_child_Type', this.state.age_type);

        axios.post(serverconfig.backendserverurl+'/api/guardians/', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res =>{
          console.log(res.data)
          this.setState({datarequested:false})
          this.setState({datasubmittedsuccessfully:true})
          
        })
        .catch(error => console.log(error))
      }

  }

  handlesur_nameChange= (e) => this.setState({ sur_name: e.target.value});
  handlemiddle_nameChange= (e) => this.setState({ middle_name: e.target.value});
  handlegiven_nameChange= (e) => this.setState({ given_name: e.target.value});
  handlenationalityChange= (e) => this.setState({ nationality: e.target.value});
  handleID_typeChange= (e) => {
    this.setState({ ID_type: e});

    axios.get(serverconfig.backendserverurl+`/api/idtypes/${e}`)
    .then(res => {
        this.setState({
          idtypeobject:res.data
        })
    })
  }


  handletitleChange= (e) => this.setState({ title: e});

  handleID_numberChange= (e) => this.setState({ ID_number: e.target.value});
  handledate_Of_BirthChange= (date, dateString) =>{
    this.setState({ date_Of_Birth: dateString});
    this.checkagevalidity(dateString)
  } 

  handleEmail_AddressChange= (e) => this.setState({ Email_Address: e.target.value});
  handleP_O_BoxChange= (e) => this.setState({ P_O_Box: e.target.value});
  handleArea_Of_ResidenceChange= (e) => this.setState({ Area_Of_Residence: e.target.value});
  handleDistrictChange= (e) => this.setState({ District: e.target.value});
  handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});

  handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});
  handlenext_of_kinChange= (e) => this.setState({ next_of_kin: e.target.value});
  handleNext_Of_Kin_Physical_AddressChange= (e) => this.setState({ Next_Of_Kin_Physical_Address: e.target.value});
  handlerelationshipChange= (e) => this.setState({ relationship: e.target.value});
  handlePhotoChange= async(e) =>{
    //this.setState({ Photo: e.target.files[0]});

    if(e.target.files[0]){
      this.setState({
        photofile: URL.createObjectURL(e.target.files[0])
      })
    }

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ Photo: newFile});
    console.log(newFile);


  } 

  handleid_photoChange= async(e) =>{
    this.setState({ id_photo: e.target.files[0]});
    if(e.target.files[0]){
      this.setState({
        id_photofile: URL.createObjectURL(e.target.files[0])
      })
    }

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ id_photo: newFile});
    console.log(newFile);


  } 

  handleDate_Of_JoiningChange= (date, dateString) => this.setState({ Date_Of_Joining: dateString});
  
  handle_agetypeChange= (e) =>{

    axios.get(serverconfig.backendserverurl+`/api/age_types/${e}`)
    .then(res => {
        this.setState({
          agetypeobject:res.data
        })
    })

  
  }
  handledate_of_appointmentChange= (date, dateString) => this.setState({ date_of_appointment: dateString});

  handlestatusChange= (e) => this.setState({ status: e});
  handlemarital_statusChange= (e) => this.setState({ marital_status: e});
  handleown_residenceChange= (e) => this.setState({ own_residence: e});

  handlestaff_numberChange= (e) => this.setState({ staff_number: e.target.value});
  handlereference_oneChange= (e) => this.setState({ reference_one: e.target.value});
  handleReferee1PhoneNumberChange= (e) => this.setState({ Referee1PhoneNumber: e});

  handleaccountnumberChange= (e) => this.setState({ accountnumber: e.target.value});
  handletribeChange= (e) => this.setState({ tribe: e.target.value});
  handlerecommenderChange= (e) => this.setState({ recommender: e.target.value});
  handlelanguage1Change= (e) => this.setState({ language1: e.target.value});
  handlelanguage2Change= (e) => this.setState({ language2: e.target.value});
  handlegenderChange= (e) => this.setState({ gender: e});

  handleother_account_numberChange= (e) => this.setState({ other_account_number: e.target.value});
  handleany_other_accountChange= (e) => this.setState({ any_other_account: e});
  handleother_bankChange= (e) => this.setState({ other_bank: e.target.value});
  handlesharesChange= (e) => this.setState({ shares: e.target.value});


  //check agge validity
  checkagevalidity=(val)=>{
    var agelimit_months_cap=Number(this.state.agetypeobject.agelimit_months_cap)

      var dateMomentObjectone = moment(val, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dobobject = dateMomentObjectone.toDate();

      var datetoday=moment(this.state.date, "DD/MM/YYYY")

      var date1 = datetoday.toDate();
      var date2 = dobobject;

      var Difference_In_Time =  date1.getTime()-date2.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      console.log("diff days"+String(Difference_In_Days))

    if(Number(Difference_In_Days)>=Number(agelimit_months_cap)){
      this.setState({age_valid:true})
      console.log("Age is valid")
    }else{
      this.setState({age_valid:false})
      console.log("Age is not valid")
    }
  }

  //check agge validity
  checkidvalidity=(val)=>{
      var idvaliditydays=Number(this.state.idtypeobject.IDvaliditydays)

      var dateMomentObjectone = moment(val, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dobobject = dateMomentObjectone.toDate();

      var datetoday=moment(this.state.date, "DD/MM/YYYY")

      var date1 = datetoday.toDate();
      var date2 = dobobject;

      var Difference_In_Time =  date2.getTime()-date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      console.log("diff days"+String(Difference_In_Days))

    if(Number(Difference_In_Days)>=Number(idvaliditydays)){
      this.setState({id_valid:true})
      console.log("ID is valid")
    }else{
      this.setState({id_valid:false})
      console.log("ID is not valid")
    }
  }




  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Created New Guardian "
            subTitle="guardian details will be further reviewed if need be"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

            return (
              <div>
                <Form onFinish={(event) => this.handleFormSubmit(event)}>

                <FormItem label="Age type set"
                     name="guadian_child_Type"
                     rules={[
                       {
                         required: true,
                         message: 'Please select type set',
                       },
                     ]}           
                  >
                      <Select placeholder="Age Type Set" style={{  }} value={this.state.age_type} 
                      onChange={(val)=>{this.setState({age_type:val})
                     this.handle_agetypeChange(val)
                    }} >
                        {this.state.age_types.map(
                          (tye)=>(
                            <Option value={tye.id}>{tye.setting_type_name}</Option>
                          ))}
                      </Select>
                  </FormItem>


                <h3>Bio Data Section</h3>
                  <FormItem label="Sur Name"
                      name="surname"
                      rules={[
                        {
                          required: true,
                          message: 'Please input sur name',
                        },
                      ]}          
                  >
                    <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={this.handlesur_nameChange} />
                  </FormItem>
        
                  <FormItem label="Middle Name">
                    <Input  placeholder="Put a name here." value={this.state.middle_name} onChange={this.handlemiddle_nameChange} />
                  </FormItem>
        
                  <FormItem label="Given Name"
                      name="givenname"
                      rules={[
                        {
                          required: true,
                          message: 'Please input given name',
                        },
        
                      ]}           
                  >
                    <Input  placeholder="Put a name here." value={this.state.given_name} onChange={this.handlegiven_nameChange} />
                  </FormItem>
      
                  <FormItem label="Gender"
                     name="gender"
                     rules={[
                       {
                         required: true,
                         message: 'Please select',
                       },
                     ]}           
                  
                  >
                      <Select placeholder="Gender" style={{ width: 120 }} value={this.state.gender} onChange={this.handlegenderChange} >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                      </Select>
                  </FormItem>

                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Date of Birth"
                      name="dateofbirth"
                      rules={[
                        {
                          required: true,
                          message: 'Please select date of birth',
                        },
                      ]}   
                      style={{display:'flex',margin:3}}        
                  >
                        <DatePicker onChange={ this.handledate_Of_BirthChange} format={dateFormat} />
                  </FormItem>

                  {
                  this.state.age_valid===false?
                  <WarningFilled style={{ fontSize: 30,display:'flex',color:'red',alignSelf:'center' }}  />
                  :
                  <CheckCircleFilled style={{ fontSize: 30,display:'flex',color:'green',alignSelf:'center' }}  />
                  }

                  </div>
      
        
                  <FormItem label="Nationality"
                      name="nationality"
                      rules={[
                        {
                          required: true,
                          message: 'Please input nationality',
                        },
                      ]}            
                  >
                    <Input  placeholder="Nationality." value={this.state.nationality} onChange={this.handlenationalityChange} />
                  </FormItem>
        
        

                    <FormItem label="ID Type"
                    name="idtype"
                    rules={[
                      {
                        required: true,
                        message: 'Please select id type',
                      },
                    ]}           
                >
                    <Select placeholder="Id Type" style={{  }} value={this.state.ID_type} onChange={this.handleID_typeChange} >
                      {this.state.idtypes.map(
                        (type)=>(
                          <Option value={type.id}>{type.id_type_name}</Option>
                        ))}
                    </Select>
                </FormItem>
                  

                    <FormItem label="ID Number"
                    name="ID_number"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter ID Number',
                      },
                    ]}                   
                    
                    >
                    <Input  placeholder="ID Number." value={this.state.ID_number} onChange={this.handleID_numberChange} />
                    </FormItem>

                    <div>

                     <div style={{display:'flex',flexDirection:'row'}}>
                      <FormItem label="ID date of expiry"
                        name="id_expirydate"
                        rules={[
                          {
                            required: true,
                            message: 'Please select Id date of expiry ',
                          },
                        ]}                   
                        style={{display:'flex',margin:3}} 
                        >
                            <DatePicker onChange={(date, dateString)=>{
                              this.setState({id_expirydate:dateString})
                              this.checkidvalidity(dateString)

                            }} format={dateFormat} />
                        </FormItem>
                        {
                        this.state.id_valid===false?
                        <WarningFilled style={{ fontSize: 30,display:'flex',color:'red',alignSelf:'center' }} />
                        :
                        <CheckCircleFilled style={{ fontSize: 30,display:'flex',color:'green',alignSelf:'center' }} />
                       }
                    </div>
                 </div>


                  <FormItem label="Tribe">
                    <Input  placeholder="Tribe." value={this.state.tribe} onChange={this.handletribeChange} />
                  </FormItem>
      
                  <FormItem label="Language one">
                    <Input  placeholder="Language one." value={this.state.language1} onChange={this.handlelanguage1Change} />
                  </FormItem>
      
                  <FormItem label="Language two">
                    <Input  placeholder="Language two." value={this.state.language2} onChange={this.handlelanguage2Change} />
                  </FormItem>
      
                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Guardian Photo" style={{marginRight:3}}>
                        <Input type="file" accept="image/png, image/jpeg" placeholder="Guardian Photo"  onChange={this.handlePhotoChange}/>
                  </FormItem> 
      
                  {this.state.Photo?
                  <Image
                  width={50}
                  height={50}
                  src={this.state.photofile}    
                  />
                  :
                  null
                  }
      
                  </div>
      
                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Guardian National Id photo" style={{marginRight:3}}>
                      <Input type="file" accept="image/png, image/jpeg" placeholder="Guardian id photo"  onChange={this.handleid_photoChange}/>
                  </FormItem> 
                  {this.state.id_photo?
                    <Image
                    width={50}
                    height={50}
                    src={this.state.id_photofile}    
                    />
                    :
                    null
                    }
                  </div>


                  <h3>Employment Section</h3>
                  <FormItem label="Occupation"
                      name="coccupation"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter occupation',
                        },
                      ]}           
                  >
                    <Input  placeholder="Occupation." value={this.state.occupation} onChange={(val)=>{this.setState({occupation:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Name Of Employer/Business"
                      name="nameofemployer_business"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter name',
                        },
                      ]}           
                  >
                    <Input  placeholder="Name." value={this.state.nameofemployer_business} onChange={(val)=>{this.setState({nameofemployer_business:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Address of Employer/Business"
                      name="addressofemployer_business"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter address',
                        },
                      ]}           
                  >
                    <Input  placeholder="Address." value={this.state.addressofemployer_business} onChange={(val)=>{this.setState({addressofemployer_business:val.target.value})}} />
                  </FormItem>

        
                  <h3>Contact Section</h3>
                  <FormItem label="Email Address"
                      name="emailaddress"
                      rules={[
                        {
                          required: false,
                          message: 'Please input mail',
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                      ]}            
                  
                  >
                  <Input  type="mail" 
                          prefix={<MailOutlined className="site-form-item-icon" />} 
                          placeholder="Email"
                          value={this.state.Email_Address} 
                          onChange={this.handleEmail_AddressChange} />
                  </FormItem>
        
                  <FormItem label="Postal Address (P O Box)">
                    <Input placeholder="box number." value={this.state.P_O_Box} onChange={this.handleP_O_BoxChange} />
                  </FormItem>


                  <FormItem label="Residential (Physical) Address"
                      name="areaofresidence"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter residence',
                        },
                      ]}           
                  >
                    <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={this.handleArea_Of_ResidenceChange} />
                  </FormItem>
        
                  <FormItem label="District"
                     name="district"
                     rules={[
                       {
                         required: true,
                         message: 'Please enter district of residence',
                       },
                     ]}           
                  >
                    <Select placeholder="District" style={{  }} value={this.state.District} onChange={(val)=>{this.setState({District:val})}} >
                      {this.state.ugdistricts.map(
                        (dist)=>(
                          <Option value={dist.district_name}>{dist.district_name} | {dist.region}</Option>
                        ))}
                    </Select>
                  </FormItem>
        
                  <FormItem label= { <h6 style={{display:'flex'}} >* Phone One</h6>}                       
                  >
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber1} onChange={this.handleRegisteredPhoneNumber1Change}/>
                   </FormItem> 
        
                   <FormItem label="Phone Two">
                   <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber2} onChange={this.handleRegisteredPhoneNumber2Change}/>
                   </FormItem>

                  
                    <h3>Personal Status Section</h3>
                      <FormItem label="Title"
                        name="title"
                        rules={[
                          {
                            required: true,
                            message: 'Please select',
                          },
                        ]}           
                      
                      >
                          <Select placeholder="Title" style={{ width: 120 }} value={this.state.title} onChange={this.handletitleChange} >
                                <Option value="Mrs">Mrs</Option>
                                <Option value="Mr">Mr</Option>
                                <Option value="Miss">Miss</Option>
                          </Select>
                      </FormItem>
          
                      <FormItem label="Marital Status"
                        name="marital_status"
                        rules={[
                          {
                            required: true,
                            message: 'Please select',
                          },
                        ]}           
                      
                      >
                          <Select placeholder="Title" style={{ width: 120 }} value={this.state.marital_status} onChange={this.handlemarital_statusChange} >
                                <Option value="Married">Married</Option>
                                <Option value="Single">Single</Option>
                          </Select>
                      </FormItem>
          
                      <FormItem label="Own Residence?"
                        name="own_residence"
                        rules={[
                          {
                            required: true,
                            message: 'Please select',
                          },
                        ]}           
                      
                      >
                          <Select placeholder="Title" style={{ width: 120 }} value={this.state.own_residence} onChange={this.handleown_residenceChange} >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                          </Select>
                      </FormItem>

                   <h3>Next of Kin Section</h3>


                  <FormItem label="Next of kin">
                    <Input placeholder="Next of kin." value={this.state.next_of_kin} onChange={this.handlenext_of_kinChange} />
                  </FormItem>

                  <FormItem label="Next Of Kin Contact">
                   <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="next of kin contact"
                        value={this.state.RegisteredPhoneNumber2} onChange={(val)=>{this.setState({next_of_kin_contact:val})}}/>
                   </FormItem>

        
                  <FormItem label="Next of kin Address">
                    <Input placeholder="Next of Kin Address." value={this.state.Next_Of_Kin_Physical_Address} onChange={this.handleNext_Of_Kin_Physical_AddressChange} />
                  </FormItem>
        
                  <FormItem label="Next Of Kin Relationship">
                    <Input placeholder="Next of kin Relationship." value={this.state.relationship} onChange={this.handlerelationshipChange} />
                  </FormItem>
        

                  <FormItem label="Date of Joining"
                    name="dateofjoining"
                    rules={[
                      {
                        required: true,
                        message: 'Please select',
                      },
                    ]}  
                  >
                        <DatePicker onChange={this.handleDate_Of_JoiningChange} format={dateFormat}/>
                  </FormItem>

                  <FormItem label="Contract attachment">
                  <Input type="file" accept=".pdf" placeholder="Contract attachment" 
                   onChange={(e)=>{
                    var kilbyes=e.target.files[0].size/1024
                    if (kilbyes>Number(1024)){
                      message.error("File is greater than "+"1024"+" Kbs or 1 mb .It will not be uploaded.")
                    }else{
                      this.setState({contract_file:e.target.files[0]})
                    }
                  }}/>
                  </FormItem>

                  <FormItem >
                    <Button  type='primary' htmlType="submit">Submit</Button>
                  </FormItem>
      
                </Form>
              </div>
            );

      }

    }
    
  }

}

export default GuardianForm;



