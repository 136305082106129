import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin,Select} from 'antd';
import IDTypeForm from '../components/IDTypeForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';

import PhoneInput from 'react-phone-number-input'

  var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

var token= ''
var ngoid= ''


class SchoolDetail extends React.Component{

    state={
      school_name:'',  
      licence:'',
      company_email:'',
      school_logo:null,   
      RegisteredPhoneNumber1:'',
      box_number:'',
      accountslimit:0,
      branchname:'',
      RegisteredPhoneNumber2:'',
      RegisteredPhoneNumber3:'',
      website:'',
      address:'',
      datarequested:true,  
      safebalance:0,
      sharevalue:0,
      retainedearnings:0,
      packages:[],
      package:'',
      mobilemoneygatewaycode:'',
      mobilemoney_contact:'',
      bankaccount:'',
      school_type:'',
      schoolobj:{}

    }

    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        if(localStorage.getItem("ngoid")){
          ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        }else{
          ngoid= ''
        }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        const schoolID=this.props.match.params.schoolID;
        axios.get(`${serverconfig.backendserverurl}/api/schools/${schoolID}`)
        .then(res => {  
            this.setState({
              schoolobj: res.data
            })

            this.setState({school_name:res.data.school_name})
            this.setState({school_type:res.data.school_type})
            //this.setState({school_logo:res.data.school_logo})

            this.setState({company_email:res.data.company_email})
            this.setState({RegisteredPhoneNumber1:res.data.RegisteredPhoneNumber1})
            this.setState({RegisteredPhoneNumber2:res.data.RegisteredPhoneNumber2})
            this.setState({RegisteredPhoneNumber3:res.data.RegisteredPhoneNumber3})
            this.setState({box_number:res.data.box_number})
            this.setState({address:res.data.address})
            this.setState({website:res.data.website})
            this.setState({mobilemoney_contact:res.data.mobilemoney_contact})
            this.setState({bankaccount:res.data.bankaccount})
          
            this.setState({datarequested:false})
        })
    }



    //submit button pressed
  handleFormSubmit=() =>{
    const schoolID=this.props.match.params.schoolID;

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('school_name', this.state.school_name);
    form_data.append('school_type', this.state.school_type);
    form_data.append('company_email', this.state.company_email);
    form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
    form_data.append('box_number', this.state.box_number);

    this.state.school_logo==null?
    console.log("No profile file")
    :
    form_data.append('school_logo', this.state.school_logo,this.state.school_logo?this.state.school_logo.name:"");
    
    form_data.append('website',this.state.website);
    form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);
    form_data.append('RegisteredPhoneNumber3', this.state.RegisteredPhoneNumber3);

    form_data.append('bankaccount', this.state.bankaccount);
    form_data.append('mobilemoney_contact', this.state.mobilemoney_contact);
    form_data.append('address', this.state.address);

    axios.put(serverconfig.backendserverurl+`/api/schools/${schoolID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res => this.setState({datarequested:false})
    , window.location.reload(false),
    message.info('Successfully updated school')
    )
    .catch(error => console.log(error))

  }

    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
        return(
            <div>
         <Form
          onFinish={(event) => this.handleFormSubmit(event)}
          >

        <FormItem label="Institutional name"          
              
              >
                  <Select placeholder="Institutional type" style={{ width: 120 }} value={this.state.school_type} onChange={(val)=>{this.setState({school_type:val})}} >
                        <Option value="kindergarten">Kindergarten</Option>
                        <Option value="primary">Primary</Option>
                        <Option value="secondary">Secondary</Option>
                        <Option value="university">University</Option>
                        <Option value="college">College</Option>

                  </Select>
              </FormItem>

            <FormItem label="School Name"
              >
              <Input name="ngo_name"  placeholder="Put a name here." value={this.state.school_name} onChange={(val)=>{this.setState({school_name:val.target.value})}} />
            </FormItem>
  
  
            <FormItem label="School Logo">
              <Input name="school_logo" type="file" accept="image/png, image/jpeg" placeholder="NGO logo"  onChange={(val)=>{this.setState({ school_logo: val.target.files[0]})}}/>
            </FormItem>  
            
            <FormItem label="School Email"
            >
              <Input name="company_email" type="email"  placeholder="company@email.com" value={this.state.company_email} onChange={(val)=>{this.setState({company_email:val.target.value})}} />
            </FormItem> 
  
            <FormItem label="Phone Number">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.RegisteredPhoneNumber1} onChange={(val)=>{this.setState({RegisteredPhoneNumber1:val})}}/>
             </FormItem>
             <FormItem label="Phone Number Two">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.RegisteredPhoneNumber2} onChange={(val)=>{this.setState({RegisteredPhoneNumber2:val})}}/>
             </FormItem> 
  
             <FormItem label="Phone Number Three">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.RegisteredPhoneNumber3} onChange={(val)=>{this.setState({RegisteredPhoneNumber3:val})}}/>
             </FormItem>  
             <FormItem label="Website"

             >
              <Input  name="website"  placeholder="Webiste here" value={this.state.website} onChange={(val)=>{this.setState({website:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Box number">
              <Input name="box_number"  placeholder="Box number here" value={this.state.box_number} onChange={(val)=>{this.setState({box_number:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Address"            
            >
              <Input name="address"  placeholder="Address here" value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
            </FormItem>

          
            <FormItem label="Mobile Money Receiver Contact">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.mobilemoney_contact} onChange={(val)=>{this.setState({mobilemoney_contact:val})}}/>
             </FormItem>

            <FormItem label="Receiving bank Account">
            <Input name="bankaccount"  placeholder="bank account" value={this.state.bankaccount} onChange={(val)=>{this.setState({bankaccount:val.target.value})}} />
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit" >Update details</Button>
            </FormItem>
          </Form>
              </div>
            )

          }
    }
}

export default SchoolDetail; 