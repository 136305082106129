import React from 'react';
import axios from 'axios';
import { Card,Avatar} from 'antd';
import { Form, Input, Button,Select,Spin,Switch } from 'antd';
import {
    Col,
    Row,
  } from 'reactstrap';
import AssetForm from '../components/AssetForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class LoanTypeDetail extends React.Component{

    state={
        loantype: {},
        loan_type_name:'',
        loan_form_fees:0,
        loan_assessment_fees:0,
        loan_insurance_fee:0,
        loan_commitment_fee:0,
        loan_agreement_fees:0,
        reserve_fees:0,
        interest_rate:0,
        maximum_loan_amount:0,
        maximum_loan_amount_first_time_borrower:0,
        maximum_period:0,
        maximum_period_first_time_borrower:0,
        percentage_payment_of_currentloan_before_top_up:0,
        generate_dailyschedule:false
    }
    


    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

        const loanTypeID=this.props.match.params.loanTypeID;
        axios.get(`${serverconfig.backendserverurl}/api/loantypes/${loanTypeID}`)
        .then(res => {  
            this.setState({loantype: res.data})

            this.setState({loan_type_name: res.data.loan_type_name})
            this.setState({loan_form_fees: res.data.loan_form_fees})
            this.setState({loan_assessment_fees: res.data.loan_assessment_fees})
            this.setState({loan_insurance_fee: res.data.loan_insurance_fee})
            this.setState({loan_commitment_fee: res.data.loan_commitment_fee})
            this.setState({loan_agreement_fees: res.data.loan_agreement_fees})
            this.setState({reserve_fees: res.data.reserve_fees})
            this.setState({interest_rate: res.data.interest_rate})

            this.setState({maximum_loan_amount: res.data.maximum_loan_amount})
            this.setState({maximum_loan_amount_first_time_borrower: res.data.maximum_loan_amount_first_time_borrower})
            this.setState({maximum_period: res.data.maximum_period})
            this.setState({maximum_period_first_time_borrower: res.data.maximum_period_first_time_borrower})
            this.setState({percentage_payment_of_currentloan_before_top_up: res.data.percentage_payment_of_currentloan_before_top_up})

            this.setState({generate_dailyschedule: res.data.generate_dailyschedule})

            
            this.setState({datarequested:false})

        })
    }

    //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    const loanTypeID=this.props.match.params.loanTypeID;
    let form_data = new FormData();

    form_data.append('loan_type_name', this.state.loan_type_name);
    form_data.append('loan_form_fees', this.state.loan_form_fees);
    form_data.append('loan_assessment_fees', this.state.loan_assessment_fees);
    form_data.append('loan_insurance_fee', this.state.loan_insurance_fee);
    form_data.append('loan_commitment_fee', this.state.loan_commitment_fee);
    form_data.append('loan_agreement_fees', this.state.loan_agreement_fees);
    form_data.append('reserve_fees', this.state.reserve_fees);
    form_data.append('interest_rate', this.state.interest_rate);
    form_data.append('maximum_loan_amount', this.state.maximum_loan_amount);
    form_data.append('maximum_loan_amount_first_time_borrower', this.state.maximum_loan_amount_first_time_borrower);
    form_data.append('maximum_period', this.state.maximum_period);
    form_data.append('maximum_period_first_time_borrower', this.state.maximum_period_first_time_borrower);
    form_data.append('percentage_payment_of_currentloan_before_top_up', this.state.percentage_payment_of_currentloan_before_top_up);
    form_data.append('generate_dailyschedule', this.state.generate_dailyschedule);

    axios.put(serverconfig.backendserverurl+`/api/loantypes/${loanTypeID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>this.setState({datarequested:false}),window.location.reload(false)

  )
  .catch(error => console.log(error))

  }


  handleloan_type_nameChange= (e) => this.setState({ loan_type_name: e.target.value});
  handleloan_form_feesChange= (e) => this.setState({ loan_form_fees: e.target.value});
  handleloan_assessment_feesChange= (e) => this.setState({ loan_assessment_fees: e.target.value});
  handleloan_insurance_feeChange= (e) => this.setState({ loan_insurance_fee: e.target.value});
  handleloan_commitment_feeChange= (e) => this.setState({ loan_commitment_fee: e.target.value});
  handleloan_agreement_feesChange= (e) => this.setState({ loan_agreement_fees: e.target.value});
  handlereserve_feesChange= (e) => this.setState({ reserve_fees: e.target.value});
  handleinterest_rateChange= (e) => this.setState({ interest_rate: e.target.value});
  handlemaximum_loan_amountChange= (e) => this.setState({ maximum_loan_amount: e.target.value});
  handlemaximum_loan_amount_first_time_borrowerChange= (e) => this.setState({ maximum_loan_amount_first_time_borrower: e.target.value});
  handlemaximum_periodChange= (e) => this.setState({ maximum_period: e.target.value});
  handlemaximum_period_first_time_borrowerChange= (e) => this.setState({ maximum_period_first_time_borrower: e.target.value});
  handlepercentage_payment_of_currentloan_before_top_upChange= (e) => this.setState({ percentage_payment_of_currentloan_before_top_up: e.target.value});


    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{

            return(
                <div>
        
        <Row>
        <Col xs="12" sm="6" lg="6">

        <Card 
          style={{padding:10}}
          hoverable>
            <h4>Account Type Details / Update</h4>
            <Form
                onFinish={(event) => this.handleFormSubmit(event)} 
                >
            <FormItem label="Loan Type Name">
              <Input name="loan_type_name"  placeholder="Put a name here." value={this.state.loan_type_name} onChange={this.handleloan_type_nameChange} />
            </FormItem>

            <FormItem label="Loan Form Fees">
              <Input name="loan_form_fees"  placeholder="Block Figure eg 1000" value={this.state.loan_form_fees} onChange={this.handleloan_form_feesChange} />
            </FormItem>

  
            <FormItem label="Loan Assessment Fees">
              <Input name="loan_assessment_fees"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.loan_assessment_fees} onChange={this.handleloan_assessment_feesChange} />
            </FormItem>
  
            <FormItem label="Loan Insurance Fees">
              <Input name="loan_insurance_fee"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.loan_insurance_fee} onChange={this.handleloan_insurance_feeChange} />
            </FormItem>


            <FormItem label="Loan Commitment Fee">
              <Input name="loan_commitment_fee"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.loan_commitment_fee} onChange={this.handleloan_commitment_feeChange} />
            </FormItem>


            <FormItem label="Loan Agreement Fees">
              <Input name="loan_agreement_fees"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.loan_agreement_fees} onChange={this.handleloan_agreement_feesChange} />
            </FormItem>

            <FormItem label="Loan Reserve Fee">
              <Input name="reserve_fees"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.reserve_fees} onChange={this.handlereserve_feesChange} />
            </FormItem>

            <FormItem label="Interest Rate">
              <Input name="interest_rate"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.interest_rate} onChange={this.handleinterest_rateChange} />
            </FormItem>

            <FormItem label="Maximum Loan Amount">
              <Input name="maximum_loan_amount"  placeholder="0.00" value={this.state.maximum_loan_amount} onChange={this.handlemaximum_loan_amountChange} />
            </FormItem>

            <FormItem label="Maximum Loan Amount (First Time Borrower)">
              <Input name="maximum_loan_amount_first_time_borrower"  placeholder="0.00" value={this.state.maximum_loan_amount_first_time_borrower} onChange={this.handlemaximum_loan_amount_first_time_borrowerChange} />
            </FormItem>


            <FormItem label="Maximum Loan Period">
              <Input name="maximum_period"  placeholder="0" value={this.state.maximum_period} onChange={this.handlemaximum_periodChange} />
            </FormItem>

            <FormItem label="Maximum Loan Period (First Time Borrower)">
              <Input name="maximum_period_first_time_borrower"  placeholder="0.00" value={this.state.maximum_period_first_time_borrower} onChange={this.handlemaximum_period_first_time_borrowerChange} />
            </FormItem>


            <FormItem label="Percentage Payment Before Top App">
              <Input name="percentage_payment_of_currentloan_before_top_up"  placeholder="Enter converted value eg 0.5 for 50%" value={this.state.percentage_payment_of_currentloan_before_top_up} onChange={this.handlepercentage_payment_of_currentloan_before_top_upChange} />
            </FormItem>

            <FormItem name="generate_dailyschedule" label="Generate Daily Schedule" >
                <Switch
                    checked={this.state.generate_dailyschedule}
                    onChange={val=>{this.setState({generate_dailyschedule:!this.state.generate_dailyschedule})}}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    />
            </FormItem>  

            <FormItem>
                <Button  type="primary" htmlType="submit">Update</Button>
            </FormItem>
              </Form>
              
            </Card>
          
           </Col>
      
           </Row>
    
          </div>
            )

          }
        
    }
}

export default LoanTypeDetail; 