import React from 'react';
import axios from 'axios'
import { Table,Select,Typography, Input, Button,Collapse,Form,InputNumber,Modal,Popover,Spin,Popconfirm,message,Tooltip,Switch,DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FilePdfFilled,DeleteOutlined,DollarOutlined,RedoOutlined,FundViewOutlined,LoadingOutlined,MehFilled,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../views/serverconn'
import TransactionCreateForm from '../components/Transactioncreateform'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

const { Option } = Select;
const { Title } = Typography;
const { RangePicker } = DatePicker;

function callback(key) {
  console.log(key);
}

var token= ''
var ngoid= ''


class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/ngos/${ngoid}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })


  }

  render() {
    return (
      <div style={{padding:20}}>
<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.ngo_logo} />
              
              </Col>
              <Col xs="6" sm="6" lg="6">
              <h3>{this.state.companyprofile.ngo_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
              
                </Col>

     </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>GUARDIANS REPORT FOR {this.props.selectedngoname}</h3>

             <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>Full name</th>
                        <th>Date of birth</th>
                        <th>Gender</th>
                        <th>Contact</th>
                        <th>No of children</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.props.sheetitems.map(
                      (item)=>(
                        <tr>
                        <td>{item.fullname}</td>
                        <td>{item.DateOfBirth}</td>
                        <td>{item.gender}</td>
                        <td>{item.contact}</td>
                        <td>{item.noofchildren}</td>

                        </tr>
                      ))}
                      <tr>
                      <td style={{fontWeight:'bolder'}}>Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{fontWeight:'bolder'}}>{this.props.sheetitems.length}</td>
                      </tr>

                  </tbody>
              </reactstrp.Table> 
      </div>
    );
  }
}



class GuardiansReport extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    guardians: [],
    companymanagers:[],
    datarequested:true,
    walletmodalvisible:false,
    selectedsaccooject:{},
    date:moment().format(dateFormat).toString(),
    saccodebitamount:0,
    mobilephone:'',

    dateone:'',
    datetwo:'',
    selectedngo:'',
    selectedngoname:'',
    ngos: [],

  };


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

   if(localStorage.getItem("ngoid")){
    ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
  }else{
    ngoid= ''
  }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/ngos/?ngo_supervisor_id=${ngoid}`)
    .then(res => {
        this.setState({
          ngos:res.data
        })
        this.setState({datarequested:false})
    })


}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };



  searchSales=()=>{

    let form_data = new FormData();
    form_data.append('selectedngo', this.state.selectedngo);

    if(this.state.selectedngo===''){
      alert("Ngo missing ")
    }else{
        this.setState({datarequested:true})
        this.setState({guardians:[]})

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/get_ngoguardians', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res =>{
          this.setState({datarequested:false})
          this.setState({guardians:JSON.parse(res.data.report)})
        } 
        )
        .catch(error => console.log(error))   
    }

    }



  render() {
    const columns = [
      
      {
        title: 'Fullname',
        dataIndex: 'fullname',
        key: 'id',
        ...this.getColumnSearchProps('fullname'),
      },

      {
        title: 'Date of birth',
        dataIndex: 'DateOfBirth',
        key: 'id',
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
        key: 'id',
        ...this.getColumnSearchProps('gender'),
      },
      {
        title: 'Contact',
        dataIndex: 'contact',
        key: 'id',
        ...this.getColumnSearchProps('ngoto_name'),
      },
      {
        title: 'No of children',
        dataIndex: 'noofchildren',
        key: 'id',
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/guardians/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to view children</p>} title="View">
        <Link to={`/guardianchildren/${text}`}><FundViewOutlined style={{color:'green'}}/></Link>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      return(
        <div>

            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              selectedngoname={this.state.selectedngoname}
              sheetitems={this.state.guardians}
              ref={el => (this.componentRef = el)} /> 
            </div>


            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="GUARDIANS REPORT" key="1">
            <Form  layout="inline" >

            <FormItem label="Ngo">
                     <Select 
                     placeholder="select Ngo"
                     style={{ width: 200 }} 
                     value={this.state.selectedngo} 
                     onChange={(val)=>{
                      this.setState({selectedngo:val})
                    
                      axios.get(`${serverconfig.backendserverurl}/api/ngos/${val}`)
                      .then(res => {  
                          this.setState({selectedngoname:res.data.ngo_name})
                      })

                    }}

                     showSearch
                     optionFilterProp="guardians"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}
                     filterOption={(input, option) =>
                       option.guardians.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     }                     
                      >
                        {
                          this.state.ngos.map((item)=>{
                            return (<Option value={item.id}>{item.ngo_name}</Option>);
                          })
                        }
                        
                     </Select>
                 </FormItem>


              <FormItem>
              <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
              </FormItem> 

             </Form>          
            
            <Table 
            columns={columns}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.guardians}
            scroll={{ x: 1000 }}
            bordered/>

          <br></br>
          <h3>Total: {this.state.guardians.length}</h3>

            </Panel>
            </Collapse>
            <br />

            <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
              <ReactToPrint
                trigger={() => 
                <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                Print
                </Button>
                }
                content={() => this.componentRef}
                />
            </div>
        </div>
    )}

  }
}

export default GuardiansReport; 

