import React from 'react';
import ActivateMemberForm from '../components/ActivateMemberForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Typography,Space,Divider,Popover,Popconfirm,message,Descriptions,Modal,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,PrinterOutlined,FundViewOutlined,EditOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import ExpensePaymentForm from '../components/ExpensePaymentForm'
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Title } = Typography;

function callback(key) {
  console.log(key);
}

var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })


  }

  render() {
    return (
      <div style={{padding:20}}>
<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.sacco_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
                <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
              
                </Col>

     </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>LOAN RESERVE REPORT</h3>

           <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Account Name</th>
                          <th>Reserve Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.reserves.map(
                        (item)=>(
                          <tr>
                          <td>{item.accountnumber}</td>
                          <td>{item.accountname}</td>
                          <td>{<CurrencyFormat value={item.reserveAmount} displayType={'text'} thousandSeparator={true}/>}</td>
                     
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalreserve} displayType={'text'} thousandSeparator={true}/>}</td>
                        
                        </tr>
                    </tbody>
                </reactstrp.Table>  

      </div>
    );
  }
}


class MemberReserveReport extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    reserves: [],
    viewpaymentmodal:false,
    expensedetails:{},
    companyprofile:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    totalreserve:0,


  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/loanreserve/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          reserves:res.data
        })

        const total = res.data.reduce((Total, item) => Total + item.reserveAmount, 0);
        this.setState({totalreserve:total})
        this.setState({datarequested:false})
    })

}


  render() {
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              reserves={this.state.reserves}
              totalreserve={this.state.totalreserve}
              ref={el => (this.componentRef = el)} /> 
             </div>


            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}> LOAN RESERVE REPORT</h4>} key="1">
           
               <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Account Name</th>
                          <th>Reserve Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.reserves.map(
                        (item)=>(
                          <tr>
                          <td>{item.accountnumber}</td>
                          <td>{item.accountname}</td>
                          <td>{<CurrencyFormat value={item.reserveAmount} displayType={'text'} thousandSeparator={true}/>}</td>
                     
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalreserve} displayType={'text'} thousandSeparator={true}/>}</td>
                        
                        </tr>
                    </tbody>
                </reactstrp.Table>              
           
                <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
              </div>

            </Panel>
            </Collapse>
        </div>
    )

    }


  }
}

export default MemberReserveReport; 
