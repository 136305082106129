import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Descriptions,Result,InputNumber,Form,Input,message,Select,DatePicker } from 'antd';
import { Row, Col,Collapse,Spin } from 'antd';
import ExpenseForm from '../components/ExpenseForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import ExpenseReceiptAttachmentForm from './ExpenseReceiptAttachmentform'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


function callback(key) {
    console.log(key);
  }

  var token= ''
  var sacco= ''
  var username= ''

class ExpenseDetail extends React.Component{

    state={
        expense: {},
        staffs:[],
        expensecategories:[],
        date:moment().format(dateFormat).toString(),  
        quantity:0,
        rate:0,
        amounttobepaid:0,
        balance:0,
        description:'',
        expensecategory:'',
        Particular:'',
        debitor:'',
        debitorphoneone:'',
        debitorphonetwo:'',
        datarequested:true,
        expensepayments:[],
        datasubmittedsuccessfully:false,

    }

    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
      }else{
         sacco= ''
         username= ''
      }
    
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

        const expenseID=this.props.match.params.expenseID;
        axios.get(`${serverconfig.backendserverurl}/api/expenses/${expenseID}`)
        .then(res => {  
            this.setState({expense: res.data})

            this.setState({date: res.data.date})
            this.setState({quantity: res.data.quantity})
            this.setState({rate: res.data.rate})
            this.setState({description:res.data.details})
            this.setState({expensecategory:res.data.expense_category})
            this.setState({Particular:res.data.particular})
            this.setState({debitor:res.data.debitor})
            this.setState({debitorphoneone:res.data.RegisteredPhoneNumber1})
            this.setState({debitorphonetwo:res.data.RegisteredPhoneNumber2})

        })

        axios.get(serverconfig.backendserverurl+`/api/staffs/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              staffs:res.data
            })
        })
    
        axios.get(serverconfig.backendserverurl+`/api/expensecategories/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              expensecategories:res.data
            })

            this.setState({datarequested:false})

        })

        axios.get(serverconfig.backendserverurl+`/api/expensepayments/?sacco=${sacco}&expense=${expenseID}`)
        .then(res => {
            this.setState({
              expensepayments:res.data
            })
        })


    }


    handledatechange= (date, dateString) => this.setState({ date: dateString});
    handlequantityChange= (e) => this.setState({ quantity: e.target.value});
    handlerateChange= (e) => this.setState({ rate: e.target.value});
  
    handleamounttobepaidChange= (e) =>{
      this.setState({ amounttobepaid: e.target.value});
    } 
    handledescriptionChange= (e) => this.setState({ description: e.target.value});
  
    handleexpensecategoryChange= (e) => this.setState({ expensecategory: e});
    handleParticularChange= (e) => this.setState({ Particular: e.target.value});
    handledebitorChange= (e) => this.setState({ debitor: e.target.value});
  
    handledebitorphoneoneChange= (e) => this.setState({ debitorphoneone: e});
    handledebitorphonetwoChange= (e) => this.setState({ debitorphonetwo: e});


    //calculate expense payments total
    calculateExpensepaymentsTotal=()=>{
      var tot=0
      this.state.expensepayments.map(
        (item)=>{
          tot+=Number(item.amount)
        })
  
      return tot;

    }
  
    getTotalPayment=()=>{
      var total=0;
      total=Number(this.state.rate)*Number(this.state.quantity)
      return total;
    }


    //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})
    const expenseID=this.props.match.params.expenseID;

    console.log(this.state.issuestaff)

    let form_data = new FormData();
    form_data.append('expenseID', expenseID);
    form_data.append('expense_category', this.state.expensecategory);
    form_data.append('expense_item', this.state.Particular);
    form_data.append('particular', this.state.Particular);
    form_data.append('rate', this.state.rate);
    form_data.append('quantity', this.state.quantity);
    form_data.append('total_to_be_paid', this.getTotalPayment());
    form_data.append('details', this.state.description);
    form_data.append('user', username);
    
    axios.post(serverconfig.backendserverurl+'/customqueries/updatexpense', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>{
    this.setState({datarequested:false})
    message.info(res.data.message)
    this.setState({datasubmittedsuccessfully:true})
  } 
  )
  .catch(error => console.log(error))

  }


    render(){
      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        if (this.state.datasubmittedsuccessfully===true){
          return(
              <Card>
              <Result
              status="success"
              title="Successfully Updated expense "
              subTitle="Expenses are reported in the Income Statement"
              extra={[
                <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
              ]}
              />
              </Card>
          );
  
        }else{

          return(
            <div>
                <Row>
                <Card title={this.state.expense.expense_item}>
                    <Descriptions title="">
                    <Descriptions.Item label="Date">{this.state.expense.date}</Descriptions.Item>
                    <Descriptions.Item label="Category">{this.state.expense.expensecategoryname}</Descriptions.Item>
                    <Descriptions.Item label="Particular">{this.state.expense.particular}</Descriptions.Item>
                    <Descriptions.Item label="Expense to type">{this.state.expense.expenseto_type}</Descriptions.Item>
                    <Descriptions.Item label="Expense to">{this.state.expense.expenseto_name}</Descriptions.Item>
                    <Descriptions.Item label="Expense to contact">{this.state.expense.expenseto_contact}</Descriptions.Item>
                    <Descriptions.Item label="Rate"><CurrencyFormat value={this.state.expense.rate} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></Descriptions.Item>
                    <Descriptions.Item label="Quantity">{this.state.expense.quantity}</Descriptions.Item>
                    <Descriptions.Item label="Total Amount"><CurrencyFormat value={this.state.expense.total_to_be_paid} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></Descriptions.Item>
                    <Descriptions.Item label="Amount Paid"><CurrencyFormat value={this.calculateExpensepaymentsTotal()} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></Descriptions.Item>
                    <Descriptions.Item label="Pending Balance"><CurrencyFormat value={Number(this.state.expense.total_to_be_paid)-this.calculateExpensepaymentsTotal()} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></Descriptions.Item>
                    </Descriptions>
                </Card>
  
                </Row>
                <br/>
                < ExpenseReceiptAttachmentForm  expenseID={this.props.match.params.expenseID}/>
                <br/>
  
                <Collapse defaultActiveKey={['0']} onChange={callback}>
                <Panel header="Open Update Form" key="1">
                <h4>Expense Update</h4>
  
                <Form 
                  name="expense Form"
                  onFinish={(event) => this.handleFormSubmit(event)}
                  >
  
                
                <FormItem label="Expense Category"
  
                            >
                                <Select placeholder="Expense Category" style={{ width: 120 }} value={this.state.expensecategory} onChange={this.handleexpensecategoryChange} >
                                {this.state.expensecategories.map(
                                    (ctgry)=>(
                                    <Option value={ctgry.id}>{ctgry.category_name}</Option>
                                    ))}
                                </Select>
                            </FormItem>
                    
                    
                    <FormItem label="Expense Particular"
                              
                            >
                            <Input  placeholder="Particular." value={this.state.Particular} onChange={this.handleParticularChange} />
                            </FormItem>
               
                    
  
  
                <FormItem label="Quantity"
  
                            >
                            <Input name="quantity" type="number"  placeholder="Quantity" value={this.state.quantity} onChange={this.handlequantityChange}/>
                            </FormItem>
                    
                
                <FormItem label="Rate"
                  >
                  <Input name="rate" type="number"  placeholder="Rate" value={this.state.rate} onChange={this.handlerateChange}/>
                  </FormItem>
                    
                    
                <FormItem label="Amount to be paid"           
                        >
                        <Input  type="number"  placeholder="Amount to be paid" value={this.getTotalPayment()}  readOnly/>
                        </FormItem>
                
  
                <FormItem label="Description">
                <Input  placeholder="Details." value={this.state.description} onChange={this.handledescriptionChange} />
                </FormItem>
  
                <FormItem>
                    <Button type="primary" htmlType="submit" >Update</Button>
                </FormItem>
            </Form>
              
                </Panel>
                </Collapse>

            
            
            </div>
        )

        }


      }

    }
}

export default ExpenseDetail; 