import React from 'react';
import ChildForm from '../components/ChildForm'
import GroupForm from '../components/GroupForm'

import ActivateMemberForm from '../components/ActivateMemberForm'
import MemberImportForm from '../components/MemberImportForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin, Card,Avatar,Image } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { FilePdfFilled,SearchOutlined,DeleteOutlined,FundViewOutlined,DownloadOutlined,EditOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var ngoid= ''
var bizuserid= ''
var token= ''

class ChildrenList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    children: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    userrights:{},
    loaded_image:false

  };

  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    
    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }
    
     if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   this.componentmountactions();

}

//component mount method
componentmountactions=()=>{
  axios.get(serverconfig.backendserverurl+`/api/children/?Ngo=${ngoid}`)
  .then(res => {
      this.setState({
        children:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
      this.setState({datarequested:false})
  })


}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/children/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );

  };



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

      var columns = [
        {
          title: 'Profile',
          dataIndex: 'Photo',
          key: 'id',
          render: text =>
          <p>
            <Avatar size={70} icon={      
              <Image
              width={this.state.loaded_image===false?0:70}
              height={this.state.loaded_image===false?0:70}
              src={text}   
              preview={true} 
              onLoad={()=>{this.setState({loaded_image:true})}}
              />} />

            {
              this.state.loaded_image===false?
              <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
              :
              null
            }          

          </p>,
        },
        {
          title: 'Child ID',
          dataIndex: 'child_no',
          key: 'id',
          ...this.getColumnSearchProps('child_no'),
        },
        {
          title: 'Full Name',
          dataIndex: 'FullName',
          key: 'id',
          ...this.getColumnSearchProps('FullName'),
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'id',
          ...this.getColumnSearchProps('gender'),
        },

        {
          title: 'Contact',
          dataIndex: 'RegisteredPhoneNumber1',
          key: 'id',
          ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
        },
        {
          title: 'School type',
          dataIndex: 'school_type',
          key: 'id',
          ...this.getColumnSearchProps('school_type'),
        },
        {
          title: 'School name',
          dataIndex: 'school_name',
          key: 'id',
          ...this.getColumnSearchProps('school_name'),
        },
        
        {
          title: 'User',
          dataIndex: 'username',
          key: 'id',
          ...this.getColumnSearchProps('username'),
        },

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/children/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to view documents</p>} title="View">
          <Link to={`/childdocuments/${text}`}><FilePdfFilled style={{color:'green'}}/></Link>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          {
            this.state.userrights.can_edit_delete_children===true?
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>
          :
          null}

          </p>,
        }
  
      ];
    
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>AVAILABLE  CHILDREN</h4>} key="1">
            
            <Table   
            columns={columns} 
            scroll={{ x: 1000 }}
            dataSource={this.state.children} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered/>
            <br />

            <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Children bio data</Button>}>
                <ExcelSheet data={this.state.children} name="Children bio">
                    <ExcelColumn label="Child ID" value="child_no"/>
                    <ExcelColumn label="Name" value="FullName"/>
                    <ExcelColumn label="Tel" value="RegisteredPhoneNumber1"/>
                    <ExcelColumn label="Email" value="EmailAddress"/>

                </ExcelSheet>
            </ExcelFile>

            </Panel>
            </Collapse>

            <Row>
            <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Collapse Panel to open child entry Form" key="1">
              <h2>Create new child here</h2>
              <ChildForm /> 
            </Panel>
            </Collapse>  
            </Col>

            </Row>
        </div>
    )

    }

  }
}

export default ChildrenList; 
