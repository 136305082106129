import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin,DatePicker,InputNumber} from 'antd';
import IDTypeForm from '../components/IDTypeForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
import moment from 'moment';

const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var token= ''
const { TextArea } = Input;
const { RangePicker } = DatePicker;

class SchoolTermDetail extends React.Component{

    state={
        schoolterm: {},
        term_name:'',
        term_description:'',
        datarequested:true,
        date_from:'',  
        date_to:'',  
    }

    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        const termID=this.props.match.params.termID;
        axios.get(`${serverconfig.backendserverurl}/api/schoolterms/${termID}`)
        .then(res => {  
            this.setState({
                schoolterm: res.data
            })

            this.setState({date_from:res.data.date_from})
            this.setState({date_to:res.data.date_to})
            this.setState({term_name:res.data.term_name})
            this.setState({term_description:res.data.term_description})
            this.setState({datarequested:false})
        })
    }

     

    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const termID=this.props.match.params.termID;

    let form_data = new FormData();
    form_data.append('date_to', this.state.date_to);
    form_data.append('date_from', this.state.date_from);
    form_data.append('term_name', this.state.term_name);
    form_data.append('term_description', this.state.term_description);

    axios.put(`${serverconfig.backendserverurl}/api/schoolterms/${termID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>this.setState({datarequested:false}),window.location.reload(false),message.info('Successfully updated ID'))
    .catch(error => console.log(error))

  }


    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
                <div>
                 
          <Card 
          style={{padding:10}}
          hoverable>
              <h4>School Term Update</h4>
              <Form>
                            
          <FormItem label="Term name"
          >
            <Input  placeholder="Term name." value={this.state.term_name} onChange={(val)=>{this.setState({term_name:val.target.value})}} />
          </FormItem>


          <FormItem label="Description"       
          >
            <TextArea
                  placeholder="Description."
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  value={this.state.term_description}
                  onChange={(val)=>{this.setState({term_description:val.target.value})}}
                />
          </FormItem>

          
          <FormItem label={"Date ( "+this.state.date_from+" - "+this.state.date_to+" )"}
          >
              <RangePicker defaultValue={moment()} 
              onChange={(date, dateString) =>{
                this.setState({ date_from: dateString[0]});
                this.setState({ date_to: dateString[1]});
              } } format={dateFormat} />
          </FormItem>
     
            <FormItem>
            <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
            </FormItem>
            </Form>

         </Card>

         <br/>
        </div>
      )

      }
    }
}

export default SchoolTermDetail; 