import React from 'react';
import { Form, Input, Button,Spin,Switch, message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TextArea } = Input;

var token= ''
var sacco= ''
var ngoid= ''


class ScheduleNotificationForm extends React.Component {

  state = {
    notification_message:'' ,  
    staffroleDescription:'',
    datarequested:false,
    notification_days:[{'day':1},{'day':2},{'day':3},{'day':4},{'day':5},{'day':6},{'day':7},{'day':8},{'day':9},{'day':10},{'day':11},{'day':12}
    ,{'day':13},{'day':14},{'day':15},{'day':16},{'day':17},{'day':18},{'day':19},{'day':20},{'day':21},,{'day':22},{'day':23},{'day':24},{'day':25},{'day':26},{'day':27},{'day':28}],
    selected_days:[],
    send_byemail:false,
    send_bysms:false,
    send_bydashboardnotication:false
  }

  componentDidMount(){
    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      ngoid= ''
   }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{

    if(this.state.notification_message.length>160){
      message.error("Message cant be greater than 160 characters")
    }else{

      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('notification_message', this.state.notification_message);
      form_data.append('days_of_occurancy',JSON.stringify(this.state.selected_days) );
  
      form_data.append('send_bydashboardnotication', this.state.send_bydashboardnotication);
      form_data.append('send_byemail', this.state.send_byemail);
      form_data.append('send_bysms', this.state.send_bysms);
  
      form_data.append('Ngo', ngoid);
      
        axios.post(serverconfig.backendserverurl+'/api/scheduled_notifications/', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res =>     this.setState({datarequested:false})
      ,window.location.reload(false)
      )
      .catch(error => console.log(error))
    }

  }
  

  handlenotification_messageChange= (e) => this.setState({ notification_message: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ staffroleDescription: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
          onFinish={(event) => this.handleFormSubmit(event)}  
    >
      <FormItem   
      label="Notification message"
      name="notification_message"
      rules={[
        {
          required: true,
          message: 'Please input message',
        },
      ]}      
      >
      
        <TextArea
          placeholder="Message here"
          autoSize={{ minRows: 2, maxRows: 6 }}
          value={this.state.notification_message}
          onChange={this.handlenotification_messageChange}
        />

      </FormItem>


      <FormItem label="Select Monthly days for notification">
        {
          this.state.notification_days.map((item)=>{
            return (
              <Button type={this.state.selected_days.indexOf(item.day)> -1?'primary':'default'} onClick={()=>{
                if(this.state.selected_days.indexOf(item.day)> -1){
                  console.log("trans removed")
                  const newList = this.state.selected_days.filter((trans) => trans!== item.day);
                  this.setState({selected_days:newList});
                }else{

                  this.setState({
                    selected_days: [...this.state.selected_days, item.day]
                    });

                    console.log("trans added")
                }

              }}> Day {item.day}</Button>
            );
          })
        }

      </FormItem>


      <FormItem name="send_byemail" label="send by email" >
          <Switch
            checked={this.state.send_byemail}
            onChange={val=>{this.setState({send_byemail:!this.state.send_byemail})}}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            />
      </FormItem>

      <FormItem name="send_bysms" label="send by sms" >
          <Switch
            checked={this.state.send_bysms}
            onChange={val=>{this.setState({send_bysms:!this.state.send_bysms})}}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            />
      </FormItem>


      <FormItem name="send_bydashboardnotication" label="send by dashboardnotication" >
          <Switch
            checked={this.state.send_bydashboardnotication}
            onChange={val=>{this.setState({send_bydashboardnotication:!this.state.send_bydashboardnotication})}}
            checkedChildren="ON"
            unCheckedChildren="OFF"
            />
      </FormItem>
     
      <FormItem>
        <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
      </FormItem>
    </Form>
        </div>
      );

    }

  }

}


export default ScheduleNotificationForm;



