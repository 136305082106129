import React from 'react';
import { Form, Input, Button,Select,message,Spin,Switch } from 'antd';
import { Table,Collapse,Popover,Popconfirm,Descriptions,Modal } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import { SearchOutlined,LoadingOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,EditOutlined } from '@ant-design/icons';
var CryptoJS = require("crypto-js");

const dateFormat = 'HHmmssDDMMYYYY';

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var ngoid= ''


class NGOCreateForm extends React.Component {

  state = {
    ngo_name:'',  
    licence:'',
    companyemail:'',
    ngo_logo:null,   
    companyphone:'',
    boxnumber:'',
    accountslimit:0,
    branchname:'',
    companyphonetwo:'',
    companyphonethree:'',
    website:'',
    address:'',
    datarequested:true,  
    safebalance:0,
    sharevalue:0,
    retainedearnings:0,
    packages:[],
    package:'',
    mobilemoneygatewaycode:'',
    mobilemoneyreceiver_number:'',
    bankaccount:'',
    ngo_controller:false

  }


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false});

}


  //submit button pressed
  handleFormSubmit=(event) =>{

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('ngo_name', this.state.ngo_name);
    form_data.append('company_email', this.state.companyemail);
    form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
    form_data.append('box_number', this.state.boxnumber);

    this.state.ngo_logo==null?
    console.log("No profile file")
    :
    form_data.append('ngo_logo', this.state.ngo_logo,this.state.ngo_logo?this.state.ngo_logo.name:"");
    
    form_data.append('website',this.state.website);
    form_data.append('RegisteredPhoneNumber2', this.state.companyphonetwo);
    form_data.append('RegisteredPhoneNumber3', this.state.companyphonethree);

    form_data.append('ngo_controller', false);
    form_data.append('address', this.state.address);
    form_data.append('ngo_supervisor_id', ngoid);

    axios.post(serverconfig.backendserverurl+'/api/ngos/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res => this.setState({datarequested:false})
    , window.location.reload(false),
    message.info('Successfully created ngo')
    )
    .catch(error => console.log(error))

  }

  

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div >
          <Form
          onFinish={(event) => this.handleFormSubmit(event)}
          >
            <FormItem label="NGO Name"
                name="ngo_name"
                rules={[
                  {
                    required: true,
                    message: 'Please input name',
                  },
                ]}
              >
              <Input name="ngo_name"  placeholder="Put a name here." value={this.state.ngo_name} onChange={(val)=>{this.setState({ngo_name:val.target.value})}} />
            </FormItem>
  
  
            <FormItem label="NGO Logo">
              <Input name="ngo_logo" type="file" accept="image/png, image/jpeg" placeholder="NGO logo"  onChange={(val)=>{this.setState({ ngo_logo: val.target.files[0]})}}/>
            </FormItem>  
            
            <FormItem label="NGO Email"
                name="companyemail"
                rules={[
                  {
                    required: true,
                    message: 'Please input email',
                  },
                ]}
            >
              <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.companyemail} onChange={(val)=>{this.setState({companyemail:val.target.value})}} />
            </FormItem> 
  
            <FormItem label="Phone Number">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphone} onChange={(val)=>{this.setState({companyphone:val})}}/>
             </FormItem>
             <FormItem label="Phone Number Two">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphonetwo} onChange={(val)=>{this.setState({companyphonetwo:val})}}/>
             </FormItem> 
  
             <FormItem label="Phone Number Three">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphonethree} onChange={(val)=>{this.setState({companyphonethree:val})}}/>
             </FormItem>  
             <FormItem label="Website"

             >
              <Input  name="website"  placeholder="Webiste here" value={this.state.website} onChange={(val)=>{this.setState({website:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Box number">
              <Input name="boxnumber"  placeholder="Box number here" value={this.state.boxnumber} onChange={(val)=>{this.setState({boxnumber:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: 'Please input email',
                },
              ]}
            
            >
              <Input name="address"  placeholder="Address here" value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit" >Create</Button>
            </FormItem>
          </Form>
        </div>
      );
    }
   
  }

}


export default NGOCreateForm;



