import React from 'react';
import CompanyAccountForm from '../components/CompanyAccountForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Modal,Form } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

var token= ''
var ngoid= ''

class CompanyAccountList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    companyaccounts: [],
    datarequested:true,
    account_number:'' ,  
    bank_name:'',
    selectedaccountobj:{},
    showupdateform:false
  };
  

  componentDidMount(){
    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/companyaccounts/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };

  render() {
    const columns = [
      {
        title: 'bank Name',
        dataIndex: 'bank_name',
        key: 'id',
        ...this.getColumnSearchProps('bank_name'),
      },
      {
        title: 'Account Number',
        dataIndex: 'account_number',
        key: 'id',
        ...this.getColumnSearchProps('account_number'),

      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <FundViewOutlined style={{color:'blue'}} onClick={()=>{
          this.setState({selectedaccountobj:record})
          this.setState({bank_name:record.bank_name})
          this.setState({account_number:record.account_number})

          this.setState({showupdateform:true})

          }}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}} />
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="BANK ACCOUNTS" key="1">
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.companyaccounts}
            bordered/>
             </Panel>
             </Collapse>
            <br />
            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Collapse Panel to open Form" key="1">
              <h2>Create Account here</h2>
              <CompanyAccountForm /> 
            </Panel>
            </Collapse>

            <Modal              
                visible={this.state.showupdateform}
                title="Account Update Form"
                width={600}
                onCancel={()=>{this.setState({showupdateform:false})}}

                footer={[
                  <Button key="back" type='primary' onClick={()=>{

                    let form_data = new FormData();
                    form_data.append('account_number', this.state.account_number);
                    form_data.append('bank_name', this.state.bank_name);
                
                      axios.put(serverconfig.backendserverurl+`/api/companyaccounts/${this.state.selectedaccountobj.id}/`, form_data, {
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                      })
                    .then(res => {

                      axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?Ngo=${ngoid}`)
                      .then(res => {
                          this.setState({
                              companyaccounts:res.data
                          })
                      this.setState({showupdateform:false})
                    }   
                    )
                    .catch(error => console.log(error))

                    })}}>
                    Update Account
                  </Button>,
                  <Button key="ok" type='text' onClick={()=>{this.setState({showupdateform:false})}}>
                  Cancel
                </Button>
                  ]}
                  >

              <FormItem label="Bank Name"
                >
                  <Input name="bank_name"  placeholder="Put name here." value={this.state.bank_name} onChange={(val)=>{this.setState({bank_name:val.target.value})}} />
                </FormItem>

                <FormItem label="Account Number"  
                >
                  <Input name="account_number"  placeholder="Put acc number here" value={this.state.account_number} onChange={(val)=>{this.setState({account_number:val.target.value})}} />
                </FormItem>
              

            </Modal>

        </div>
    )
    }

  }
}

export default CompanyAccountList; 
