import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result,InputNumber, message } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined,WarningFilled,CheckCircleFilled } from '@ant-design/icons';
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TextArea } = Input;

var token= ''
var ngoid= ''
var bizuserid= ''


class ChildFeesPaymentForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accounttypes:[],
    idtypes:[],
    sur_name:'' ,  
    middle_name:'',
    given_name:'',
    nationality:'',
    ID_type:'',
    ID_number:'',
    date_Of_Birth:'',
    Email_Address:'',
    P_O_Box:'',
    Area_Of_Residence:'',
    District:'',
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    next_of_kin:'',
    Next_Of_Kin_Physical_Address:'',
    relationship:'',
    Photo:null,
    Signature:null,
    Thumbprint:null,

    Date_Of_Joining:'',
    account_type:'',
    date_of_appointment:'',
    status:'',
    staff_number:'',
    reference_one:'',
    Referee1PhoneNumber:'',
    datarequested:true,
    accountnumber:'',
    photofile:null,
    signaturefile:null,
    thumbprintfile:null,
    marital_status:'',
    own_residence:'',
    tribe:'',
    recommender:'',
    language1:'',
    language2:'',
    gender:'',
    any_other_account:'',
    other_account_number:'',
    other_bank:'',
    shares:0,
    title:'',
    datasubmittedsuccessfully:false,
    saccopackageobj:{},
    members:[],
    companyprofile:{},

    occupation:'',
    postaladdress:'',
    homecelllocaltion:'',
    cell:'',
    cellleader:'',
    nameofemployer_business:'',

    addressofemployer_business:'',
    refree_institutionalmember_name:'',
    refree_institutionalmember_contact:'',
    refree_cellleader_name:'',
    refree_cellleader_contact:'',
    next_of_kin_contact:'',

    child_account_school:'',
    child_account_school_class:'',

    membertype:'senior',
    pysical_form:null,
    saving_period:'',

    id_expirydate:'',
    ugdistricts:[],
    cellleader_contact:'',
    id_valid:false,
    age_valid:false,
    agetypeobject:{},
    idtypeobject:{},
    reapersrefobj:{},
    reapersselectedref:'',

    age_types:[],
    age_type:'',
    bankaccount:'',
    mobilemoney_contact:'',
    schools:[],
    selected_school:'',
    child_account_school_class:'',
    guardians:[],
    selected_guardian:'',

    children:[],
    selected_child:'',
    documenttype:'',
    receipt_attachment:null,
    document_description:'',

    school_fee_budget:'',
    fees_amount_paid:0,
    remarks:'',
    schoolfees_budget: [],
    fees_paidamount:0,
    fees_balance:0,
    fees_receipt_attachment:null,

    requirements_amount_paid:0,
    rqments_receipt_attachment:null,
    reqmnts_paidamount:0,
    reqmnts_balance:0,


  }

  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/idtypes/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          idtypes:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/ngos/${ngoid}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })

    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/ugdistricts/?Ngo=${ngoid}`)
    .then(res => {
      this.setState({
        ugdistricts:res.data
      })
    })

    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/age_types/?Ngo=${ngoid}`)
    .then(res => {
      this.setState({
        age_types:res.data
      })
    })

    axios.get(serverconfig.backendserverurl+`/api/schools/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          schools:res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/guardians/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          guardians:res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/children/?Ngo=${ngoid}`)
    .then(res => {
        this.setState({
          children:res.data
        })

        this.setState({datarequested:false})
    })

})

}


  //submit button pressed
  handleFormSubmit=(event) =>{

        if(this.state.school_fee_budget===''){
          message.info("No child termly fees selected")

        }else if(Number(this.state.fees_amount_paid)>Number(this.state.fees_balance)){
          message.info("Amount cant be greater than fees_balance")

        }else{

          this.setState({datarequested:true})
          
          let form_data = new FormData();
          form_data.append('date', this.state.date);
          form_data.append('school_fee_budget', this.state.school_fee_budget);
          form_data.append('fees_amount_paid', this.state.fees_amount_paid);
          form_data.append('remarks', this.state.remarks);
          form_data.append('Ngo', ngoid);
          form_data.append('user', bizuserid);
          
          this.state.fees_receipt_attachment==null?
          console.log("No file")
          :
          form_data.append('fees_receipt_attachment', this.state.fees_receipt_attachment, this.state.fees_receipt_attachment?this.state.fees_receipt_attachment.name:"");
    
          this.state.rqments_receipt_attachment==null?
          console.log("No file")
          :
          form_data.append('rqments_receipt_attachment', this.state.rqments_receipt_attachment, this.state.rqments_receipt_attachment?this.state.rqments_receipt_attachment.name:"");

          form_data.append('requirements_amount_paid', this.state.requirements_amount_paid);

          axios.post(serverconfig.backendserverurl+'/api/schoolfeespayment/', form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then(res =>{
            console.log(res.data)
            this.setState({datarequested:false})
            this.setState({datasubmittedsuccessfully:true})
            
          })
          .catch(error => console.log(error))
        }
  }

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Uploaded  document "
            subTitle="document details will be further reviewed if need be"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

            return (
              <div>
                <Form
                    onFinish={(event) => this.handleFormSubmit(event)}
                >

                <FormItem label="Select child"
                     name="selected_child"
                     rules={[
                       {
                         required: true,
                         message: 'Please select child',
                       },
                     ]}           
                  >
                      <Select placeholder="Child"
                       style={{  }} value={this.state.selected_child} 
                       onChange={(val)=>{
                        this.setState({selected_child:val})
                        this.setState({schoolfees_budget:[]})
                      
                        axios.get(serverconfig.backendserverurl+`/api/schoolfees_budget/?child=${val}`)
                        .then(res => {
                            this.setState({
                              schoolfees_budget:res.data
                            })
                        })
                      
                      
                      }}
                      
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      >
                        {this.state.children.map(
                          (chld)=>(
                            <Option value={chld.id}>{chld.FullName}</Option>
                          ))}
                      </Select>
                  </FormItem>

                  {
                    this.state.schoolfees_budget.length>0?
                    <FormItem label="Select school term fees"
                    name="school_fee_budget"
                    rules={[
                      {
                        required: true,
                        message: 'Please select school term fees',
                      },
                    ]}           
                  >
                     <Select placeholder="Termly fees"
                      style={{  }} 
                      value={this.state.school_fee_budget} 
                      onChange={(val)=>{
                       this.setState({school_fee_budget:val})
                       //get the paymnet details

                       let form_data = new FormData();
                       form_data.append('school_fee_budget', val);
                     
                       axios.post(serverconfig.backendserverurl+`/customqueries/getfeesbudgetpayemntdetails`, form_data,{
                         headers: {
                           'content-type': 'multipart/form-data'
                         }
                         })
                       .then(res => {
                        this.setState({fees_paidamount:res.data.fees_paidamount})
                        this.setState({fees_balance:res.data.fees_balance})

                        this.setState({reqmnts_balance:res.data.rqmnts_balance})
                        this.setState({reqmnts_paidamount:res.data.rqmnts_paidamount})

                        })
                     }}
                     
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}
                     
                     >
                       {this.state.schoolfees_budget.map(
                         (bgt)=>(
                           <Option value={bgt.id}>{bgt.school_term_name} | {bgt.school_fees_amount}</Option>
                         ))}
                     </Select>
                   </FormItem>                    
                    :
                    null
                  }

              <h4>Fees Paid:  <CurrencyFormat value={this.state.fees_paidamount} displayType={'text'} thousandSeparator={true}/></h4>
              <h4>Fees Balance:  <CurrencyFormat value={this.state.fees_balance} displayType={'text'} thousandSeparator={true}/></h4>

              <h4>Requirements amount Paid: <CurrencyFormat value={this.state.reqmnts_paidamount} displayType={'text'} thousandSeparator={true}/></h4>
              <h4>Requirements Balance:  <CurrencyFormat value={this.state.reqmnts_balance} displayType={'text'} thousandSeparator={true}/></h4>


               <FormItem label="Fees Amount"
                name="fees_amount_paid"
                rules={[
                  {
                    required: false,
                    message: 'Please input amount',
                  },
                ]}
                
                >
                  <InputNumber
                    style={{ width: 150 }} 
                    defaultValue={0.0}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\x\s?|(,*)/g, '')}
                    onChange={(val)=>{this.setState({fees_amount_paid:val})}}
                  />    
                              
                </FormItem>


                <FormItem label="Requirements Amount"
                name="requirements_amount_paid"
                rules={[
                  {
                    required: false,
                    message: 'Please input amount',
                  },
                ]}
                
                >
                  <InputNumber
                    style={{ width: 150 }} 
                    defaultValue={0.0}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\x\s?|(,*)/g, '')}
                    onChange={(val)=>{this.setState({requirements_amount_paid:val})}}
                  />    
                              
                </FormItem>


                  <FormItem label="Fees Receipt" style={{marginRight:3}}
                    name="fees_receipt_attachment"
                    rules={[
                      {
                        required: false,
                        message: 'Please input file',
                      },
                    ]}
                  >
                        <Input type="file" accept=".pdf" placeholder="file" 
                         onChange={(e)=>{ 
                          
                          var kilbyes=e.target.files[0].size/1024
                          if (kilbyes>Number(1024)){
                            message.error("File is greater than "+"1024"+" Kbs or 1 mb .It will not be uploaded.")
                          }else{
                            this.setState({fees_receipt_attachment:e.target.files[0]})
                          }
                          
                          
                          
                          
                          }}/>
                  </FormItem> 


                  <FormItem label="Requirements pay receipt" style={{marginRight:3}}
                    name="rqments_receipt_attachment"
                    rules={[
                      {
                        required: false,
                        message: 'Please input file',
                      },
                    ]}
                  >
                        <Input type="file" accept=".pdf" placeholder="file" 
                         onChange={(e)=>{ 
                          
                          var kilbyes=e.target.files[0].size/1024
                          if (kilbyes>Number(1024)){
                            message.error("File is greater than "+"1024"+" Kbs or 1 mb .It will not be uploaded.")
                          }else{
                            this.setState({rqments_receipt_attachment:e.target.files[0]})
                          }
                          
                          
                          }}/>
                  </FormItem> 


                  <FormItem label="Remarks"
                  name="remarks"
                  rules={[
                    {
                      required: true,
                      message: 'Please input remarks',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="gaurdian remarks"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        value={this.state.remarks}
                        onChange={(val)=>{this.setState({remarks:val.target.value})}}
                      />
                  </FormItem>


                <FormItem >
                  <Button  type='primary' htmlType="submit">Submit</Button>
                </FormItem>
              </Form>
            </div>
          );
    }

    }
    
  }

}

export default ChildFeesPaymentForm;

