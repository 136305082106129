import React from 'react';
import { Form, Input, Button,Select,Spin,DatePicker,Tooltip,Popover,Popconfirm,Result,message,Card,InputNumber,Table } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined,DeleteOutlined,PlusOutlined, LoadingOutlined,PrinterOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import { number } from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var token= ''
var sacco= ''
var bizuserid= ''
var username=''
message.config({duration:3000})

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

//calculate principleTotal
calculateScheduleTotal=()=>{
  var principleTotal=0
  this.props.loanschedule.map(
    (item)=>{
      principleTotal+=Number(item.principle)
    })

  return principleTotal;
}

  //calculate principleTotal
  calculateInterestTotal=()=>{
    var InterestTotal=0
    this.props.loanschedule.map(
      (item)=>{
        InterestTotal+=Number(item.interest)
      })

    return InterestTotal;
  }

  //calculate principleTotal
  calculateTotalPay=()=>{
    var TotalPay=0
    this.props.loanschedule.map(
      (item)=>{
        TotalPay+=Number(item.total)
      })

    return TotalPay;
  }



  render() {
    return (
      <div style={{padding:20}}>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN SCHEDULE </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} MONTHS</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.loanissueobject.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>

           <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Principle Bf</th>
                            <th>Principle Pay</th>
                            <th>Interest Pay</th>
                            <th>Total Pay</th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.repayment_number}</td>
                            <td>{item.date}</td>
                            <td>{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>        

    </div>
    );
  }
}


class StatementToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      sacco= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }
 //calculate principle paid
 calculateTotalPrinciplePaid=()=>{
  var TotalPay=0
  this.props.loanrepayments.map(
    (item)=>{
      TotalPay+=Number(item.principle_paid)
    })

  return TotalPay;
 }

  //calculate Interest paid
  calculateTotalInterestPaid=()=>{
    var TotalPay=0
    this.props.loanrepayments.map(
      (item)=>{
        TotalPay+=Number(item.interest_paid)
      })

    return TotalPay;
   }

         //calculate Total total paid
  calculateTotalTotalPaid=()=>{
    var TotalPay=0
    this.props.loanrepayments.map(
      (item)=>{
        TotalPay+=Number(item.total_paid)
      })

    return TotalPay;
   }

   //calculate total fine paid
   calculateTotalFinePaid=()=>{
    var TotalPay=0
    this.props.loanrepayments.map(
      (item)=>{
        TotalPay+=Number(item.fine_paid)
      })

    return TotalPay;
   }


  render() {
    return (
      <div style={{padding:20}}>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN STATEMENT </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} MONTHS</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.loanissueobject.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>

                  <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Desc</th>
                            <th>Principle Paid</th>
                            <th>Interest Paid</th>
                            <th>Total Paid</th>
                            <th>Fine Paid</th>
                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanrepayments.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.description}</td>
                            <td>{<CurrencyFormat value={item.principle_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.fine_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPrinciplePaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalTotalPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalFinePaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>       

    </div>
    );
  }
}


class DailyReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

  }


  render() {
    return (
      <div style={{padding:20}}>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                <br></br>

                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}>DAILY LOAN SCHEDULE </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} MONTHS</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.loanissueobject.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>

                  <br></br>
                  <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th>Principle Bf</th>
                          <th>Interest Bf</th>
                          <th>Principle Pay</th>
                          <th>Interest Pay</th>
                          <th>Total Pay</th>
                          <th>Principle Bal</th>
                          <th>Interest Bal</th>

                          <th>Received AMount</th>
                          <th>Receipt No</th>


                        </tr>
                      </thead>
                      <tbody>
                      {this.props.loandailyschedules.map(
                        (item)=>(
                          <tr>
                          <td>{item.repayment_number}</td>
                          <td>{item.date}</td>
                          <td>{<CurrencyFormat value={item.principlebefore} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestbefore} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.principlepay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestpay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalpay} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{<CurrencyFormat value={item.principlebalance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestbalance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          <td></td>

                          </tr>
                        ))}

                    </tbody>
                </reactstrp.Table>
                <br></br>
                <h3>Client Name:......................................................</h3>

                <h3>Officer:......................................................</h3>
                
                <h3>Manager:......................................................</h3>

    </div>
    );
  }
}


class LoanIssueDetail extends React.Component {

  state = {
    datetoday:moment().format(dateFormat).toString(),
    loanamount:0,
    interestrate:0,
    interestratetype:'',
    loanperiod:0,
    datarequested:true,
    dateofissuance:moment(),
    dateofissuancestring:moment().format(dateFormat).toString(),
    loanschedule:[],
    loanissueobject:{},
    loanrepayments:[],
    loandailyschedules:[],
    companyprofile:{},

    userprofile:{},
    deletelist:[],
    totaltobepaidobject:{},
    interestpay:0

  }

  componentDidMount(){

      if(localStorage.getItem("sacco")){
          sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      }else{
          sacco= ''
      }
      
      if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
      }else{
          token= ''
      }

      if(localStorage.getItem("sacco")){
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
        username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

      }else{
        bizuserid= ''
        username=''

      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

    const loanissueID=this.props.match.params.loanissueID;
    axios.get(`${serverconfig.backendserverurl}/api/loanissues/${loanissueID}`)
    .then(res => {  
        this.setState({loanissueobject: res.data})

    })

    axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${loanissueID}`)
    .then(res => {  
        this.setState({loanschedule: res.data})
    })

    axios.get(`${serverconfig.backendserverurl}/api/loanrepayments/?loanissue=${loanissueID}`)
    .then(res => {  
        this.setState({loanrepayments: res.data})
        
    })

    axios.get(`${serverconfig.backendserverurl}/api/loandailyscheduledate/?loanissue=${loanissueID}`)
    .then(res => {  
        this.setState({loandailyschedules: res.data})
    })

    axios.get(`${serverconfig.backendserverurl}/api/accounts/${bizuserid}`)
    .then(res => {  
        this.setState({
          userprofile: res.data
        })
    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

        //this.setState({datarequested:false})

    })

    //form here
    let form_data = new FormData();
    form_data.append('issued_loan_id', loanissueID);
    axios.post(serverconfig.backendserverurl+'/customqueries/getcurrentperiodbalance', form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
      .then(res =>{
        this.setState({datarequested:false})

        console.log(res.data)

        this.setState({totaltobepaidobject:JSON.parse(res.data.data)})
     
      } 
      )
      .catch(error => console.log(error)) 

  }


  //calculate principleTotal
  calculateScheduleTotal=()=>{
    var principleTotal=0
    this.state.loanschedule.map(
      (item)=>{
        principleTotal+=Number(item.principle)
      })

    return principleTotal;
  }

    //calculate principleTotal
    calculateInterestTotal=()=>{
      var InterestTotal=0
      this.state.loanschedule.map(
        (item)=>{
          InterestTotal+=Number(item.interest)
        })
  
      return InterestTotal;
    }

    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.loanschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay;
    }


      //calculate principle paid
      calculateTotalPrinciplePaid=()=>{
      var TotalPay=0
      this.state.loanrepayments.map(
        (item)=>{
          TotalPay+=Number(item.principle_paid)
        })
  
      return TotalPay;
     }
  
      //calculate Interest paid
      calculateTotalInterestPaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.interest_paid)
          })
    
        return TotalPay;
       }

             //calculate Total total paid
      calculateTotalTotalPaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.total_paid)
          })
    
        return TotalPay;
       }

       //calculate total fine paid
       calculateTotalFinePaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.fine_paid)
          })
    
        return TotalPay;
       }


  render(){
   
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            loanschedule={this.state.loanschedule} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            ref={el => (this.componentRef = el)} /> 
            </div>

            &nbsp;&nbsp;
            <div style={{display: "none"}}>
            <DailyReceiptToPrint
            loandailyschedules={this.state.loandailyschedules} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            ref={el => (this.componentRefthree = el)} /> 
            </div>


            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <StatementToPrint
            loanrepayments={this.state.loanrepayments} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            ref={sel => (this.componentReftwo = sel)} /> 
            </div>

            <Row >

                    <Col xs="12" sm="6" lg="8">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN SCHEDULE</h4>

                    <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Principle Bf</th>
                            <th>Principle Pay</th>
                            <th>Interest Pay</th>
                            <th>Total Pay</th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.repayment_number}</td>
                            <td>{item.date}</td>
                            <td>{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>
                  <br></br>
                  <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>

                  <a>
                      <ReactToPrint
                        trigger={() =>                     
                          <div style={{}}>
                            <Tooltip title="Click to print" placement="top">
                            <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                            <h6 style={{alignSelf:'center'}}>Print Schedule</h6>
                            </Tooltip>
                          </div>                          
                            }
                            content={() => this.componentRef}
                          />  
                          </a>
                  </div>
 
              </Card>
              </Col>
              <Col xs="12" sm="6" lg="4">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder',color:'salmon'}}>ISSUED LOAN DETAILS</h4>
                    <h6 style={{fontWeight:'bold'}}>Loan Amount:<CurrencyFormat value={this.state.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX </h6>
                    <h6 style={{fontWeight:'bold'}}>Issuance Date: {this.state.loanissueobject.date}</h6>
                    <h6 style={{fontWeight:'bold'}}>Loan Period: {this.state.loanissueobject.loan_period}</h6>
                    <h6 style={{fontWeight:'bold'}}>Interest Rate: {this.state.loanissueobject.interest_rate}</h6>
                    <h6 style={{fontWeight:'bold'}}>Interest Rate Type: {this.state.loanissueobject.interest_rate_type}</h6>

                    <h6 style={{fontWeight:'bold'}}>Account Number: {this.state.loanissueobject.accountNumber}</h6>
                    <h6 style={{fontWeight:'bold'}}>Account Name: {this.state.loanissueobject.accountName}</h6>

                    <br></br>
                    <h5 style={{color:'red'}}>NOTE</h5>
                    <h6>Flat Interest gives more profits as compared to reducing balance</h6>
                    <h6>Incase we round,we make sure loan principle balances at the last payment</h6>

                    </Card>
                    </Col>
            </Row>
            <br></br>


            {
              this.state.loanissueobject.dailyschedulegenerated===true?
                  <Row >

                  <Col xs="12" sm="8" lg="12">
                  <Card>
                  <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN DAILY SCHEDULE</h4>

                  <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th>Principle Bf</th>
                          <th>Interest Bf</th>
                          <th>Principle Pay</th>
                          <th>Interest Pay</th>
                          <th>Total Pay</th>
                          <th>Principle Bal</th>
                          <th>Interest Bal</th>
                          <th>Principle Cleared</th>
                          <th>Interest Cleared</th>

                          
                          {
                            this.state.companyprofile.allow_transaction_editing===true&& this.state.loanissueobject.active_loan===true?
                            <th>Mark Paid</th>
                            :null
                          }


                          {
                            this.state.companyprofile.allow_transaction_editing===true&& this.state.loanissueobject.active_loan===true?
                            <th>Reset</th>
                            :null
                          }



                        </tr>
                      </thead>
                      <tbody>
                      {this.state.loandailyschedules.map(
                        (item)=>(
                          <tr>
                          <td>{item.repayment_number}</td>
                          <td>{item.date}</td>
                          <td>{<CurrencyFormat value={item.principlebefore} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestbefore} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.principlepay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestpay} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalpay} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{<CurrencyFormat value={item.principlebalance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.interestbalance} displayType={'text'} thousandSeparator={true}/>}</td>

                          <th>{item.principleCleared===true?"Yes":"No"}</th>
                          <th>{item.interestCleared===true?"Yes":"No"}</th>


                          {
                            this.state.companyprofile.allow_transaction_editing===true&& this.state.loanissueobject.active_loan===true?
                            <th>

                            <Button type="primary" onClick={()=>{

                              let form_data = new FormData();
                              form_data.append('scheduleid', item.id);
                              form_data.append('action', "markpaid");

                              //Now submit sale data to database
                              axios.post(serverconfig.backendserverurl+'/customqueries/resetdailyschedule', form_data,{
                              headers: {
                                'content-type': 'multipart/form-data'
                              }
                              })
                              .then(res =>{
                                const loanissueID=this.props.match.params.loanissueID;

                                axios.get(`${serverconfig.backendserverurl}/api/loandailyscheduledate/?loanissue=${loanissueID}`)
                                .then(res => {  
                                    this.setState({loandailyschedules: res.data})
                                })

                               })
                              .catch(error => console.log(error)) 

                            }}>
                              Mark 
                            </Button>
                            </th>

                            :null
                          }



                          {
                            this.state.companyprofile.allow_transaction_editing===true&& this.state.loanissueobject.active_loan===true?
                            <th>

                            <Button type="primary" onClick={()=>{

                              let form_data = new FormData();
                              form_data.append('scheduleid', item.id);
                              form_data.append('action', "reset");

                              //Now submit sale data to database
                              axios.post(serverconfig.backendserverurl+'/customqueries/resetdailyschedule', form_data,{
                              headers: {
                                'content-type': 'multipart/form-data'
                              }
                              })
                              .then(res =>{
                                const loanissueID=this.props.match.params.loanissueID;

                                axios.get(`${serverconfig.backendserverurl}/api/loandailyscheduledate/?loanissue=${loanissueID}`)
                                .then(res => {  
                                    this.setState({loandailyschedules: res.data})
                                })

                               })
                              .catch(error => console.log(error)) 

                            }}>
                              Reset 
                            </Button>
                            </th>

                            :null
                          }
                          

                          </tr>
                        ))}

                    </tbody>
                </reactstrp.Table>
                <br></br>
                <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>

                <a>
                    <ReactToPrint
                      trigger={() =>                     
                        <div style={{}}>
                          <Tooltip title="Click to print" placement="top">
                          <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                          <h6 style={{alignSelf:'center'}}>Print Daily Schedule</h6>
                          </Tooltip>
                        </div>                          
                          }
                          content={() => this.componentRefthree}
                        />  
                        </a>
                </div>

            </Card>
            </Col>
          </Row>

            :
            null

          }


            <br></br>
            <Row >
            <Col xs="12" sm="6" lg="12">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN STATEMENT</h4>
                  <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Desc</th>
                            <th>Details</th>
                            <th>Principle Paid</th>
                            <th>Interest Paid</th>
                            <th>Total Paid</th>
                            <th>Fine Paid</th>
                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>

                            {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>Select Payment</th>
                            :
                            null
                           }

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanrepayments.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.description}</td>
                            <td>{item.more_details}</td>

                            <td>{<CurrencyFormat value={item.principle_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.fine_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                           
                            {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <th>
                              
                            <PlusOutlined  style={{color:this.state.deletelist.indexOf(item.id)> -1?'red':'green'}}  onClick={()=>{

                              if(this.state.deletelist.indexOf(item.id)> -1){
                                console.log("trans removed")
                                const newList = this.state.deletelist.filter((trans) => trans!== item.id);
                                this.setState({deletelist:newList});
                              }else{

                                this.setState({
                                  deletelist: [...this.state.deletelist, item.id]
                                  });

                                  console.log("trans added")

                              }
                                
                            }}/>

                            </th>
                            :
                            null
                          }
                           
                           
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPrinciplePaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalTotalPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalFinePaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          <td></td>
                          <td></td>

                          {
                            this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                            <td></td>
                            :
                            null
                          }

                          </tr>

                      </tbody>
                  </reactstrp.Table>

                  <br></br>
                  <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>


                  {
                   this.state.companyprofile.allow_transaction_editing===true && this.state.userprofile.branch_manager===true&& this.state.loanissueobject.active_loan===true?
                  <Button  type="primary" htmlType='button'
                  onClick={()=>{
                    this.setState({datarequested:true})

                    let form_data = new FormData();
                    form_data.append('deletemode','loanrepayments');
                    form_data.append('deletelist',JSON.stringify(this.state.deletelist) );

                    axios.post(serverconfig.backendserverurl+'/customqueries/massdeleting', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                    .then(res => {
                      //get new data back
                      this.setState({datarequested:false})
                      this.setState({deletelist:[]})
                      window.location.reload(false)

                    })
                    .catch(error => console.log(error))

                  }}
                  
                  >Batch Delete</Button>
                  :
                  null
                  }


                  &nbsp;
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  &nbsp;


                  <a>
                      <ReactToPrint
                        trigger={() =>                     
                          <div style={{}}>
                            <Tooltip title="Click to print" placement="top">
                            <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                            <h6 style={{alignSelf:'center'}}>Print Statement</h6>
                            </Tooltip>
                          </div>                          
                            }
                            content={() => this.componentReftwo}
                          />  
                          </a>

                  </div>


              </Card>
                    
            </Col>
            
            </Row>

            <br></br>
            {
              this.state.loanissueobject.dailyschedulegenerated===true && this.state.loanissueobject.active_loan===true?
            <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <h3 style={{display:'flex',color:'blue'}}>Loan Total PayOff</h3>
            <h4>Total Interest Paid:<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/> </h4>
            <h4>Total Principle Paid:<CurrencyFormat value={this.calculateTotalPrinciplePaid()} displayType={'text'} thousandSeparator={true}/> </h4>
            <h4>Total Paid:<CurrencyFormat value={this.calculateTotalTotalPaid()} displayType={'text'} thousandSeparator={true}/> </h4>

            <h4>Total Interest To Be Paid:<CurrencyFormat value={this.state.totaltobepaidobject.TotalInteresttobe} displayType={'text'} thousandSeparator={true}/> </h4>
            <h4>Total Principle To Be Paid:<CurrencyFormat value={this.state.totaltobepaidobject.TotalPrincipletobe} displayType={'text'} thousandSeparator={true}/> </h4>
            <h4>Total To Be Paid:<CurrencyFormat value={this.state.totaltobepaidobject.TotalTotaltobe} displayType={'text'} thousandSeparator={true}/> </h4>

            <h4>Total Interest Balance:<CurrencyFormat value={this.state.totaltobepaidobject.TotalInterestbalance} displayType={'text'} thousandSeparator={true}/> </h4>
            <h4>Total Principle Balance:<CurrencyFormat value={this.state.totaltobepaidobject.TotalPrinciplebalance} displayType={'text'} thousandSeparator={true}/> </h4>
            <h4>Total Balance:<CurrencyFormat value={this.state.totaltobepaidobject.Totaltotalbalance} displayType={'text'} thousandSeparator={true}/> </h4>

            </Card>
            </Col>

            <Col xs="12" sm="6" lg="6">
            <Card>
            <h3 style={{display:'flex',color:'blue'}}>PayOff Here</h3>
            <Button type="primary"  shape="round" 
            onClick={()=>{
               const loanissueID=this.props.match.params.loanissueID;
               this.setState({datarequested:true})

              //form here
              let form_data = new FormData();
              form_data.append('issued_loan_id', loanissueID);
              form_data.append('username', username);

              axios.post(serverconfig.backendserverurl+'/customqueries/dailyloantotapayoff', form_data,{
                headers: {
                  'content-type': 'multipart/form-data'
                }
                })
                .then(res =>{
                  this.setState({datarequested:false})
                  console.log(res.data)
                  message.info(res.data.message)
                  //window.location.reload(false)
                } 
                )
                .catch(error => console.log(error)) 

            }}
            >
                  PayOff Loan
            </Button>
            </Card>
            </Col>

            </Row>
            :
            null}

            {
              this.state.companyprofile.emergency_interest_onloan?
              <div>
                <br></br>
              <FormItem label={<h6 style={{fontWeight:'bold'}}>Interest Amount </h6>}

                >
                  <InputNumber
                    style={{ width: 200 }} 
                    defaultValue={0.0}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\x\s?|(,*)/g, '')}
                    onChange={(val)=>{
                      this.setState({interestpay:val})
                    }}
                  />    
                              
                </FormItem>

                <Button type="primary"  shape="round" 
                    onClick={()=>{
                        const loanissueID=this.props.match.params.loanissueID;
                        this.setState({datarequested:true})

                      //form here
                      let form_data = new FormData();
                      form_data.append('issued_loan_id', loanissueID);
                      form_data.append('username', username);
                      form_data.append('interestpay', Number(this.state.interestpay));

                      axios.post(serverconfig.backendserverurl+'/customqueries/emergencyinterestpay', form_data,{
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                        })
                        .then(res =>{
                          this.setState({datarequested:false})
                          console.log(res.data)
                          message.info(res.data.message)
                          window.location.reload(false)
                        } 
                        )
                        .catch(error => console.log(error)) 
                    }}
                    >
                     Last Interest Pay
                    </Button>

              </div>
              :
              null
            }

        </div>
      );

    
    }

  }

}


export default LoanIssueDetail;



