import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined } from '@ant-design/icons';
var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''
var sacco= ''


class LoanImport_PayForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    excelfilefile:null,
    saccoboject:{}
  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          saccoboject:res.data
        })
        this.setState({datarequested:false})

    })

}


  //submit button pressed
  handleFormSubmit=(event) =>{

    if(this.state.excelfilefile===null){
      message.info("excel file missing")
    }else{

      this.setState({datarequested:true})

      let form_data = new FormData();
     
      form_data.append('sacco', sacco);
      this.state.excelfile==null?
      console.log("No excelfile file")
      :
      form_data.append('fileToUpload', this.state.excelfile, this.state.excelfile?this.state.excelfile.name:"");
  
      console.log(form_data)
  
        axios.post(serverconfig.backendserverurl+'/customqueries/importloan_balances', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res =>{
        console.log(res.data)
        this.setState({datarequested:false})
        //window.location.reload(false)
        this.setState({datasubmittedsuccessfully:true})
      }    
      )
      .catch(error => console.log(error))

    }

  }


  handleexcelfileChange= (e) =>{
    this.setState({ excelfile: e.target.files[0]});
    if(e.target.files[0]){
      this.setState({
        excelfilefile: URL.createObjectURL(e.target.files[0])
      })
    }

  } 


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{


      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Imported member data into the system "
            subTitle="Member details will be further reviewed if need be"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

            return (
              <div>
                <Form
                    onFinish={(event) => this.handleFormSubmit(event)}
                >
                      
                <div style={{display:'flex',flexDirection:'row'}}>
                <FormItem label="Loans Excel File" style={{marginRight:3}}>
                    <Input type="file" accept="*" placeholder="Loans Excel File"  onChange={this.handleexcelfileChange}/>
                </FormItem> 
                </div>
  
              <FormItem >
                <Button  type='primary' htmlType="submit">Loan Balance Import</Button>
              </FormItem>
      
                </Form>
              </div>
            );

      }

    }
    
  }

}

export default LoanImport_PayForm;



