import React from 'react';
import axios from 'axios';
import { Card,Avatar} from 'antd';
import { Form, Input, Button,Select,Spin,Image,DatePicker,Result,Switch,Tooltip,message } from 'antd';
import {
    Col,
    Row,
  } from 'reactstrap';
import { UserOutlined,LoadingOutlined,EditOutlined } from '@ant-design/icons';
import {  LockOutlined,MailOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import * as serverconfig from '../serverconn'
import moment from 'moment';
import * as primarycolor from '../primarycolor'
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';


var token= ''
var bizuserid= ''
var ngoid= ''


class GuardianDetail extends React.Component{

    state={
        guardian: {},
        accounttypes:[],
        idtypes:[],
        sur_name:'' ,  
        middle_name:'',
        given_name:'',
        nationality:'',
        ID_type:'',
        ID_number:'',
        date_Of_Birth:'',
        Email_Address:'',
        P_O_Box:'',
        Area_Of_Residence:'',
        District:'',
        RegisteredPhoneNumber1:'',
        RegisteredPhoneNumber2:'',
        next_of_kin:'',
        Next_Of_Kin_Physical_Address:'',
        relationship:'',
        Photo:null,
        id_photo:null,
        thumbprintfile:null,

        Date_Of_Joining:'',
        account_type:'',
        date_of_appointment:'',
        status:'',
        staff_number:'',
        reference_one:'',
        Referee1PhoneNumber:'',
        datarequested:true,
        accountnumber:'',
        photofile:null,
        id_photofile:null,
        Thumbprint:null,

        marital_status:'',
        own_residence:'',
        tribe:'',
        recommender:'',
        language1:'',
        language2:'',
        gender:'',
        any_other_account:'',
        other_account_number:'',
        other_bank:'',
        title:'',
        datasubmittedsuccessfully:false,
        userrights:{},
        email_statements:false,
        user:'',
        users:[],
        date:'',
        how_did_you_know_sacco:'',
        supersavertypes:[],
        supersavertype:'',
        editform_visible:false,

        bankaccount:'',
        mobilemoney_contact:'',
        contract_file:false

    }


    componentDidMount(){
      if(localStorage.getItem("ngoid")){
        ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      }else{
        ngoid= ''
      }
      
      if(localStorage.getItem("bizuserid")){
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      }else{
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }


        const guardianID=this.props.match.params.guardianID;
        axios.get(`${serverconfig.backendserverurl}/api/guardians/${guardianID}`)
        .then(res => {  
            this.setState({guardian: res.data})

            this.setState({date: res.data.date})
            this.setState({sur_name: res.data.SurName})
            this.setState({middle_name: res.data.MiddleName})
            this.setState({given_name: res.data.OtherName})
            this.setState({nationality: res.data.Nationality})
            this.setState({ID_type: res.data.IDType})
            this.setState({ID_number: res.data.IDNumber})
            this.setState({date_Of_Birth: res.data.DateOfBirth})
            this.setState({Email_Address: res.data.EmailAddress})
            this.setState({P_O_Box: res.data.POBox})
            this.setState({Area_Of_Residence: res.data.AreaOfResidence})
            this.setState({District: res.data.District})
            this.setState({RegisteredPhoneNumber1: res.data.RegisteredPhoneNumber1})
            this.setState({RegisteredPhoneNumber2: res.data.RegisteredPhoneNumber2})
            this.setState({next_of_kin: res.data.next_of_kin})
            this.setState({Next_Of_Kin_Physical_Address: res.data.NextOfKinPhysicalAddress})
            this.setState({relationship: res.data.relationship})
            this.setState({Date_Of_Joining: res.data.DateOfJoining})
            this.setState({gender: res.data.gender})
            this.setState({marital_status: res.data.marital_status})
            this.setState({title: res.data.title})
            this.setState({own_residence: res.data.own_residence})
            this.setState({tribe: res.data.tribe})
            this.setState({language1: res.data.language1})
            this.setState({language2: res.data.language2})
            this.setState({user: res.data.user})

            this.setState({mobilemoney_contact: res.data.mobilemoney_contact})
            this.setState({bankaccount: res.data.bankaccount})

        })

    
        axios.get(serverconfig.backendserverurl+`/api/idtypes/?Ngo=${ngoid}`)
        .then(res => {
            this.setState({
              idtypes:res.data
            })
        })

        //get the sacco package here
        axios.get(serverconfig.backendserverurl+`/api/ugdistricts/?Ngo=${ngoid}`)
        .then(res => {
          this.setState({
            ugdistricts:res.data
          })
        })

        //get the sacco package here
        axios.get(serverconfig.backendserverurl+`/api/age_types/?Ngo=${ngoid}`)
        .then(res => {
          this.setState({
            age_types:res.data
          })
            this.setState({datarequested:false})
        })

        axios.get(serverconfig.backendserverurl+`/api/accounts/?Ngo=${ngoid}`)
        .then(res => {
            this.setState({
              users:res.data
            })
        })


        axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
        .then(res => {
            this.setState({
              userrights:res.data[0]
            })
            this.setState({datarequested:false})
        })

    }


//imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};





    //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    const guardianID=this.props.match.params.guardianID;

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('SurName', this.state.sur_name);
    form_data.append('MiddleName', this.state.middle_name);
    form_data.append('OtherName', this.state.given_name);
    form_data.append('Nationality', this.state.nationality);
    form_data.append('IDType', this.state.ID_type);
    form_data.append('IDNumber', this.state.ID_number);
    form_data.append('DateOfBirth', this.state.date_Of_Birth);

    form_data.append('EmailAddress', this.state.Email_Address);
    form_data.append('POBox', this.state.P_O_Box);
    form_data.append('AreaOfResidence', this.state.Area_Of_Residence);
    form_data.append('District', this.state.District);
    form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
    form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);

    form_data.append('next_of_kin', this.state.next_of_kin);
    form_data.append('NextOfKinPhysicalAddress', this.state.Next_Of_Kin_Physical_Address);
    form_data.append('relationship', this.state.relationship);
    form_data.append('DateOfJoining', this.state.Date_Of_Joining);

    form_data.append('gender', this.state.gender);
    form_data.append('marital_status', this.state.marital_status);
    form_data.append('title', this.state.title);

    form_data.append('own_residence', this.state.own_residence);
    form_data.append('tribe', this.state.tribe);
    form_data.append('language1', this.state.language1);
    form_data.append('language2', this.state.language2);
    form_data.append('user', this.state.user);
    form_data.append('Ngo', Number(ngoid));
    form_data.append('guardianID', guardianID);

    axios.post(serverconfig.backendserverurl+`/customqueries/updateguardianaccountfromdashboard`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>{

    let form_data1 = new FormData();
    this.state.Photo==null?
    console.log("No profile file")
    :
    form_data1.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");

    this.state.id_photo==null?
    console.log("No id_photo file")
    :
    form_data1.append('id_photo', this.state.id_photo, this.state.id_photo?this.state.id_photo.name:"");

    this.state.contract_file==null?
    console.log("No contract_file file")
    :
    form_data1.append('contract_file', this.state.contract_file, this.state.contract_file?this.state.contract_file.name:"");

    axios.put(serverconfig.backendserverurl+`/api/guardiansignatureupdate/${guardianID}/`, form_data1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>{
    console.log("successful image upload")
  }

  )
  .catch(error => console.log(error))

  this.setState({datarequested:false})
  this.setState({datasubmittedsuccessfully:true})

  }
    
  )
  .catch(error => console.log(error))

  }


 
  handlesur_nameChange= (e) => this.setState({ sur_name: e.target.value});
  handlemiddle_nameChange= (e) => this.setState({ middle_name: e.target.value});
  handlegiven_nameChange= (e) => this.setState({ given_name: e.target.value});
  handlenationalityChange= (e) => this.setState({ nationality: e.target.value});
  handleID_typeChange= (e) => this.setState({ ID_type: e});
  handletitleChange= (e) => this.setState({ title: e});

  handleID_numberChange= (e) => this.setState({ ID_number: e.target.value});
  handledate_Of_BirthChange= (date, dateString) => this.setState({ date_Of_Birth: dateString});

  handleEmail_AddressChange= (e) => this.setState({ Email_Address: e.target.value});
  handleP_O_BoxChange= (e) => this.setState({ P_O_Box: e.target.value});
  handleArea_Of_ResidenceChange= (e) => this.setState({ Area_Of_Residence: e.target.value});
  handleDistrictChange= (e) => this.setState({ District: e.target.value});
  handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});

  handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});
  handlenext_of_kinChange= (e) => this.setState({ next_of_kin: e.target.value});
  handleNext_Of_Kin_Physical_AddressChange= (e) => this.setState({ Next_Of_Kin_Physical_Address: e.target.value});
  handlerelationshipChange= (e) => this.setState({ relationship: e.target.value});
  handlePhotoChange= async(e) =>{
    //this.setState({ Photo: e.target.files[0]});

    if(e.target.files[0]){
      this.setState({
        photofile: URL.createObjectURL(e.target.files[0])
      })
    }

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ Photo: newFile});
    console.log(newFile);


  } 

  handleid_photoChange= async(e) =>{
    this.setState({ id_photo: e.target.files[0]});
    if(e.target.files[0]){
      this.setState({
        id_photofile: URL.createObjectURL(e.target.files[0])
      })
    }

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ id_photo: newFile});
    console.log(newFile);


  } 

  handleDate_Of_JoiningChange= (date, dateString) => this.setState({ Date_Of_Joining: dateString});
  handleaccount_typeChange= (e) => this.setState({ account_type: e});
  handledate_of_appointmentChange= (date, dateString) => this.setState({ date_of_appointment: dateString});

  handlestatusChange= (e) => this.setState({ status: e});
  handlemarital_statusChange= (e) => this.setState({ marital_status: e});
  handleown_residenceChange= (e) => this.setState({ own_residence: e});

  handlestaff_numberChange= (e) => this.setState({ staff_number: e.target.value});
  handlereference_oneChange= (e) => this.setState({ reference_one: e.target.value});
  handleReferee1PhoneNumberChange= (e) => this.setState({ Referee1PhoneNumber: e});

  handleaccountnumberChange= (e) => this.setState({ accountnumber: e.target.value});
  handletribeChange= (e) => this.setState({ tribe: e.target.value});
  handlerecommenderChange= (e) => this.setState({ recommender: e.target.value});
  handlelanguage1Change= (e) => this.setState({ language1: e.target.value});
  handlelanguage2Change= (e) => this.setState({ language2: e.target.value});
  handlegenderChange= (e) => this.setState({ gender: e});

  handleother_account_numberChange= (e) => this.setState({ other_account_number: e.target.value});
  handleany_other_accountChange= (e) => this.setState({ any_other_account: e});
  handleother_bankChange= (e) => this.setState({ other_bank: e.target.value});


    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{

            if (this.state.datasubmittedsuccessfully===true){
              return(
                  <Card>
                  <Result
                  status="success"
                  title="Successfully Updated guardian details "
                  subTitle="guardian details can be updated any time"
                  extra={[
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />
                  </Card>
              );
      
            }else{

          return(
            <div>
              <div style={{display:'flex',flexDirection:'row'}}>
              <h2  style={{display:'flex',margin:4}}> {this.state.guardian.FullName} | {this.state.guardian.RegisteredPhoneNumber1}</h2>
              
              <Tooltip title={"Click to edit guardian information"}>

              <EditOutlined style={{color:primarycolor.primarycolor,fontSize: '30px',}} onClick={()=>{this.setState({editform_visible:!this.state.editform_visible})}} />
              </Tooltip>
              </div>

              <div style={{display:'flex',overflowX:'auto'}}>
              <reactstrp.Table bordered >
                  <thead>
                  <tr>
                  <th>
                  <Image
                  width={100}
                  height={100}
                  src={this.state.guardian.Photo}    
                  />
                  <br></br>
                  <br></br>

                  <Image
                  width={100}
                  height={100}
                  src={this.state.guardian.id_photo}    
                  />                          

                    </th>
                    <th>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Sur Name: {this.state.guardian.SurName}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Middle Name: {this.state.guardian.MiddleName}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Other Name: {this.state.guardian.OtherName}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Nationality: {this.state.guardian.Nationality}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Date Of Birth: {this.state.guardian.DateOfBirth}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Gender: {this.state.guardian.gender}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Nationality: {this.state.guardian.Nationality}</p>

                    <p style={{fontWeight:'bolder',fontSize:15}}>Occupation: {this.state.guardian.occupation}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Name of employer: {this.state.guardian.nameofemployer_business}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Address of employer: {this.state.guardian.addressofemployer_business}</p>

                    </th>
                    <th>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Phone One: {this.state.guardian.RegisteredPhoneNumber1}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Phone Two: {this.state.guardian.RegisteredPhoneNumber2}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Email: {this.state.guardian.EmailAddress}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>District: {this.state.guardian.District}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Physical Address: {this.state.guardian.AreaOfResidence}</p>

                    </th>
                    <th>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Tribe: {this.state.guardian.tribe}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Language One: {this.state.guardian.language1}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Language two: {this.state.guardian.language2}</p>

                    <p style={{fontWeight:'bolder',fontSize:15}}>Mobile Money contact: {this.state.guardian.mobilemoney_contact}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Bank account Number: {this.state.guardian.bankaccount}</p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Date Of Joining: {this.state.guardian.DateOfJoining}</p>

                    </th>
                  </tr>
                </thead>
              </reactstrp.Table>   
              </div>

              {
                this.state.editform_visible===true?
              <Card> 

                <h4 style={{fontWeight:'bold'}}>guardian Update Form</h4>
                <Form
                    onFinish={(event) => this.handleFormSubmit(event)} 
                    >
  
                  <FormItem label="Sur Name">
                    <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={this.handlesur_nameChange} />
                  </FormItem>
        
                  <FormItem label="Middle Name">
                    <Input  placeholder="Put a name here." value={this.state.middle_name} onChange={this.handlemiddle_nameChange} />
                  </FormItem>
        
                  <FormItem label="Given Name">
                    <Input  placeholder="Put a name here." value={this.state.given_name} onChange={this.handlegiven_nameChange} />
                  </FormItem>
  
                  <FormItem label="Gender">
                      <Select placeholder="Gender" style={{ width: 120 }} value={this.state.gender} onChange={this.handlegenderChange} >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                      </Select>
                  </FormItem>
  
        
                  <FormItem label="Nationality">
                    <Input  placeholder="Nationality." value={this.state.nationality} onChange={this.handlenationalityChange} />
                  </FormItem>
        
        
                  <FormItem label="ID Type">
                      <Select placeholder="Id Type" style={{  }} value={this.state.ID_type} onChange={this.handleID_typeChange} >
                        {this.state.idtypes.map(
                          (type)=>(
                            <Option value={type.id}>{type.id_type_name}</Option>
                          ))}
                      </Select>
                  </FormItem>
        
                  <FormItem label="ID Number">
                    <Input  placeholder="ID Number." value={this.state.ID_number} onChange={this.handleID_numberChange} />
                  </FormItem>
        
                  <FormItem label="Date of Birth">
                        <DatePicker onChange={this.handledate_Of_BirthChange} format={dateFormat} />
                  </FormItem>
  
                  <FormItem label="Tribe">
                    <Input  placeholder="Tribe." value={this.state.tribe} onChange={this.handletribeChange} />
                  </FormItem>
  
                  <FormItem label="Language one">
                    <Input  placeholder="Language one." value={this.state.language1} onChange={this.handlelanguage1Change} />
                  </FormItem>
  
                  <FormItem label="Language two">
                    <Input  placeholder="Language two." value={this.state.language2} onChange={this.handlelanguage2Change} />
                  </FormItem>
  
                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="guardian Photo" style={{marginRight:3}}>
                        <Input type="file" accept="image/png, image/jpeg" placeholder="guardian Photo"  onChange={this.handlePhotoChange}/>
                  </FormItem> 
  
                  {this.state.Photo?
                  <Image
                  width={50}
                  height={50}
                  src={this.state.photofile}    
                  />
                  :
                  null
                  }
  
                  </div>
  
  
                <div style={{display:'flex',flexDirection:'row'}}>
                <FormItem label="guardian id photo" style={{marginRight:3}}>
                    <Input type="file" accept="image/png, image/jpeg" placeholder="guardian id photo"  onChange={this.handleid_photoChange}/>
                </FormItem> 
                {this.state.id_photo?
                  <Image
                  width={50}
                  height={50}
                  src={this.state.id_photofile}    
                  />
                  :
                  null
                  }
                </div>
  

                  <h3>Contact Section</h3>
  
                  <FormItem label="Email Address">
                  <Input  type="mail" 
                          prefix={<MailOutlined className="site-form-item-icon" />} 
                          placeholder="Email"
                          value={this.state.Email_Address} 
                          onChange={this.handleEmail_AddressChange} />
                  </FormItem>
        
                  <FormItem label="Box Number">
                    <Input placeholder="box number." value={this.state.P_O_Box} onChange={this.handleP_O_BoxChange} />
                  </FormItem>
        
                  <FormItem label="Area of Residence">
                    <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={this.handleArea_Of_ResidenceChange} />
                  </FormItem>
        
                  <FormItem label="District">
                    <Input placeholder="District." value={this.state.District} onChange={this.handleDistrictChange} />
                  </FormItem>
        
                  <FormItem label="Phone One"                    
                  >
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber1} onChange={this.handleRegisteredPhoneNumber1Change}/>
                  </FormItem> 
        
                  <FormItem label="Phone Two">
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber2} onChange={this.handleRegisteredPhoneNumber2Change}/>
                  </FormItem>
  
                  <h3>Personal Status Section</h3>
                  <FormItem label="Title">
                      <Select placeholder="Title" style={{ width: 120 }} value={this.state.title} onChange={this.handletitleChange} >
                            <Option value="Mrs">Mrs</Option>
                            <Option value="Mr">Mr</Option>
                            <Option value="Miss">Miss</Option>
                      </Select>
                  </FormItem>
  
                  <FormItem label="Marital Status">
                      <Select placeholder="Title" style={{ width: 120 }} value={this.state.marital_status} onChange={this.handlemarital_statusChange} >
                            <Option value="Married">Married</Option>
                            <Option value="Engaged">Engaged</Option>
                            <Option value="Single">Single</Option>
                      </Select>
                  </FormItem>
  
                  <FormItem label="Own Residence?">
                      <Select placeholder="Title" style={{ width: 120 }} value={this.state.own_residence} onChange={this.handleown_residenceChange} >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                      </Select>
                  </FormItem>
  
  
                  <h3>Next of Kin Section</h3>
  
                  <FormItem label="Next of kin">
                    <Input placeholder="Next of kin." value={this.state.next_of_kin} onChange={this.handlenext_of_kinChange} />
                  </FormItem>
        
                  <FormItem label="Next of kin Address">
                    <Input placeholder="Next of Kin Address." value={this.state.Next_Of_Kin_Physical_Address} onChange={this.handleNext_Of_Kin_Physical_AddressChange} />
                  </FormItem>
        
                  <FormItem label="Relationship">
                    <Input placeholder="Next of kin Relationship." value={this.state.relationship} onChange={this.handlerelationshipChange} />
                  </FormItem>
        
                  <FormItem label={"Date of Joining ("+this.state.Date_Of_Joining+")"}>
                        <DatePicker onChange={this.handleDate_Of_JoiningChange} format={dateFormat}/>
                  </FormItem>
  
                  <h4>Personal Account details.</h4>
                  <FormItem label="Bank account"
                  >
                    <Input placeholder="Bank account." value={this.state.bankaccount} onChange={(val)=>{this.setState({bankaccount:val.target.value})}} />
                  </FormItem>
                  <FormItem label="Mobile Money contact"
                  >
                    <Input placeholder="Mobile Money Contact." value={this.state.mobilemoney_contact} onChange={(val)=>{this.setState({mobilemoney_contact:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Attached User">
                      <Select placeholder="Attached User" style={{  }} value={this.state.user} onChange={(val)=>{this.setState({user:val})}} >
                        {this.state.users.map(
                          (type)=>(
                            <Option value={type.id}>{type.username}</Option>
                          ))}
                      </Select>
                  </FormItem>

                  <FormItem label="Contract attachment">
                  <Input type="file" accept=".pdf" placeholder="Contract attachment" 
                   onChange={(e)=>{
                    var kilbyes=e.target.files[0].size/1024
                    if (kilbyes>Number(1024)){
                      message.error("File is greater than "+"1024"+" Kbs or 1 mb .It will not be uploaded.")
                    }else{
                      this.setState({contract_file:e.target.files[0]})
                    }
                    
                  }}/>
                   </FormItem>
  
                <FormItem>
                <Button  type="primary" htmlType="submit">Update</Button>
                </FormItem>
  
                </Form>

            </Card>
              :
              null
            }
            
            </div>
            )

            }

          }
        
    }
}

export default GuardianDetail; 